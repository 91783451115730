import React, { useState } from 'react';
import {
    FormattedMessage,
    injectIntl,
    intlShape,
    FormattedDate,
} from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../components';
import { ensureListing } from '../../util/data';
import { Modal } from '../../components';
import { getUserTxRole } from '../../util/transaction';
import moment from 'moment';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
    const {
        className,
        rootClassName,
        showButtons,
        showReview,
        showCancel,
        stateData,
        onOpenReviewModal,
        acceptInProgress,
        declineInProgress,
        acceptSaleError,
        declineSaleError,
        onAcceptSale,
        onDeclineSale,
        onCancel,
        transaction,
        currentUser,
    } = props;

    const buttonsDisabled = acceptInProgress || declineInProgress;

    const acceptErrorMessage = acceptSaleError ? (
        <p className={css.actionError}>
            <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
        </p>
    ) : null;
    const declineErrorMessage = declineSaleError ? (
        <p className={css.actionError}>
            <FormattedMessage id="TransactionPanel.declineSaleFailed" />
        </p>
    ) : null;

    const classes = classNames(rootClassName || css.actionButtons, className);

    const [isTheModalOpen, setIsTheModalOpen] = useState(false);

    const onManageDisableScrolling = (
        componentId,
        scrollingDisabled = true
    ) => {
        // We are just checking the value for now
        console.log(
            'Toggling Modal - scrollingDisabled currently:',
            componentId,
            scrollingDisabled
        );
    };

    const startDate =
        transaction.booking?.attributes?.displayStart;
    const endDate = transaction.booking?.attributes?.displayEnd;

    const listingAttributes = ensureListing(
        transaction.listing
    ).attributes;
    const timeZone = listingAttributes.availabilityPlan
        ? listingAttributes.availabilityPlan.timezone
        : 'Etc/UTC';

    const timeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
    };

    const dateFormatOptions = {
        month: 'short',
        day: 'numeric',
    };

    const timeZoneMaybe = timeZone ? { timeZone } : null;

    const userRole = getUserTxRole(currentUser.id, transaction);
    const { displayStart } =
        (transaction.booking && transaction.booking.attributes) || {};
    const durationLeftBeforeBookingStart = moment.duration(
        moment(displayStart).diff(moment(new Date()))
    );
    const isTimePassed = moment().isAfter(moment(displayStart));
    const isMoreThan48h =
        durationLeftBeforeBookingStart.asHours() > 48 ? true : false;

        const shouldShowCancelBooking = () => {
            if (!stateData || isTimePassed) return false;
        
            const isBookingAcceptedOrRequestedByCustomer = stateData === 'accepted' || (stateData === 'requested' && userRole === 'customer');
            if (!isBookingAcceptedOrRequestedByCustomer) return false;
        
            const isUserProviderOrCustomer = ['provider', 'customer'].includes(userRole);
            const isUserProvider = userRole === 'provider';
            const isBookingRequestedByCustomer = stateData === 'requested' && userRole === 'customer';
        
            console.log('isMoreThan48h', isMoreThan48h && isUserProviderOrCustomer);
            console.log('!isMoreThan48h', !isMoreThan48h && (isUserProvider || isBookingRequestedByCustomer));
            return (isMoreThan48h && isUserProviderOrCustomer) || (!isMoreThan48h && (isUserProvider || isBookingRequestedByCustomer));
        };
    console.log("showCancel", showCancel);
    return showButtons || showReview || showCancel ? (
        <div className={classes}>
            {showButtons && (
                <>
                    <div className={css.actionErrors}>
                        {acceptErrorMessage}
                        {declineErrorMessage}
                    </div>
                    <div className={css.actionButtonWrapper}>
                        <SecondaryButton
                            inProgress={declineInProgress}
                            disabled={buttonsDisabled}
                            onClick={onDeclineSale}>
                            <FormattedMessage id="TransactionPanel.declineButton" />
                        </SecondaryButton>
                        <PrimaryButton
                            inProgress={acceptInProgress}
                            disabled={buttonsDisabled}
                            onClick={onAcceptSale}>
                            <FormattedMessage id="TransactionPanel.acceptButton" />
                        </PrimaryButton>
                    </div>
                </>
            )}
            {showReview && (
                <>
                    <PrimaryButton onClick={onOpenReviewModal}>
                        Leave a Review
                    </PrimaryButton>
                </>
            )}
            {shouldShowCancelBooking() && (
                <SecondaryButton
                    className={css.theCancelButton}
                    disabled={buttonsDisabled}
                    onClick={() => setIsTheModalOpen(true)}>
                    Cancel Booking
                </SecondaryButton>
            )}
            <Modal
                id="cancelTransaction"
                containerClassName=""
                contentClassName=""
                isOpen={isTheModalOpen}
                onClose={() => setIsTheModalOpen(false)}
                onManageDisableScrolling={
                    onManageDisableScrolling
                }
                usePortal
                closeButtonMessage="">
                <p className={css.modalTitle}>
                    Are you sure you want to cancel this
                    booking?
                </p>
                <p className={css.modalMessage}>
                    <span>
                        <FormattedDate
                            value={startDate}
                            {...dateFormatOptions}
                            {...timeZoneMaybe}
                        />
                    </span>
                    <br />
                    <span>
                        <FormattedDate
                            value={startDate}
                            {...timeFormatOptions}
                            {...timeZoneMaybe}
                        />{' '}
                        -{' '}
                        <FormattedDate
                            value={endDate}
                            {...timeFormatOptions}
                            {...timeZoneMaybe}
                        />
                    </span>
                </p>
                <br />
                <div className={css.buttonsContainer}>
                    <span
                        className={css.reconsider}
                        onClick={() => {
                            setIsTheModalOpen(false);
                        }}>
                        Reconsider
                    </span>
                    <button
                        className={css.theCancelButton}
                        onClick={() => {
                            onCancel(transaction);
                            setIsTheModalOpen(false);
                        }}>
                        Cancel Booking
                    </button>
                </div>
            </Modal>
        </div>
    ) : null;
};

export default SaleActionButtonsMaybe;
