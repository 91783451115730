import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { calculateQuantityFromHours, timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
    Form,
    IconSpinner,
    InlineTextButton,
    PrimaryButton,
    SecondaryButton,
    Button,
} from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';

import css from './BookingTimeForm.module.css';
import { BookingDatesList } from './BookingDatesList';

export class BookingTimeFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clearForm: false,
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleFormInstantSubmit = this.handleFormInstantSubmit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    handleFormSubmit(e) {
        this.props.onSubmit(e);
    }

    handleFormInstantSubmit(e) {
        this.props.onSubmitInstant(e);
    }

    // When the values of the form are updated we need to fetch
    // lineItems from FTW backend for the EstimatedTransactionMaybe
    // In case you add more fields to the form, make sure you add
    // the values here to the bookingData object.
    handleOnChange(formValues) {
        // formValues -> bookingEndDate, bookingEndTime, bookingStartDate, bookingStartTime

        const { bookingStartTime, bookingEndTime } = formValues.values;

        const startDate = bookingStartTime
            ? timestampToDate(bookingStartTime)
            : null;
        const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

        const listingId = this.props.listingId;
        const isOwnListing = this.props.isOwnListing;

        // We expect values bookingStartTime and bookingEndTime to be strings
        // which is the default case when the value has been selected through the form
        const isSameTime = bookingStartTime === bookingEndTime;

        if (
            bookingStartTime &&
            bookingEndTime &&
            !isSameTime &&
            !this.props.fetchLineItemsInProgress
        ) {
            const totalBookingHours = calculateQuantityFromHours(
                startDate,
                endDate
            );
            const shouldDiscount = totalBookingHours >= 5;
            // this.props.addNewBooking(formValues.values);
            this.props.onFetchTransactionLineItems({
                bookingData: { startDate, endDate, shouldDiscount },
                listingId,
                isOwnListing,
            });
        }

        // sending formvalues to ListingPage to be stored
        // execute only have values
        // bookingEndDate, bookingEndTime, bookingStartDate, bookingStartTime
        if (
            formValues.values &&
            formValues.values.bookingEndTime &&
            formValues.values.bookingEndDate &&
            formValues.values.bookingStartTime &&
            formValues.values.bookingStartDate &&
            formValues.values.bookingStartDate.date &&
            formValues.values.bookingEndDate.date
        ) {
            let isIncluded = false;
            this.props.bookingDatesArray.map(bookingDates => {
                if (
                    bookingDates.bookingStartDate.date ===
                    formValues.values.bookingStartTime.date &&
                    bookingDates.bookingStartTime ===
                    formValues.values.bookingStartTime &&
                    bookingDates.bookingEndDate.date ===
                    formValues.values.bookingEndDate.date &&
                    bookingDates.bookingEndTime ===
                    formValues.values.bookingEndTime
                ) {
                    isIncluded = true;
                }
            });

            if (!isIncluded) {
                if (typeof window !== 'undefined') {
                    window.fbq('track', 'pageView');
                    window.fbq('track', 'AddToCart');
                }

                // this.props.storeBookingDatesArray(formValues.values);
                // this.props.addNewBooking(formValues.values);
                this.props.storeBookingDatesArray({
                    ...formValues.values,
                    bookingStartDate: { date: startDate },
                    bookingEndDate: { date: endDate },
                });
                this.props.addNewBooking({
                    ...formValues.values,
                    bookingStartDate: { date: startDate },
                    bookingEndDate: { date: endDate },
                });
            } else {
                console.log('Cannot pick same/duplicate date');
            }
        }
    }

    removeBookingDates(dateIndex) {
        this.props.removeBookingDates(dateIndex);
        this.props.deleteBooking(dateIndex);
    }

    render() {
        const {
            rootClassName,
            className,
            price: unitPrice,
            addNewBooking,
            deleteBooking,
            bookingDatesArray,
            listing,
            onContactUser,
            ...rest
        } = this.props;
        const isDiscountBookingEnabled =
            listing?.attributes?.publicData?.isDiscountBookingEnabled !== "false" && listing?.attributes?.publicData?.isDiscountBookingEnabled !== false;
        const classes = classNames(rootClassName || css.root, className);
        const { publicData } = listing.attributes;
        if (!unitPrice) {
            return (
                <div className={classes}>
                    <p className={css.error}>
                        <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
                    </p>
                </div>
            );
        }
        if (unitPrice.currency !== config.currency) {
            return (
                <div className={classes}>
                    <p className={css.error}>
                        <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
                    </p>
                </div>
            );
        }

        return (
            <FinalForm
                {...rest}
                unitPrice={unitPrice}
                onSubmit={(e) => {
                    this.handleFormSubmit(e);
                }}
                render={fieldRenderProps => {
                    const {
                        endDatePlaceholder,
                        startDatePlaceholder,
                        form,
                        pristine,
                        handleSubmit,
                        intl,
                        isOwnListing,
                        listingId,
                        submitButtonWrapperClassName,
                        unitType,
                        values,
                        monthlyTimeSlots,
                        onFetchTimeSlots,
                        timeZone,
                        lineItems,
                        fetchLineItemsInProgress,
                        fetchLineItemsError,
                        removeBookingDates,
                    } = fieldRenderProps;

                    const startTime =
                        values && values.bookingStartTime
                            ? values.bookingStartTime
                            : null;
                    const endTime =
                        values && values.bookingEndTime
                            ? values.bookingEndTime
                            : null;

                    const bookingStartLabel = intl.formatMessage({
                        id: 'BookingTimeForm.bookingStartTitle',
                    });
                    const bookingEndLabel = intl.formatMessage({
                        id: 'BookingTimeForm.bookingEndTitle',
                    });

                    const startDate = startTime
                        ? timestampToDate(startTime)
                        : null;
                    const endDate = endTime ? timestampToDate(endTime) : null;

                    // This is the place to collect breakdown estimation data. See the
                    // EstimatedBreakdownMaybe component to change the calculations
                    // for customized payment processes.
                    const bookingData =
                        bookingDatesArray && bookingDatesArray.length > 0
                            ? {
                                unitType,
                                bookingDatesArray,
                                timeZone,
                            }
                            : null;

                    const showEstimatedBreakdown =
                        bookingData &&
                        lineItems &&
                        !fetchLineItemsInProgress &&
                        !fetchLineItemsError;

                    // show list of dates when (+) is clicked
                    // take/fetch from existing form values
                    // const showDateList = bookingDatesArray && (
                    //   <ul>
                    //     {bookingDatesArray.map((bookingDates, index) => (
                    //         <BookingDatesList indexKey={index} booking={bookingDates} bookingData={bookingData} removeBookingDates={removeBookingDates}/>
                    //     ))}
                    //   </ul>
                    // );
                    const showDateList =
                        bookingDatesArray && bookingDatesArray.length ? (
                            <>
                                {React.Children.toArray(
                                    bookingDatesArray.map((booking, index) => (
                                        <BookingDatesList
                                            indexKey={index}
                                            booking={booking}
                                            bookingData={bookingData}
                                            removeBookingDates={
                                                removeBookingDates
                                            }
                                        />
                                    ))
                                )}
                            </>
                        ) : null;

                    const bookingInfoMaybe = showEstimatedBreakdown ? (
                        <div className={css.priceBreakdownContainer}>
                            <h3 className={css.priceBreakdownTitle}>
                                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
                            </h3>
                            <EstimatedBreakdownMaybe
                                bookingData={bookingData}
                                lineItems={lineItems}
                            />
                        </div>
                    ) : null;

                    const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
                        <IconSpinner className={css.spinner} />
                    ) : null;

                    const bookingInfoErrorMaybe = fetchLineItemsError ? (
                        <span className={css.sideBarError}>
                            <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
                        </span>
                    ) : null;

                    const submitButtonClasses = classNames(
                        submitButtonWrapperClassName || css.submitButtonWrapper
                    );

                    const startDateInputProps = {
                        label: bookingStartLabel,
                        placeholderText: startDatePlaceholder,
                    };
                    const endDateInputProps = {
                        label: bookingEndLabel,
                        placeholderText: endDatePlaceholder,
                    };

                    const dateInputProps = {
                        startDateInputProps,
                        endDateInputProps,
                    };

                    const bookingEnabled = monthlyTimeSlots && timeZone ? true : false;

                    return (
                        <Form onSubmit={(e) => {handleSubmit(e)}} className={classes} enforcePagePreloadFor="CheckoutPage">
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={values => {
                                    // Changes everytime user select value on field input
                                    this.handleOnChange(values);

                                    // 1st formValues -> bookingEndDate, bookingEndTime, bookingStartTime
                                    // 2nd formValues -> bookingEndDate, bookingEndTime, bookingStartDate, bookingStartTime
                                }}
                            />
                            {monthlyTimeSlots && timeZone ? (
                                <FieldDateAndTimeInput
                                    {...dateInputProps}
                                    className={css.bookingDates}
                                    listingId={listingId}
                                    bookingStartLabel={bookingStartLabel}
                                    onFetchTimeSlots={onFetchTimeSlots}
                                    monthlyTimeSlots={monthlyTimeSlots}
                                    values={values}
                                    intl={intl}
                                    form={form}
                                    pristine={pristine}
                                    timeZone={timeZone}
                                    bookingDatesArray={bookingDatesArray}
                                />
                            ) : null}

                            {isDiscountBookingEnabled && (
                                <small className={css.bookingDiscountNote}>
                                    <FormattedMessage id="BookingTimeForm.bookingDiscountNote" />
                                </small>
                            )}

                            {bookingDatesArray &&
                                bookingDatesArray.length > 0 && (
                                    <div
                                        className={css.priceBreakdownContainer}>
                                        {showDateList}
                                    </div>
                                )}
                            {bookingDatesArray &&
                                bookingDatesArray.length > 9 && (
                                    <p className={css.maxDates}>Max 10 Dates</p>
                                )}
                            <br />

                            {/* {bookingInfoMaybe}
                                {loadingSpinnerMaybe}
                            {bookingInfoErrorMaybe} */}

                            <p className={css.smallPrint}>
                                <FormattedMessage
                                    id={
                                        isOwnListing
                                            ? 'BookingTimeForm.ownListing'
                                            : bookingEnabled
                                                ? 'BookingTimeForm.youWontBeChargedInfo'
                                                : 'BookingTimeForm.enquiryInfo'
                                    }
                                />
                            </p>

                            <div className={css.submitButtonClasses}>
                                <PrimaryButton
                                    type="submit"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.handleFormSubmit(e);
                                    }}
                                >
                                    <FormattedMessage id="BookingTimeForm.requestToBook" />
                                </PrimaryButton>
                            </div>
                            <div className={css.submitButtonClasses}>
                                <PrimaryButton
                                    className={css.enquiryButton}
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onContactUser();
                                    }}>
                                    <FormattedMessage id="BookingTimeForm.enquiry" />
                                </PrimaryButton>
                            </div>
                            {bookingEnabled &&
                                publicData.isInstantBookingEnabled &&
                                publicData.isInstantBookingEnabled === 'Yes' ? (
                                <div className={css.submitButtonClasses}>
                                    <SecondaryButton
                                        type="button"
                                        onClick={(e) =>{
                                            e.preventDefault();
                                            e.stopPropagation();
                                            this.handleFormInstantSubmit(e);
                                        }
                                        }
                                        disabled={
                                            this.props.bookingDatesArray &&
                                                this.props.bookingDatesArray
                                                    .length > 1
                                                ? true
                                                : false
                                        }>
                                        ⚡&nbsp;
                                        <FormattedMessage id="BookingTimeForm.instantBook" />
                                    </SecondaryButton>
                                </div>
                            ) : null}

                            {publicData.isInstantBookingEnabled &&
                                publicData.isInstantBookingEnabled === 'Yes' ? (
                                <p className={css.instantBookInfo}>
                                    <FormattedMessage
                                        id={'BookingTimeForm.instantBookInfo'}
                                    />
                                </p>
                            ) : null}
                        </Form>
                    );
                }}
            />
        );
    }
}

BookingTimeFormComponent.defaultProps = {
    rootClassName: null,
    className: null,
    submitButtonWrapperClassName: null,
    price: null,
    isOwnListing: false,
    listingId: null,
    startDatePlaceholder: null,
    endDatePlaceholder: null,
    monthlyTimeSlots: null,
    lineItems: null,
    fetchLineItemsError: null,
    listing: null,
};

BookingTimeFormComponent.propTypes = {
    rootClassName: string,
    className: string,
    submitButtonWrapperClassName: string,

    unitType: propTypes.bookingUnitType.isRequired,
    price: propTypes.money,
    isOwnListing: bool,
    listingId: propTypes.uuid,
    monthlyTimeSlots: object,
    onFetchTimeSlots: func.isRequired,

    onFetchTransactionLineItems: func.isRequired,
    lineItems: array,
    fetchLineItemsInProgress: bool.isRequired,
    fetchLineItemsError: propTypes.error,
    addNewBooking: func.isRequired,
    deleteBooking: func.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,

    // for tests
    startDatePlaceholder: string,
    endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
