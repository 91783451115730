import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { EditListingOthersForm } from '../../forms';
import config from '../../config';

import css from './EditListingOthersPanel.module.css';

const EditListingOthersPanel = props => {
    const {
        className,
        rootClassName,
        listing,
        disabled,
        ready,
        onSubmit,
        onChange,
        submitButtonText,
        panelUpdated,
        updateInProgress,
        errors,
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const { description, title, publicData } = currentListing.attributes;

    const isPublished =
        currentListing.id &&
        currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
        <FormattedMessage
            id="EditListingOthersPanel.title"
            values={{
                listingTitle: (
                    <ListingLink listing={listing}>
                        <FormattedMessage id="EditListingOthersPanel.listingTitle" />
                    </ListingLink>
                ),
            }}
        />
    ) : (
        <FormattedMessage id="EditListingOthersPanel.createListingTitle" />
    );

    const certificateOptions = findOptionsForSelectFilter(
        'certificate',
        config.custom.filters
    );
    return (
        <div className={classes}>
            <h1 className={css.title}>{panelTitle}</h1>
            <EditListingOthersForm
                className={css.form}
                initialValues={{
                    howManyPlayersAllowed: publicData.howManyPlayersAllowed,
                    parkingSpots: publicData.parkingSpots,
                    rules: publicData.rules,
                    notes: publicData.notes,
                }}
                saveActionMsg={submitButtonText}
                onSubmit={values => {
                    const {
                        howManyPlayersAllowed,
                        parkingSpots,
                        rules,
                        notes,
                    } = values;
                    const updateValues = {
                        publicData: {
                            howManyPlayersAllowed: howManyPlayersAllowed.trim(),
                            parkingSpots: parkingSpots.trim(),
                            rules,
                            notes,
                        },
                    };

                    onSubmit(updateValues);
                }}
                onChange={onChange}
                disabled={disabled}
                ready={ready}
                updated={panelUpdated}
                updateInProgress={updateInProgress}
                fetchErrors={errors}
                certificateOptions={certificateOptions}
            />
        </div>
    );
};

EditListingOthersPanel.defaultProps = {
    className: null,
    rootClassName: null,
    errors: null,
    listing: null,
};

EditListingOthersPanel.propTypes = {
    className: string,
    rootClassName: string,

    // We cannot use propTypes.listing since the listing might be a draft.
    listing: object,

    disabled: bool.isRequired,
    ready: bool.isRequired,
    onSubmit: func.isRequired,
    onChange: func.isRequired,
    submitButtonText: string.isRequired,
    panelUpdated: bool.isRequired,
    updateInProgress: bool.isRequired,
    errors: object.isRequired,
};

export default EditListingOthersPanel;
