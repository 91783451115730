import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
    Button,
    FieldCheckboxGroup,
    Form,
    FieldCheckbox,
} from '../../components';

import css from './EditListingCourtTypeForm.module.css';

const EditListingCourtTypeFormComponent = props => (
    <FinalForm
        {...props}
        mutators={{ ...arrayMutators }}
        render={formRenderProps => {
            const {
                disabled,
                ready,
                rootClassName,
                className,
                name,
                handleSubmit,
                pristine,
                saveActionMsg,
                updated,
                updateInProgress,
                fetchErrors,
                filterConfig,
            } = formRenderProps;

            const classes = classNames(rootClassName || css.root, className);
            const submitReady = (updated && pristine) || ready;
            const submitInProgress = updateInProgress;
            const submitDisabled = disabled || submitInProgress;

            const { updateListingError, showListingsError } = fetchErrors || {};
            const errorMessage = updateListingError ? (
                <p className={css.error}>
                    <FormattedMessage id="EditListingCourtTypeForm.updateFailed" />
                </p>
            ) : null;

            const errorMessageShowListing = showListingsError ? (
                <p className={css.error}>
                    <FormattedMessage id="EditListingCourtTypeForm.showListingFailed" />
                </p>
            ) : null;

            const options = findOptionsForSelectFilter(
                'courtType',
                filterConfig
            );
            return (
                <Form className={classes} onSubmit={handleSubmit}>
                    {errorMessage}
                    {errorMessageShowListing}

                    <div className={css.list}>
                        <ul className={css.outdoor}>
                            <legend className={css.label22}>OUTDOOR</legend>
                            {options.map((option, index) => {
                                const fieldId = `typeOutdoor.${option.key}`;
                                return (
                                    <li key={fieldId} className={css.item}>
                                        <FieldCheckbox
                                            id={fieldId}
                                            name="typeOutdoor"
                                            // label={option.label}
                                            value={option.key}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                        <ul className={css.indoor}>
                            <legend className={css.label22}>INDOOR</legend>
                            {options.map((option, index) => {
                                const fieldId = `typeIndoor.${option.key}`;
                                return (
                                    <li key={fieldId} className={css.item}>
                                        <FieldCheckbox
                                            id={fieldId}
                                            name="typeIndoor"
                                            label={option.label}
                                            value={option.key}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                    {/* <fieldset className={classes} style={{ display: 'flex' }}>
            <ul className={css.list} style={{ float: 'left', maxWidth: '5%' }}>
              {options.map((option, index) => {
                const fieldId = `outdoor.${option.key}`;
                return (
                  <li key={fieldId} className={css.item}>
                    <FieldCheckbox
                      id={fieldId}
                      name="outdoor"
                      // label={option.label}
                      value={option.key}
                    />
                  </li>
                );
              })}
            </ul>
            <ul className={css.features} style={{ float: 'left', maxWidth: '25%' }}>
              {options.map((option, index) => {
                const fieldId = `indoor.${option.key}`;
                return (
                  <li key={fieldId} className={css.item}>
                    <FieldCheckbox
                      id={fieldId}
                      name="indoor"
                      label={option.label}
                      value={option.key}
                    />
                  </li>
                );
              })}
            </ul>
            <ValidationError fieldMeta={{ ...meta }} />
          </fieldset> */}

                    {/* <FieldCheckboxGroup className={css.features} id="indoor" name="indoor" options={options} /> */}

                    <Button
                        className={css.submitButton}
                        type="submit"
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                        ready={submitReady}>
                        {saveActionMsg}
                    </Button>

                    {/* <p><FormattedMessage id="EditListingCourtTypeForm.findCourt" /></p> */}
                </Form>
            );
        }}
    />
);

EditListingCourtTypeFormComponent.defaultProps = {
    rootClassName: null,
    className: null,
    fetchErrors: null,
    filterConfig: config.custom.filters,
};

EditListingCourtTypeFormComponent.propTypes = {
    rootClassName: string,
    className: string,
    name: string.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
    fetchErrors: shape({
        showListingsError: propTypes.error,
        updateListingError: propTypes.error,
    }),
    filterConfig: propTypes.filterConfig,
};

const EditListingCourtTypeForm = EditListingCourtTypeFormComponent;

export default EditListingCourtTypeForm;
