import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './ImageFromSrc.module.css';
import IconSpinner from '../IconSpinner/IconSpinner';

// Create elements out of given thumbnail file
class ImageFromSrc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataURLS: null,
            isLoading: false,
        };
        this.readImage = this.readImage.bind(this);
        this.setLoading = this.setLoading.bind(this);
    }

    readImage = (temporalImage) => {
        const profileTemporalImage = (temporalImage && temporalImage.uploadedImage?.attributes?.variants['square-small2x'].url) ? temporalImage.uploadedImage?.attributes?.variants['square-small2x'].url : null;

        this.setState({ profileTemporalImage });
    };

    setLoading = (isLoading) => {
      this.setState({ isLoading });
    };

    componentDidMount() {
        this.readImage(this.props.file);
        this.setLoading(this.props.isLoading);
    }

    // Rerender if file changes
    componentDidUpdate(prevProps) {
        if (prevProps.file !== this.props.file) {
            this.readImage(this.props.file);
        }
        if (prevProps.isLoading !== this.props.isLoading) {
            this.setLoading(this.props.isLoading);
        }
    }

    render() {
        const {
            className,
            rootClassName,
            aspectRatioClassName,
            file,
            id,
            children,
        } = this.props;
        const classes = classNames(rootClassName || css.root, className);
        const aspectRatioClasses = aspectRatioClassName || css.aspectWrapper;

        return (
            <>
        {(this.state.profileTemporalImage || this.state.isLoading) && (
            <div className={classes}>
            <div className={css.threeToTwoWrapper}>
                <div className={classNames(aspectRatioClasses, this.state.isLoading ? css.loadingContainer : null)}>
                    {this.state.isLoading ?
                      <IconSpinner/> :
                      <img
                          src={this.state.profileTemporalImage}
                          alt="Profile Picture"
                          className={css.rootForImage}
                      />
                    }
                </div>
            </div>
            {children}
        </div>
        )}</>
        );
    }
}

ImageFromSrc.defaultProps = {
    className: null,
    children: null,
    rootClassName: null,
    aspectRatioClassName: null,
    isLoading: true
};

const { any, node, string } = PropTypes;

ImageFromSrc.propTypes = {
    className: string,
    rootClassName: string,
    aspectRatioClassName: string,
    file: any.isRequired,
    id: string.isRequired,
    children: node,
};

export default ImageFromSrc;
