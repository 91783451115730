import React from 'react';
import classNames from 'classnames';
import { ExternalLink } from '../../components';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build AddressLinkMaybe
const AddressLinkMaybe = props => {
    const {
        className,
        rootClassName,
        location,
        geolocation,
        showAddress,
        showHeader,
        toast,
        isCustomer,
    } = props;
    const { address, building } = location || {};
    const { lat, lng } = geolocation || {};
    const hrefToGoogleMaps = geolocation
        ? `https://maps.google.com/?q=${lat},${lng}`
        : address
            ? `https://maps.google.com/?q=${encodeURIComponent(address)}`
            : null;

    const directions =
        typeof building === 'string' && building.length > 0
            ? `${building}}`
            : address;

    const classes = classNames(rootClassName || css.address, className);
    return showAddress && hrefToGoogleMaps ? (
        <>
            <p className={classes} style={{ position: 'relative' }}>
                {showHeader && <p style={{ color: '#059669' }}>Court Location</p>}
                <ExternalLink href={hrefToGoogleMaps} className={css.linkColor}>
                    {address}
                </ExternalLink>
                {typeof building === 'string' && building.length > 0 && showHeader && (
                    <>
                        <br></br>
                        <p style={{ color: '#059669' }}>Directions</p>
                        <span className={css.extraDirections}>{building}</span>
                    </>
                )}
            </p>
            {isCustomer && toast}
        </>

    ) : null;
};

export default AddressLinkMaybe;
