import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
    Form,
    PrimaryButton,
    FieldTextInput,
    FieldPhoneNumberInput,
    FieldCheckbox,
    ImageFromFile,
    FieldSelect,
    Button,
    NamedLink,
    Avatar,
    IconSpinner,
} from '../../components';
import ImageCropModal from './ImageCropModal/ImageCropModal';

import config from '../../config';

import css from './CompleteProfileForm.module.css';
import ImageFromSrc from '../../components/ImageFromSrc/ImageFromSrc';

const KEY_CODE_ENTER = 13;
const ACCEPT_IMAGES = 'image/jpeg,image/jpg,image/png';

const CompleteProfileFormComponent = props => {

    // Image upload
    const [crop, setCrop] = useState(null);
    const [imgSrc, setImgSrc] = useState('');
    const [tempId, setTempId] = useState('');
    const [imageModalState, setImageModalState] = useState(false);


    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            // Makes crop preview update between images.
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                setImgSrc(reader.result.toString() || '')
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    return (
        <FinalForm
            {...props}
            render={fieldRenderProps => {
                const {
                    rootClassName,
                    className,
                    formId,
                    handleSubmit,
                    inProgress,
                    invalid,
                    intl,
                    onOpenTermsOfService,
                    values,
                    form,
                    onManageDisableScrolling,
                    onImageUpload,
                    profileImage,
                    uploadImageError,
                    uploadInProgress,
                    uploadTempInProgress,
                    updateProfileError,
                    image,
                    tempImage,
                    temporalImage,
                    setTempImage,
                    isNewUser
                } = fieldRenderProps;

                const uploadingOverlay =
                  uploadInProgress ? (
                    <div className={css.uploadingImageOverlay}>
                      <IconSpinner />
                    </div>
                  ) : null;

                let user = {};

                const hasUploadError = !!uploadImageError && !uploadInProgress;
                const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
                const transientUserProfileImage = profileImage.uploadedImage || user.profileImage;
                const transientUser = { ...user, profileImage: transientUserProfileImage };

                // Ensure that file exists if imageFromFile is used
                const fileExists = !!profileImage.file;
                const fileUploadInProgress = uploadInProgress && fileExists;
                const delayAfterUpload = profileImage.imageId;
                const imageFromFile =
                  fileExists && (fileUploadInProgress || delayAfterUpload) ? (
                    <ImageFromFile
                      id={profileImage.id}
                      className={errorClasses}
                      rootClassName={css.uploadingImage}
                      aspectWidth={1}
                      aspectHeight={1}
                      file={profileImage.file}
                    >
                      {uploadingOverlay}
                    </ImageFromFile>
                  ) : null;

                // Avatar is rendered in hidden during the upload delay
                // Upload delay smoothes image change process:
                // responsive img has time to load srcset stuff before it is shown to user.
                const avatarClasses = classNames(errorClasses, css.avatar, {
                  [css.avatarInvisible]: false,
                });
                const avatarComponent =
                  !fileUploadInProgress && profileImage.imageId ? (
                    <Avatar
                      className={avatarClasses}
                      renderSizes="(max-width: 767px) 96px, 240px"
                      user={transientUser}
                      disableProfileLink
                    />
                  ) : null;

                const chooseAvatarLabel =
                  profileImage.imageId || fileUploadInProgress ? (
                    <div className={css.avatarContainer}>
                      {fileUploadInProgress ? imageFromFile : null}
                      {avatarComponent}
                      <div className={css.changeAvatar}>
                        <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                      </div>
                    </div>
                  ) : (
                    <div className={css.avatarPlaceholder}>
                      <div className={css.avatarPlaceholderText}>
                        <FormattedMessage id="ProfileSettingsForm.addYourProfilePicture" />
                      </div>
                      <div className={css.avatarPlaceholderTextMobile}>
                        <FormattedMessage id="ProfileSettingsForm.addYourProfilePictureMobile" />
                      </div>
                    </div>
                  );

                const submitError = updateProfileError ? (
                  <div className={css.error}>
                    <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
                  </div>
                ) : null;

                const realUsPhoneNumber = validators.realUsPhoneNumber(
                    'Only US Phone Numbers'
                );

                // bio
                const bioPlaceholder = intl.formatMessage({
                    id: 'SignupForm.bioPlaceholder',
                });
                const bioRequiredMessage = intl.formatMessage({
                    id: 'SignupForm.bioRequired',
                });
                const bioRequired = validators.required(bioRequiredMessage);

                // zipCode
                const zipCodeLabel = intl.formatMessage({
                    id: 'SignupForm.zipCodeLabel',
                });
                const zipCodePlaceholder = intl.formatMessage({
                    id: 'SignupForm.zipCodePlaceholder',
                });
                const zipCodeRequiredMessage = intl.formatMessage({
                    id: 'SignupForm.zipCodeRequired',
                });
                const zipCodeRequired = validators.required(zipCodeRequiredMessage);

                // city
                const cityLabel = intl.formatMessage({
                    id: 'SignupForm.cityLabel',
                });
                const cityPlaceholder = intl.formatMessage({
                    id: 'SignupForm.cityPlaceholder',
                });
                const cityRequiredMessage = intl.formatMessage({
                    id: 'SignupForm.cityRequired',
                });
                const cityRequired = validators.required(cityRequiredMessage);

                // state
                const stateLabel = intl.formatMessage({
                    id: 'SignupForm.stateLabel',
                });
                const statePlaceholder = intl.formatMessage({
                    id: 'SignupForm.statePlaceholder',
                });
                const stateRequiredMessage = intl.formatMessage({
                    id: 'SignupForm.stateRequired',
                });
                const stateRequired = validators.required(stateRequiredMessage);

                // country
                const countryLabel = intl.formatMessage({
                    id: 'SignupForm.countryLabel',
                });
                const countryPlaceholder = intl.formatMessage({
                    id: 'SignupForm.countryPlaceholder',
                });
                const countryRequiredMessage = intl.formatMessage({
                    id: 'SignupForm.countryRequired',
                });
                const countryRequired = validators.required(countryRequiredMessage);

                // phoneNumber
                const phoneNumberLabel = intl.formatMessage({
                    id: 'SignupForm.phoneNumberLabel',
                });
                const phoneNumberPlaceholder = intl.formatMessage({
                    id: 'SignupForm.phoneNumberPlaceholder',
                });
                const phoneNumberRequiredMessage = intl.formatMessage({
                    id: 'SignupForm.phoneNumberRequired',
                });

                const classes = classNames(rootClassName || css.root, className);
                const submitInProgress = inProgress;
                const haveOneUserType = values && ((values["Player"] && values["Player"][0] === true) || (values["Coach"] && values["Coach"][0] === true) || (values["CourtOwner"] && values["CourtOwner"][0] === true))
                const submitDisabled = invalid || submitInProgress || !haveOneUserType || !image;
                const handleTermsKeyUp = e => {
                    // Allow click action with keyboard like with normal links
                    if (e.keyCode === KEY_CODE_ENTER) {
                        onOpenTermsOfService();
                    }
                };

                const termsLink = (
                    <span
                        className={css.termsLink}
                        onClick={onOpenTermsOfService}
                        role="button"
                        tabIndex="0"
                        onKeyUp={handleTermsKeyUp}>
                        <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
                    </span>
                );

                const countries = config?.custom?.countryList;

                const labelForImage = uploadTempInProgress || temporalImage ? (

                    <div className={css.avatarContainer}>
                        <ImageFromSrc
                            id={temporalImage.id}
                            className={errorClasses}
                            rootClassName={css.uploadingImage}
                            aspectRatioClassName={css.squareAspectRatio}
                            isLoading={uploadTempInProgress}
                            file={temporalImage}
                        />
                        <div className={css.changeAvatar}>
                            <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                        </div>
                    </div>
                ) : (
                    <div className={css.avatarPlaceholder}>
                        <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="camera">
                                <g id="vuesax/linear/camera">
                                    <g id="camera_2">
                                        <path id="Vector" d="M6.75992 22H17.2399C19.9999 22 21.0999 20.31 21.2299 18.25L21.7499 9.99C21.8899 7.83 20.1699 6 17.9999 6C17.3899 6 16.8299 5.65 16.5499 5.11L15.8299 3.66C15.3699 2.75 14.1699 2 13.1499 2H10.8599C9.82992 2 8.62992 2.75 8.16992 3.66L7.44992 5.11C7.16992 5.65 6.60992 6 5.99992 6C3.82992 6 2.10992 7.83 2.24992 9.99L2.76992 18.25C2.88992 20.31 3.99992 22 6.75992 22Z" stroke="#121212" strokeOpacity="0.38" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="#e7e7e7" />
                                        <path id="Vector_2" d="M10.5 8H13.5" stroke="#121212" strokeOpacity="0.38" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="#e7e7e7" />
                                        <path id="Vector_3" d="M12 18C13.79 18 15.25 16.54 15.25 14.75C15.25 12.96 13.79 11.5 12 11.5C10.21 11.5 8.75 12.96 8.75 14.75C8.75 16.54 10.21 18 12 18Z" stroke="#121212" strokeOpacity="0.38" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="#e7e7e7" />
                                    </g>
                                </g>
                            </g>
                        </svg>

                    </div>
                );

                return (
                    <Form className={classes} onSubmit={handleSubmit}>
                        <div className={css.steps}>
                            <div className={css.step}>
                                <FormattedMessage id="SignupForm.step2" />
                            </div>
                        </div>
                        <h2 className={css.signupTitle}>
                            <FormattedMessage id={isNewUser ? "SignupForm.signupTitle" : "SignupForm.completeTitle"} />
                        </h2>
                        <div>
                            <div className={css.sectionContainer}>
                                {/* <ImageCropModal
                                    crop={crop}
                                    setCrop={setCrop}
                                    imgSrc={imgSrc}
                                    tempId={tempId}
                                    onImageUpload={setTempImage}
                                    onManageDisableScrolling={
                                        onManageDisableScrolling
                                    }
                                    imageModalState={imageModalState}
                                    closeImageModal={() => {
                                        setImageModalState(false);
                                    }}
                                />
                                <Field
                                    accept={ACCEPT_IMAGES}
                                    id="profileImage"
                                    name="profileImage"
                                    label={labelForImage}
                                    type="file"
                                    form={null}
                                    uploadImageError={uploadImageError}
                                    disabled={uploadInProgress}
                                >
                                    {fieldProps => {
                                        const {
                                            accept,
                                            id,
                                            input,
                                            label,
                                            disabled,
                                            uploadImageError,
                                        } = fieldProps;
                                        const { name, type } = input;
                                        const onChange = e => {
                                            const file = e.target.files[0];
                                            form.change(`profileImage`, file);
                                            form.blur(`profileImage`);
                                            if (file != null) {
                                                setImageModalState(true);
                                                setTempId(file.name);
                                                onSelectFile(e);
                                            }
                                        };

                                        let error = null;

                                        if (
                                            isUploadImageOverLimitError(
                                                uploadImageError
                                            )
                                        ) {
                                            error = (
                                                <div className={css.error}>
                                                    <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                                                </div>
                                            );
                                        } else if (uploadImageError) {
                                            error = (
                                                <div className={css.error}>
                                                    <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                                                </div>
                                            );
                                        }

                                        return (
                                            <div
                                                className={
                                                    css.uploadAvatarWrapper
                                                }>
                                                <label
                                                    className={css.label}
                                                    htmlFor={id}>
                                                    {label}
                                                </label>
                                                <input
                                                    accept={accept}
                                                    id={id}
                                                    name={name}
                                                    className={
                                                        css.uploadAvatarInput
                                                    }
                                                    disabled={disabled}
                                                    onChange={onChange}
                                                    type={type}
                                                />
                                                {error}
                                            </div>
                                        );
                                    }}
                                </Field> */}

                                <Field
                                  accept={ACCEPT_IMAGES}
                                  id="profileImage"
                                  name="profileImage"
                                  label={chooseAvatarLabel}
                                  type="file"
                                  form={null}
                                  uploadImageError={uploadImageError}
                                  disabled={uploadInProgress}
                                >
                                  {fieldProps => {
                                    const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                                    const { name, type } = input;
                                    const onChange = e => {
                                      const file = e.target.files[0];
                                      form.change(`profileImage`, file);
                                      form.blur(`profileImage`);
                                      if (file != null) {
                                        const tempId = `${file.name}_${Date.now()}`;
                                        onImageUpload({ id: tempId, file });
                                      }
                                    };

                                    let error = null;

                                    if (isUploadImageOverLimitError(uploadImageError)) {
                                      error = (
                                        <div className={css.error}>
                                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                                        </div>
                                      );
                                    } else if (uploadImageError) {
                                      error = (
                                        <div className={css.error}>
                                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                                        </div>
                                      );
                                    }

                                    return (
                                      <div className={css.uploadAvatarWrapper}>
                                        <label className={css.label} htmlFor={id}>
                                          {label}
                                        </label>
                                        <input
                                          accept={accept}
                                          id={id}
                                          name={name}
                                          className={css.uploadAvatarInput}
                                          disabled={disabled}
                                          onChange={onChange}
                                          type={type}
                                        />
                                        {error}
                                      </div>
                                    );
                                  }}
                                </Field>

                                    <p className={classNames(css.avatarNote, {
                                        [css.avatarNoteError]: !image
                                    })}>
                                        <FormattedMessage id="ProfileSettingsForm.avatarNote" />
                                    </p>
                            </div>
                            <FieldTextInput
                                className={css.field}
                                inputRootClass={css.textarea}
                                type="textarea"
                                id={
                                    formId
                                        ? `${formId}.bio`
                                        : 'bio'
                                }
                                name="bio"
                                placeholder={bioPlaceholder}
                                validate={bioRequired}
                                rows={3}
                            />
                            <div className={css.double}>
                                <FieldSelect
                                    className={css.field}
                                    selectClassName={css.inputNoIcon}
                                    name="country"
                                    id={
                                        formId
                                            ? `${formId}.country`
                                            : 'country'
                                    }
                                    label={countryLabel}
                                    validate={countryRequired}
                                >
                                    <option hidden value="">
                                        {countryPlaceholder}
                                    </option>
                                    {countries.map((country, index) => (
                                        <option value={country} key={index}>
                                            {country}
                                        </option>
                                    ))}
                                </FieldSelect>
                                <FieldTextInput
                                    className={css.field}
                                    inputRootClass={css.inputNoIcon}
                                    type="text"
                                    id={formId ? `${formId}.city` : 'city'}
                                    name="city"
                                    label={cityLabel}
                                    placeholder={cityPlaceholder}
                                    validate={cityRequired}
                                />
                                <FieldTextInput
                                    className={css.field}
                                    inputRootClass={css.inputNoIcon}
                                    type="text"
                                    id={
                                        formId ? `${formId}.state` : 'state'
                                    }
                                    name="state"
                                    label={stateLabel}
                                    placeholder={statePlaceholder}
                                    validate={stateRequired}
                                />
                            </div>
                            <div className={css.double}>
                                <div className={css.phoneField}>
                                    <FieldPhoneNumberInput
                                        className={css.field}
                                        inputRootClass={classNames(css.input, css.phonefield)}
                                        name="phoneNumber"
                                        id={
                                            formId
                                                ? `${formId}.phoneNumber`
                                                : 'phoneNumber'
                                        }
                                        label={phoneNumberLabel}
                                        placeholder={phoneNumberPlaceholder}
                                        shouldAddPlusOne={false}
                                        validate={validators.composeValidators(
                                            realUsPhoneNumber
                                        )}
                                        icon={<div className={css.fieldIcon}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                                <path d="M14.6466 13.1289C14.6466 13.3689 14.5933 13.6156 14.4799 13.8556C14.3666 14.0956 14.2199 14.3222 14.0266 14.5356C13.6999 14.8956 13.3399 15.1556 12.9333 15.3222C12.5333 15.4889 12.0999 15.5756 11.6333 15.5756C10.9533 15.5756 10.2266 15.4156 9.45992 15.0889C8.69325 14.7622 7.92658 14.3222 7.16658 13.7689C6.39992 13.2089 5.67325 12.5889 4.97992 11.9022C4.29325 11.2089 3.67325 10.4822 3.11992 9.72225C2.57325 8.96225 2.13325 8.20225 1.81325 7.44892C1.49325 6.68892 1.33325 5.96225 1.33325 5.26891C1.33325 4.81558 1.41325 4.38225 1.57325 3.98225C1.73325 3.57558 1.98659 3.20225 2.33992 2.86892C2.76659 2.44892 3.23325 2.24225 3.72659 2.24225C3.91325 2.24225 4.09992 2.28225 4.26659 2.36225C4.43992 2.44225 4.59325 2.56225 4.71325 2.73558L6.25992 4.91558C6.37992 5.08225 6.46658 5.23558 6.52658 5.38225C6.58658 5.52225 6.61992 5.66225 6.61992 5.78892C6.61992 5.94892 6.57325 6.10891 6.47992 6.26225C6.39325 6.41558 6.26658 6.57558 6.10658 6.73558L5.59992 7.26225C5.52658 7.33558 5.49325 7.42225 5.49325 7.52892C5.49325 7.58225 5.49992 7.62891 5.51325 7.68225C5.53325 7.73558 5.55325 7.77558 5.56659 7.81558C5.68659 8.03558 5.89325 8.32225 6.18658 8.66892C6.48658 9.01558 6.80658 9.36891 7.15325 9.72225C7.51325 10.0756 7.85992 10.4022 8.21325 10.7022C8.55992 10.9956 8.84658 11.1956 9.07325 11.3156C9.10658 11.3289 9.14658 11.3489 9.19325 11.3689C9.24658 11.3889 9.29992 11.3956 9.35992 11.3956C9.47325 11.3956 9.55992 11.3556 9.63325 11.2822L10.1399 10.7822C10.3066 10.6156 10.4666 10.4889 10.6199 10.4089C10.7733 10.3156 10.9266 10.2689 11.0933 10.2689C11.2199 10.2689 11.3533 10.2956 11.4999 10.3556C11.6466 10.4156 11.7999 10.5022 11.9666 10.6156L14.1733 12.1822C14.3466 12.3022 14.4666 12.4422 14.5399 12.6089C14.6066 12.7756 14.6466 12.9422 14.6466 13.1289Z" stroke="#121212" strokeWidth="1.2" strokeMiterlimit="10" fill="none" />
                                            </svg> +1
                                        </div>}
                                    />
                                    <p className={css.phoneNumberNote}>
                                        <FormattedMessage id="SignupForm.phoneNumberNote" />
                                    </p>
                                </div>
                                <FieldTextInput
                                    className={classNames(css.field, css.zipCodeInput)}
                                    inputRootClass={css.inputNoIcon}
                                    type="text"
                                    id={
                                        formId ? `${formId}.zipCode` : 'zipCode'
                                    }
                                    name="zipCode"
                                    label={zipCodeLabel}
                                    placeholder={zipCodePlaceholder}
                                    validate={zipCodeRequired}
                                />
                            </div>
                            <div className={css.userTypeField}>
                                <legend>
                                    <FormattedMessage id="SignupForm.whatKindOfPlayerAreYou" />
                                </legend>
                                <div className={css.userTypeInputWrapper}>
                                    <label className={css.userTypeLabel}>
                                        <FieldCheckbox
                                            id={'Player'}
                                            name={'Player'}
                                            value={true}
                                            className={css.check}
                                        />
                                        <span className={classNames(css.userTypeText, {
                                            [css.userTypeTextActive]: values && values["Player"] && values["Player"][0] === true
                                        })}>
                                            Player
                                        </span>
                                    </label>
                                    <label className={css.userTypeLabel}>
                                        <FieldCheckbox
                                            id={'Coach'}
                                            name={'Coach'}
                                            value={true}
                                            className={css.check}
                                        />
                                        <span className={classNames(css.userTypeText, {
                                            [css.userTypeTextActive]: values && values["Coach"] && values["Coach"][0] === true
                                        })}>
                                            Coach
                                        </span>
                                    </label>
                                    <label className={css.userTypeLabel}>
                                        <FieldCheckbox
                                            id={'CourtOwner'}
                                            name={'CourtOwner'}
                                            value={true}
                                            className={css.check}
                                        />
                                        <span className={classNames(css.userTypeText, {
                                            [css.userTypeTextActive]: values && values["CourtOwner"] && values["CourtOwner"][0] === true
                                        })}>
                                            Court Owner
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className={css.bottomWrapper}>
                            <p className={css.bottomWrapperText}>
                                <span className={css.termsText}>
                                    <FormattedMessage
                                        id="SignupForm.termsAndConditionsAcceptText"
                                        values={{ termsLink }}
                                    />
                                </span>
                            </p>

                            <Button
                                className={css.button}
                                type="submit"
                                inProgress={submitInProgress}
                                disabled={submitDisabled}>
                                <FormattedMessage id={isNewUser ? "SignupForm.createAccount" : "SignupForm.completeAccount"} />
                            </Button>


                        </div>
                    </Form>
                );
            }}
        />
    )
};

CompleteProfileFormComponent.defaultProps = {
    inProgress: false,
    uploadTempInProgress: false,
    uploadTempImageError: null,
    image: null,
    temporalImage: null
};

const { bool, func } = PropTypes;

CompleteProfileFormComponent.propTypes = {
    inProgress: bool,
    uploadTempInProgress: bool,
    onOpenTermsOfService: func.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const CompleteProfileForm = compose(injectIntl)(CompleteProfileFormComponent);
CompleteProfileForm.displayName = 'CompleteProfileForm';

export default CompleteProfileForm;
