import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { createSlug, stringify } from '../../util/urlHelpers';
import { NamedLink } from '../../components';

import css from './TransactionPanel.module.css';

export const HEADING_ENQUIRED = 'enquired';
export const HEADING_PAYMENT_PENDING = 'pending-payment';
export const HEADING_PAYMENT_EXPIRED = 'payment-expired';
export const HEADING_REQUESTED = 'requested';
export const HEADING_ACCEPTED = 'accepted';
export const HEADING_DECLINED = 'declined';
export const HEADING_CANCELED = 'canceled';
export const HEADING_DELIVERED = 'delivered';

const createListingLink = (
    listingId,
    label,
    listingDeleted,
    searchParams = {},
    className = ''
) => {
    if (!listingDeleted) {
        const params = { id: listingId, slug: createSlug(label) };
        const to = { search: stringify(searchParams) };
        return (
            <NamedLink
                className={css.enquiryButton}
                name="ListingPage"
                params={params}
                to={to}>
                <FormattedMessage id="EnquiryMessage.link" />
            </NamedLink>
        );
    } else {
        return (
            <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />
        );
    }
};

// Functional component as a helper to choose and show Order or Sale heading info:
// title, subtitle, and message
const EnquiryMessage = props => {
    const { listingId, listingTitle, listingDeleted } = props;

    const listingLink = createListingLink(
        listingId,
        listingTitle,
        listingDeleted
    );

    return <div className={css.enquiryMessageContainer}>{listingLink}</div>;
};

export default EnquiryMessage;
