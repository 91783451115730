import isEmpty from 'lodash/isEmpty';
import { denormalisedResponseEntities } from '../util/data';
import { clearCurrentUser, fetchCurrentUser, currentUserShowSuccess } from './user.duck';
import { createUserWithIdp, createUser, updateUser } from '../util/api';
import { storableError } from '../util/errors';
import * as log from '../util/log';

const authenticated = authInfo => authInfo && authInfo.isAnonymous === false;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/Auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/Auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/Auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/Auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/Auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/Auth/SIGNUP_ERROR';

export const CONFIRM_REQUEST = 'app/Auth/CONFIRM_REQUEST';
export const CONFIRM_SUCCESS = 'app/Auth/CONFIRM_SUCCESS';
export const CONFIRM_ERROR = 'app/Auth/CONFIRM_ERROR';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

export const UPLOAD_IMAGE_REQUEST =
    'app/Auth/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_TEMP_IMAGE_REQUEST =
    'app/Auth/UPLOAD_TEMP_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS =
    'app/Auth/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_TEMP_IMAGE_SUCCESS =
    'app/Auth/UPLOAD_TEMP_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/Auth/UPLOAD_IMAGE_ERROR';
export const UPLOAD_TEMP_IMAGE_ERROR = 'app/Auth/UPLOAD_TEMP_IMAGE_ERROR';



export const UPDATE_PROFILE_REQUEST =
    'app/Auth/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS =
    'app/Auth/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR =
    'app/Auth/UPDATE_PROFILE_ERROR';


// ================ Reducer ================ //

const initialState = {
    isAuthenticated: false,

    // scopes associated with current token
    authScopes: [],

    // auth info
    authInfoLoaded: false,

    // login
    loginError: null,
    loginInProgress: false,

    // logout
    logoutError: null,
    logoutInProgress: false,

    // signup
    signupError: null,
    signupInProgress: false,

    // confirm (create use with idp)
    confirmError: null,
    confirmInProgress: false,

    // profile picture
    image: null,
    tempImage: null,
    uploadImageError: null,
    uploadTempImageError: null,
    uploadInProgress: false,
    uploadTempInProgress: false,

    // profile
    updateInProgress: false,
    updateProfileError: null,
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case AUTH_INFO_REQUEST:
            return state;
        case AUTH_INFO_SUCCESS:
            return {
                ...state,
                authInfoLoaded: true,
                isAuthenticated: authenticated(payload),
                authScopes: payload.scopes,
            };

        case LOGIN_REQUEST:
            return {
                ...state,
                loginInProgress: true,
                loginError: null,
                logoutError: null,
                signupError: null,
            };
        case LOGIN_SUCCESS:
            return { ...state, loginInProgress: false, isAuthenticated: true };
        case LOGIN_ERROR:
            return { ...state, loginInProgress: false, loginError: payload };

        case LOGOUT_REQUEST:
            return {
                ...state,
                logoutInProgress: true,
                loginError: null,
                logoutError: null,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                logoutInProgress: false,
                isAuthenticated: false,
                authScopes: [],
            };
        case LOGOUT_ERROR:
            return { ...state, logoutInProgress: false, logoutError: payload };

        case SIGNUP_REQUEST:
            return {
                ...state,
                signupInProgress: true,
                loginError: null,
                signupError: null,
            };
        case SIGNUP_SUCCESS:
            return { ...state, signupInProgress: false };
        case SIGNUP_ERROR:
            return { ...state, signupInProgress: false, signupError: payload };

        case CONFIRM_REQUEST:
            return {
                ...state,
                confirmInProgress: true,
                loginError: null,
                confirmError: null,
            };
        case CONFIRM_SUCCESS:
            return {
                ...state,
                confirmInProgress: false,
                isAuthenticated: true,
            };
        case CONFIRM_ERROR:
            return {
                ...state,
                confirmInProgress: false,
                confirmError: payload,
            };
        case UPLOAD_IMAGE_REQUEST:
            // payload.params: { id: 'tempId', file }
            return {
                ...state,
                image: { ...payload.params },
                uploadInProgress: true,
                uploadImageError: null,
            };
        case UPLOAD_TEMP_IMAGE_REQUEST:
            // payload.params: { id: 'tempId', file }
            return {
                ...state,
                tempImage: { ...payload.params },
                uploadTempInProgress: true,
                uploadTempImageError: null,
            };
        case UPLOAD_IMAGE_SUCCESS: {
            // payload: { id: 'tempId', uploadedImage }
            const { id, uploadedImage } = payload;
            const { file } = state.image || {};
            const image = {
                id,
                imageId: uploadedImage.id,
                file,
                uploadedImage,
            };
            return { ...state, image, uploadInProgress: false };
        }
        case UPLOAD_TEMP_IMAGE_SUCCESS: {
            // payload: { id: 'tempId', uploadedImage }
            const { id, uploadedImage } = payload;
            const { file } = state.image || {};
            const image = {
                id,
                imageId: uploadedImage.id,
                file,
                uploadedImage,
            };
            return { ...state, tempImage: image, uploadTempInProgress: false };
        }
        case UPLOAD_IMAGE_ERROR: {
            // eslint-disable-next-line no-console
            return {
                ...state,
                image: null,
                uploadImageError: payload.error,
            };
        }
        case UPLOAD_TEMP_IMAGE_ERROR: {
            // eslint-disable-next-line no-console
            return {
                ...state,
                tempImage: null,
                uploadTempImageError: payload.error,
            };
        }

        case UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                updateInProgress: true,
                updateProfileError: null,
            };
        case UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                image: null,
                updateInProgress: false,
            };
        case UPDATE_PROFILE_ERROR:
            return {
                ...state,
                image: null,
                updateInProgress: false,
                updateProfileError: payload,
            };

        default:
            return state;
    }
}

// ================ Selectors ================ //

export const authenticationInProgress = state => {
    const { loginInProgress, logoutInProgress, signupInProgress } = state.Auth;
    return loginInProgress || logoutInProgress || signupInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = info => ({
    type: AUTH_INFO_SUCCESS,
    payload: info,
});

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = error => ({
    type: LOGIN_ERROR,
    payload: error,
    error: true,
});

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({
    type: LOGOUT_ERROR,
    payload: error,
    error: true,
});

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = error => ({
    type: SIGNUP_ERROR,
    payload: error,
    error: true,
});

export const confirmRequest = () => ({ type: CONFIRM_REQUEST });
export const confirmSuccess = () => ({ type: CONFIRM_SUCCESS });
export const confirmError = error => ({
    type: CONFIRM_ERROR,
    payload: error,
    error: true,
});

export const userLogout = () => ({ type: USER_LOGOUT });

// SDK method: images.upload
export const uploadImageRequest = params => ({
    type: UPLOAD_IMAGE_REQUEST,
    payload: { params },
});
export const uploadTempImageRequest = params => ({
    type: UPLOAD_TEMP_IMAGE_REQUEST,
    payload: { params },
});
export const uploadImageSuccess = result => ({
    type: UPLOAD_IMAGE_SUCCESS,
    payload: result.data,
});
export const uploadImageError = error => ({
    type: UPLOAD_IMAGE_ERROR,
    payload: error,
    error: true,
});
export const uploadTempImageSuccess = result => ({
    type: UPLOAD_TEMP_IMAGE_SUCCESS,
    payload: result.data,
});
export const uploadTempImageError = error => ({
    type: UPLOAD_TEMP_IMAGE_ERROR,
    payload: error,
    error: true,
});

// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = params => ({
    type: UPDATE_PROFILE_REQUEST,
    payload: { params },
});
export const updateProfileSuccess = result => ({
    type: UPDATE_PROFILE_SUCCESS,
    payload: result.data,
});
export const updateProfileError = error => ({
    type: UPDATE_PROFILE_ERROR,
    payload: error,
    error: true,
});

// ================ Thunks ================ //

// Images return imageId which we need to map with previously generated temporary id
export function uploadImage(actionPayload) {
    return (dispatch, getState, sdk) => {
      const id = actionPayload.id;
      dispatch(uploadImageRequest(actionPayload));

      const bodyParams = {
        image: actionPayload.file,
      };
      const queryParams = {
        expand: true,
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      };

      return sdk.images
        .upload(bodyParams, queryParams)
        .then(resp => {
          const uploadedImage = resp.data.data;
          dispatch(uploadImageSuccess({ data: { id, uploadedImage } }));
        })
        .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
    };
}

// Images return imageId which we need to map with previously generated temporary id
export function uploadTempImage(actionPayload) {
    return (dispatch, getState, sdk) => {
        const id = actionPayload.id;
        dispatch(uploadTempImageRequest(actionPayload));

        const bodyParams = {
            image: actionPayload.file,
        };
        const queryParams = {
            expand: true,
            'fields.image': [
                'variants.square-small',
                'variants.square-small2x',
            ],
        };

        return sdk.images
            .upload(bodyParams, queryParams)
            .then(resp => {
                const uploadedImage = resp.data.data;
                dispatch(uploadTempImageSuccess({ data: { id, uploadedImage } }));
                return uploadedImage;
            })
            .catch(e =>
                dispatch(uploadTempImageError({ id, error: storableError(e) }))
            );
    };
}

export const updateProfile = (actionPayload, redirect) => {
    return (dispatch, getState, sdk) => {
        dispatch(updateProfileRequest());

        const { image, ...profile } = actionPayload;

        /* const imageId = image.id;
        const bodyParams = {
            image: image.file,
        };
        const queryParamsImage = {
            expand: true,
            'fields.image': [
                'variants.square-small',
                'variants.square-small2x',
            ],
        };*/

        const queryParams = {
            expand: true,
            include: ['profileImage'],
            'fields.image': [
                'variants.square-small',
                'variants.square-small2x',
            ],
        };

        /* return sdk.images
            .upload(bodyParams, queryParamsImage)
            .then(resp => {
                const uploadedImage = resp.data.data;
                dispatch(uploadImageSuccess({ data: { imageId, uploadedImage } })); */
                const { uploadedImage } = image;
                return sdk.currentUser
                    .updateProfile({ profileImageId: uploadedImage.id, ...profile}, queryParams)
                    .then(response => {
                        updateUser({ profileImageId: uploadedImage.id, ...profile, response }).then(res => {
                          console.log(res);
                        });
                        dispatch(updateProfileSuccess(response));

                        const entities = denormalisedResponseEntities(response);
                        if (entities.length !== 1) {
                            throw new Error(
                                'Expected a resource in the sdk.currentUser.updateProfile response'
                            );
                        }
                        const currentUser = entities[0];

                        // Update current user in state.user.currentUser through user.duck.js
                        dispatch(currentUserShowSuccess(currentUser));
                    })
                    .catch(e => dispatch(updateProfileError(storableError(e))));
            /* })
            .catch(e =>
                dispatch(uploadImageError({ imageId, error: storableError(e) }))
            ); */

    };
};

export const authInfo = () => (dispatch, getState, sdk) => {
    dispatch(authInfoRequest());
    return sdk
        .authInfo()
        .then(info => dispatch(authInfoSuccess(info)))
        .catch(e => {
            // Requesting auth info just reads the token from the token
            // store (i.e. cookies), and should not fail in normal
            // circumstances. If it fails, it's due to a programming
            // error. In that case we mark the operation done and dispatch
            // `null` success action that marks the user as unauthenticated.
            log.error(e, 'auth-info-failed');
            dispatch(authInfoSuccess(null));
        });
};

export const login = (username, password) => (dispatch, getState, sdk) => {
    if (authenticationInProgress(getState())) {
        return Promise.reject(new Error('Login or logout already in progress'));
    }
    dispatch(loginRequest());

    // Note that the thunk does not reject when the login fails, it
    // just dispatches the login error action.
    return sdk
        .login({ username, password })
        .then(() => dispatch(loginSuccess()))
        .then(() => dispatch(fetchCurrentUser()))
        .catch(e => dispatch(loginError(storableError(e))));
};

export const logout = () => (dispatch, getState, sdk) => {
    if (authenticationInProgress(getState())) {
        return Promise.reject(new Error('Login or logout already in progress'));
    }
    dispatch(logoutRequest());

    // Note that the thunk does not reject when the logout fails, it
    // just dispatches the logout error action.
    return sdk
        .logout()
        .then(() => {
            // The order of the dispatched actions
            dispatch(logoutSuccess());
            dispatch(clearCurrentUser());
            log.clearUserId();
            dispatch(userLogout());
        })
        .catch(e => dispatch(logoutError(storableError(e))));
};

export const signup = params => (dispatch, getState, sdk) => {
    if (authenticationInProgress(getState())) {
        return Promise.reject(new Error('Login or logout already in progress'));
    }
    dispatch(signupRequest());
    const { email, password, firstName, lastName, bio, ...rest } = params;

    var playerType = [];
    if (params.Player) {
        playerType.push('player');
    }
    if (params.Coach) {
        playerType.push('coach');
    }
    if (params.CourtOwner) {
        playerType.push('court_owner');
    }

    const createUserParams = isEmpty(rest)
        ? { email, password, firstName, lastName }
        : {
            email,
            password,
            firstName,
            lastName,
            bio,
            publicData: { playerType: playerType, ...rest },
        };

    const userDetails = {
        email: createUserParams.email,
        first_name: createUserParams.firstName,
        last_name: createUserParams.lastName,
        playerType: playerType,
        ...rest,
    };

    // We must login the user if signup succeeds since the API doesn't
    // do that automatically.
    return sdk.currentUser
        .create(createUserParams)
        .then(() => {
            createUser(userDetails).then(res => {
                console.log(res);
            });
            if (typeof window !== 'undefined') {
                window.fbq('track', 'pageView');
                window.fbq('track', 'CompleteRegistration');
                window.dataLayer.push({
                    event: 'SignUp',
                });
            }
            dispatch(signupSuccess());
        })
        .then(() => dispatch(login(email, password)))
        .catch(e => {
            dispatch(signupError(storableError(e)));
            window.scrollTo(0, 0);
            log.error(e, 'signup-failed', {
                email: params.email,
                firstName: params.firstName,
                lastName: params.lastName,
            });
        });
};

export const signupWithIdp = params => (dispatch, getState, sdk) => {
    dispatch(confirmRequest());
    return createUserWithIdp(params)
        .then(res => {
            return dispatch(confirmSuccess());
        })
        .then(() => dispatch(fetchCurrentUser()))
        .catch(e => {
            log.error(e, 'create-user-with-idp-failed', { params });
            return dispatch(confirmError(storableError(e)));
        });
};
