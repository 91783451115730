import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
// import moment from 'moment';
import moment from 'moment-timezone';
import './Calendar.module.css';
import './react-big-calendar.css';

// const localizer = momentLocalizer(moment);

const Calendar = ({ events, eventStyleGetter, ...props }) => {
    const localizer = props.timeZone
        ? momentLocalizer(moment.tz.setDefault(props.timeZone))
        : momentLocalizer(moment);
    return (
        <div style={{ margin: '3rem 0px' }}>
            <BigCalendar
                {...props}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 800 }}
                views={['month', 'week', 'day']}
                localizer={localizer}
                eventPropGetter={eventStyleGetter}
            />
        </div>
    );
};
export default Calendar;
