import React, { Component } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
    nonEmptyArray,
    composeValidators,
    youtubeLinkValidator,
} from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
    AddImages,
    Button,
    Form,
    ValidationError,
    FieldTextInput,
    IconSpinner,
} from '../../components';

import css from './EditListingMediaForm.module.css';

const ACCEPT_IMAGES = 'image/*';

export class EditListingMediaFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageUploadRequested: false,
            submitButtonDisabled: false,
        };
        this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
        this.submittedImages = [];
    }

    onImageUploadHandler(file) {
        if (file) {
            this.setState({ imageUploadRequested: true });
            this.props
                .onImageUpload({ id: `${file.name}_${Date.now()}`, file })
                .then((response) => {
                    this.setState({
                        imageUploadRequested: false,
                        submitButtonDisabled: false,
                    });
                })
                .catch(() => {
                    this.setState({ imageUploadRequested: false });
                });
        }
    }

    render() {
        return (
            <FinalForm
                {...this.props}
                onImageUploadHandler={this.onImageUploadHandler}
                imageUploadRequested={this.state.imageUploadRequested}
                initialValues={{
                    images: this.props.images,
                    youtubeLink: this.props.initialValues.youtubeLink,
                }}
                render={formRenderProps => {
                    const {
                        form,
                        className,
                        fetchErrors,
                        handleSubmit,
                        images,
                        imageUploadRequested,
                        intl,
                        invalid,
                        onImageUploadHandler,
                        onRemoveImage,
                        disabled,
                        ready,
                        saveActionMsg,
                        updated,
                        updateInProgress,
                    } = formRenderProps;

                    const chooseImageText = this.state.imageUploadRequested ? (
                        <div className={css.thumbnailLoading}>
                            <IconSpinner />
                        </div>
                    ) : (
                        <span className={css.chooseImageText}>
                            <span className={css.chooseImage}>
                                <FormattedMessage id="EditListingMediaForm.chooseImage" />
                            </span>
                            <span className={css.imageTypes}>
                                <FormattedMessage id="EditListingMediaForm.imageTypes" />
                            </span>
                        </span>
                    );

                    const imageRequiredMessage = intl.formatMessage({
                        id: 'EditListingMediaForm.imageRequired',
                    });

                    const youtubeLinkMessage = intl.formatMessage({
                        id: 'EditListingMediaForm.youtubeLink',
                    });
                    const youtubeLinkPlaceholderMessage = intl.formatMessage({
                        id: 'EditListingMediaForm.youtubeLinkPlaceholder',
                    });

                    const {
                        publishListingError,
                        showListingsError,
                        updateListingError,
                        uploadImageError,
                    } = fetchErrors || {};
                    const uploadOverLimit = isUploadImageOverLimitError(
                        uploadImageError
                    );

                    let uploadImageFailed = null;

                    if (uploadOverLimit) {
                        uploadImageFailed = (
                            <p className={css.error}>
                                <FormattedMessage id="EditListingMediaForm.imageUploadFailed.uploadOverLimit" />
                            </p>
                        );
                    } else if (uploadImageError) {
                        uploadImageFailed = (
                            <p className={css.error}>
                                <FormattedMessage id="EditListingMediaForm.imageUploadFailed.uploadFailed" />
                            </p>
                        );
                    }

                    // NOTE: These error messages are here since Photos panel is the last visible panel
                    // before creating a new listing. If that order is changed, these should be changed too.
                    // Create and show listing errors are shown above submit button
                    const publishListingFailed = publishListingError ? (
                        <p className={css.error}>
                            <FormattedMessage id="EditListingMediaForm.publishListingFailed" />
                        </p>
                    ) : null;
                    const showListingFailed = showListingsError ? (
                        <p className={css.error}>
                            <FormattedMessage id="EditListingMediaForm.showListingFailed" />
                        </p>
                    ) : null;

                    const submittedOnce = this.submittedImages.length > 0;
                    // imgs can contain added images (with temp ids) and submitted images with uniq ids.
                    const arrayOfImgIds = imgs =>
                        imgs.map(i =>
                            typeof i.id === 'string' ? i.imageId : i.id
                        );
                    const imageIdsFromProps = arrayOfImgIds(images);
                    const imageIdsFromPreviousSubmit = arrayOfImgIds(
                        this.submittedImages
                    );
                    const imageArrayHasSameImages = isEqual(
                        imageIdsFromProps,
                        imageIdsFromPreviousSubmit
                    );
                    const pristineSinceLastSubmit =
                        submittedOnce && imageArrayHasSameImages;

                    const submitReady =
                        (updated && pristineSinceLastSubmit) ||
                        ready ||
                        this.state.submitButtonDisabled;
                    const submitInProgress = updateInProgress;
                    const submitDisabled =
                        invalid ||
                        disabled ||
                        submitInProgress ||
                        imageUploadRequested ||
                        ready ||
                        this.state.submitButtonDisabled;

                    const classes = classNames(css.root, className);

                    return (
                        <Form
                            className={classes}
                            onSubmit={e => {
                                this.submittedImages = images;
                                this.setState({ submitButtonDisabled: true });
                                handleSubmit(e);
                            }}>
                            {updateListingError ? (
                                <p className={css.error}>
                                    <FormattedMessage id="EditListingMediaForm.updateFailed" />
                                </p>
                            ) : null}
                            {
                                <AddImages
                                    className={css.imagesField}
                                    images={images}
                                    thumbnailClassName={css.thumbnail}
                                    savedImageAltText={intl.formatMessage({
                                        id:
                                            'EditListingMediaForm.savedImageAltText',
                                    })}
                                    onRemoveImage={onRemoveImage}
                                    imageUploadRequested={
                                        this.state.imageUploadRequested
                                    }>
                                    <Field
                                        id="addImage"
                                        name="addImage"
                                        accept={ACCEPT_IMAGES}
                                        form={null}
                                        label={chooseImageText}
                                        type="file"
                                        disabled={imageUploadRequested}>
                                        {fieldprops => {
                                            const {
                                                accept,
                                                input,
                                                label,
                                                disabled: fieldDisabled,
                                            } = fieldprops;
                                            const { name, type } = input;
                                            const onChange = e => {
                                                const file = e.target.files[0];
                                                form.change(`addImage`, file);
                                                form.blur(`addImage`);
                                                onImageUploadHandler(file);
                                            };
                                            const inputProps = {
                                                accept,
                                                id: name,
                                                name,
                                                onChange,
                                                type,
                                            };
                                            return (
                                                <div
                                                    className={
                                                        css.addImageWrapper
                                                    }>
                                                    <div
                                                        className={
                                                            css.aspectRatioWrapper
                                                        }>
                                                        {fieldDisabled ? null : (
                                                            <input
                                                                {...inputProps}
                                                                className={
                                                                    css.addImageInput
                                                                }
                                                            />
                                                        )}
                                                        <label
                                                            htmlFor={name}
                                                            className={
                                                                css.addImage
                                                            }>
                                                            {label}
                                                        </label>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    </Field>

                                    <Field
                                        component={props => {
                                            const { input, meta } = props;
                                            return (
                                                <div
                                                    className={
                                                        css.imageRequiredWrapper
                                                    }>
                                                    <input {...input} />
                                                    <ValidationError
                                                        fieldMeta={meta}
                                                    />
                                                </div>
                                            );
                                        }}
                                        name="images"
                                        type="hidden"
                                        validate={composeValidators(
                                            nonEmptyArray(imageRequiredMessage)
                                        )}
                                    />
                                </AddImages>
                            }
                            {uploadImageFailed}

                            <p className={css.tip}>
                                <FormattedMessage id="EditListingMediaForm.addImagesTip" />
                            </p>
                            {publishListingFailed}
                            {showListingFailed}

                            {/* <br /><br />
              <FieldTextInput
                id="youtubeLink"
                name="youtubeLink"
                className={css.youtubeLink}
                type="textarea"
                label={youtubeLinkMessage}
                placeholder={youtubeLinkPlaceholderMessage}
                // validate={youtubeLinkValidator("require valid link")}
              /> */}

                            <Button
                                className={css.submitButton}
                                type="submit"
                                inProgress={submitInProgress}
                                disabled={submitDisabled}
                                ready={submitReady}>
                                {saveActionMsg}
                            </Button>
                        </Form>
                    );
                }}
            />
        );
    }
}

EditListingMediaFormComponent.defaultProps = { fetchErrors: null, images: [] };

EditListingMediaFormComponent.propTypes = {
    fetchErrors: shape({
        publishListingError: propTypes.error,
        showListingsError: propTypes.error,
        uploadImageError: propTypes.error,
        updateListingError: propTypes.error,
    }),
    images: array,
    intl: intlShape.isRequired,
    onImageUpload: func.isRequired,
    onUpdateImageOrder: func.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
    onRemoveImage: func.isRequired,
};

export default compose(injectIntl)(EditListingMediaFormComponent);
