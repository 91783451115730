import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT, LISTING_STATE_PENDING_APPROVAL } from '../../util/types';
import { ListingLink, Button, NamedLink } from '..';
import config from '../../config';
import { IconCheckmark } from '../../components';
import { 
    LISTING_PAGE_PENDING_APPROVAL_VARIANT,
    LISTING_PAGE_DRAFT_VARIANT,
    LISTING_PAGE_PARAM_TYPE_DRAFT,
    LISTING_PAGE_PARAM_TYPE_EDIT,
    createSlug 
} from '../../util/urlHelpers';
import routeConfiguration from '../../routeConfiguration';
import {
    createResourceLocatorString,
} from '../../util/routes';

import css from './EditListingPublishPanel.module.css';

const EditListingPublishPanel = props => {
    const {
        className,
        rootClassName,
        listing,
        disabled,
        ready,
        onSubmit,
        onSaveDraft,
        onChange,
        submitButtonText,
        panelUpdated,
        updateInProgress,
        errors,
        history
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const { description, title, publicData, geolocation, availabilityPlan, price } = currentListing.attributes;
    const { images } = currentListing;

    const isPublished =
        currentListing.id &&
        currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
        <FormattedMessage
            id="EditListingPublishPanel.title"
            values={{
                listingTitle: (
                    <ListingLink listing={listing}>
                        <FormattedMessage id="EditListingPublishPanel.listingTitle" />
                    </ListingLink>
                ),
            }}
        />
    ) : (
        <FormattedMessage id="EditListingPublishPanel.createListingTitle" />
    );

    console.log(images)

    let isDescriptionComplete = !!(description && title);
    let isMediaComplete = images && images.length > 0;
    let isCourtTypeComplete = !!(
        (publicData && publicData.typeIndoor) ||
        (publicData && publicData.typeOutdoor)
    );
    let isCourtSurfaceComplete = !!(publicData && publicData.courtSurface);
    let isFeaturesComplete = !!(publicData && publicData.amenities);
    let isOthersComplete = !!(
        publicData &&
        publicData.howManyPlayersAllowed &&
        publicData.parkingSpots &&
        publicData.rules
    );
    let isPolicyComplete = !!(publicData && typeof publicData.rules !== 'undefined');
    let isLocationComplete = !!(
        geolocation &&
        publicData &&
        publicData.location &&
        publicData.location.address
    );
    let isPricingComplete = !!price;
    let isAvailabilityComplete = !!availabilityPlan;

    const submitReady = isDescriptionComplete && isMediaComplete && isCourtTypeComplete && isCourtSurfaceComplete && isFeaturesComplete && isOthersComplete && isPolicyComplete && isLocationComplete && isPricingComplete && isAvailabilityComplete;

    const createListingURL = (routes, listing) => {
        const id = listing.id.uuid;
        const slug = createSlug(listing.attributes.title);
        const isPendingApproval =
            listing.attributes.state === LISTING_STATE_PENDING_APPROVAL;
        const isDraft = listing.attributes.state === LISTING_STATE_DRAFT;
        const variant = isDraft
            ? LISTING_PAGE_DRAFT_VARIANT
            : isPendingApproval
            ? LISTING_PAGE_PENDING_APPROVAL_VARIANT
            : null;
    
        const linkProps =
            isPendingApproval || isDraft
                ? {
                      name: 'ListingPageVariant',
                      params: {
                          id,
                          slug,
                          variant,
                      },
                  }
                : {
                      name: 'ListingPage',
                      params: { id, slug },
                  };
    
        return createResourceLocatorString(
            linkProps.name,
            routes,
            linkProps.params,
            {}
        );
    };

    return (
        <div className={classes}>
            <h1 className={css.title}>{panelTitle}</h1>

            <div className={css.sumary}>
                <div className={css.sumaryItem}>
                    <div className={css.sumaryItemStatus}>
                        {isDescriptionComplete ? <IconCheckmark size="small" className={css.completed} /> : <IconCheckmark size="small" className={css.incompleted} />}
                    </div>
                    <div className={css.sumaryItemTitle}>Description</div>
                </div>
                <div className={css.sumaryItem}>
                    <div className={css.sumaryItemStatus}>
                        {isMediaComplete ? <IconCheckmark size="small" className={css.completed} /> : <IconCheckmark size="small" className={css.incompleted} />}
                    </div>
                    <div className={css.sumaryItemTitle}>Media</div>
                </div>
                <div className={css.sumaryItem}>

                    <div className={css.sumaryItemStatus}>
                        {isCourtTypeComplete ? <IconCheckmark size="small" className={css.completed} /> : <IconCheckmark size="small" className={css.incompleted} />}
                    </div>
                    <div className={css.sumaryItemTitle}>Court Type</div>
                </div>
                <div className={css.sumaryItem}>
                    <div className={css.sumaryItemStatus}>
                        {isCourtSurfaceComplete ? <IconCheckmark size="small" className={css.completed} /> : <IconCheckmark size="small" className={css.incompleted} />}
                    </div>
                    <div className={css.sumaryItemTitle}>Court Surface</div>
                </div>
                <div className={css.sumaryItem}>
                    <div className={css.sumaryItemStatus}>
                        {isFeaturesComplete ? <IconCheckmark size="small" className={css.completed} /> : <IconCheckmark size="small" className={css.incompleted} />}
                    </div>
                    <div className={css.sumaryItemTitle}>Features</div>
                </div>
                <div className={css.sumaryItem}>
                    <div className={css.sumaryItemStatus}>
                        {isOthersComplete ? <IconCheckmark size="small" className={css.completed} /> : <IconCheckmark size="small" className={css.incompleted} />}
                    </div>
                    <div className={css.sumaryItemTitle}>Others</div>
                </div>
                <div className={css.sumaryItem}>
                    <div className={css.sumaryItemStatus}>
                        {isPolicyComplete ? <IconCheckmark size="small" className={css.completed} /> : <IconCheckmark size="small" className={css.incompleted} />}
                    </div>
                    <div className={css.sumaryItemTitle}>Policy</div>
                </div>
                <div className={css.sumaryItem}>
                    <div className={css.sumaryItemStatus}>
                        {isLocationComplete ? <IconCheckmark size="small" className={css.completed} /> : <IconCheckmark size="small" className={css.incompleted} />}
                    </div>
                    <div className={css.sumaryItemTitle}>Location</div>
                </div>
                <div className={css.sumaryItem}>
                    <div className={css.sumaryItemStatus}>
                        {isPricingComplete ? <IconCheckmark size="small" className={css.completed} /> : <IconCheckmark size="small" className={css.incompleted} />}
                    </div>
                    <div className={css.sumaryItemTitle}>Pricing</div>
                </div>
                <div className={css.sumaryItem}>
                    <div className={css.sumaryItemStatus}>
                        {isAvailabilityComplete ? <IconCheckmark size="small" className={css.completed} /> : <IconCheckmark size="small" className={css.incompleted} />}
                    </div>
                    <div className={css.sumaryItemTitle}>Availability</div>
                </div>
            </div>

            <div className={css.actions}>
                {!isPublished && (
                    <>
                        <Button
                            className={css.submitButtonText}
                            disabled={!submitReady}
                            onClick={() => {
                                onSubmit();
                            }}
                        >
                            {submitButtonText}
                        </Button>

                        <NamedLink
                            className={css.editListingLink}
                            name="ProfileSettingsPage"
                        >
                            Save Draft
                        </NamedLink>
                    </>
                )}
                {isPublished && (
                    <Button
                        className={css.editListingLink}
                        onClick={() => {
                            history.push(
                                createListingURL(routeConfiguration(), listing)
                            );
                        }}
                    >
                        <FormattedMessage id="EditListingPublishPanel.editListingLink" />
                    </Button>
                )}
            </div>
        </div>
    );
};

EditListingPublishPanel.defaultProps = {
    className: null,
    rootClassName: null,
    errors: null,
    listing: null,
};

EditListingPublishPanel.propTypes = {
    className: string,
    rootClassName: string,

    // We cannot use propTypes.listing since the listing might be a draft.
    listing: object,

    disabled: bool.isRequired,
    ready: bool.isRequired,
    onSubmit: func.isRequired,
    onChange: func.isRequired,
    submitButtonText: string.isRequired,
    panelUpdated: bool.isRequired,
    updateInProgress: bool.isRequired,
    errors: object.isRequired,
};

export default EditListingPublishPanel;
