import React, { useState, useEffect } from 'react';
import Geocoder from './../../components/LocationAutocompleteInput/GeocoderMapbox';
import { Redirect } from 'react-router-dom';

const WPSearchPage = props => {
    async function getGeocoder() {
        // Create the Geocoder as late as possible only when it is needed.
        const geocoder = new Geocoder();
        const { predictions } = await geocoder.getPlacePredictions(
            props.params.location
        );
        const { bounds } = await geocoder.getPlaceDetails(predictions[0]);
        const url = `/s?address=${encodeURI(
            predictions[0].place_name
        )}&bounds=${bounds.ne.lat}%2C${bounds.ne.lng}%2C${bounds.sw.lat}%2C${
            bounds.sw.lng
        }`;
        if (typeof window !== 'undefined') {
            window.location.href = url;
        }
    }

    useEffect(() => {
        getGeocoder();
    }, []);

    return <div></div>;
};

export default WPSearchPage;
