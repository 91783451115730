import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
    Form,
    Avatar,
    Button,
    ImageFromFile,
    IconSpinner,
    FieldTextInput,
    FieldCheckboxGroup,
    FieldSelect,
    FieldCheckbox,
    FieldBoolean,
} from '../../components';
import ImageCropModal from './ImageCropModal/ImageCropModal';
import css from './ProfileSettingsForm.module.css';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset

class ProfileSettingsFormComponent extends Component {
    constructor(props) {
        super(props);

        this.uploadDelayTimeoutId = null;
        this.state = {
            uploadDelay: false,
            playerType: [],
            crop: null,
            imgSrc: '',
            tempId: '',
            imageModalState: false,
        };
        this.submittedValues = {};
        this.onSelectFile = this.onSelectFile.bind(this);
        this.setCrop = this.setCrop.bind(this);
    }

    componentDidMount() {
        const user = ensureCurrentUser(this.props.currentUser);
        const { publicData } = user.attributes.profile;
        this.setState({
            playerType: publicData.playerType ? publicData.playerType : [],
        });
    }

    componentDidUpdate(prevProps) {
        // Upload delay is additional time window where Avatar is added to the DOM,
        // but not yet visible (time to load image URL from srcset)
        if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
            this.setState({ uploadDelay: true });
            this.uploadDelayTimeoutId = window.setTimeout(() => {
                this.setState({ uploadDelay: false });
            }, UPLOAD_CHANGE_DELAY);
        }
    }

    componentWillUnmount() {
        window.clearTimeout(this.uploadDelayTimeoutId);
    }

    onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            // Makes crop preview update between images.
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({
                    imgSrc: reader.result.toString() || '',
                })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    setCrop(value) {
        this.setState({
            crop: value,
        });
    }
    render() {
        return (
            <FinalForm
                {...this.props}
                mutators={{ ...arrayMutators }}
                render={fieldRenderProps => {
                    const {
                        className,
                        currentUser,
                        handleSubmit,
                        intl,
                        invalid,
                        onImageUpload,
                        pristine,
                        profileImage,
                        rootClassName,
                        updateInProgress,
                        updateProfileError,
                        uploadImageError,
                        uploadInProgress,
                        form,
                        values,
                        onManageDisableScrolling,
                    } = fieldRenderProps;

                    const user = ensureCurrentUser(currentUser);

                    // First name
                    const firstNameLabel = intl.formatMessage({
                        id: 'ProfileSettingsForm.firstNameLabel',
                    });
                    const firstNamePlaceholder = intl.formatMessage({
                        id: 'ProfileSettingsForm.firstNamePlaceholder',
                    });
                    const firstNameRequiredMessage = intl.formatMessage({
                        id: 'ProfileSettingsForm.firstNameRequired',
                    });
                    const firstNameRequired = validators.required(
                        firstNameRequiredMessage
                    );

                    // Last name
                    const lastNameLabel = intl.formatMessage({
                        id: 'ProfileSettingsForm.lastNameLabel',
                    });
                    const lastNamePlaceholder = intl.formatMessage({
                        id: 'ProfileSettingsForm.lastNamePlaceholder',
                    });
                    const lastNameRequiredMessage = intl.formatMessage({
                        id: 'ProfileSettingsForm.lastNameRequired',
                    });
                    const lastNameRequired = validators.required(
                        lastNameRequiredMessage
                    );

                    // Bio
                    const bioLabel = intl.formatMessage({
                        id: 'ProfileSettingsForm.bioLabel',
                    });
                    const bioPlaceholder = intl.formatMessage({
                        id: 'ProfileSettingsForm.bioPlaceholder',
                    });

                    const bioRequiredMessage = intl.formatMessage({
                        id: 'ProfileSettingsForm.bioRequired',
                    });

                    const insuredPlaceholder = intl.formatMessage({
                        id: 'ProfileSettingsForm.insuredPlaceholder',
                    });

                    // USTA
                    const ustaLabel = intl.formatMessage({
                        id: 'ProfileSettingsForm.ustaLabel',
                    });

                    // PTR
                    const ptrLabel = intl.formatMessage({
                        id: 'ProfileSettingsForm.ptrLabel',
                    });

                    // USPTA
                    const usptaLabel = intl.formatMessage({
                        id: 'ProfileSettingsForm.usptaLabel',
                    });

                    const uploadingOverlay =
                        uploadInProgress || this.state.uploadDelay ? (
                            <div className={css.uploadingImageOverlay}>
                                <IconSpinner />
                            </div>
                        ) : null;

                    const hasUploadError =
                        !!uploadImageError && !uploadInProgress;
                    const errorClasses = classNames({
                        [css.avatarUploadError]: hasUploadError,
                    });
                    const transientUserProfileImage =
                        profileImage.uploadedImage || user.profileImage;
                    const transientUser = {
                        ...user,
                        profileImage: transientUserProfileImage,
                    };

                    // Ensure that file exists if imageFromFile is used
                    const fileExists = !!profileImage.file;
                    const fileUploadInProgress = uploadInProgress && fileExists;
                    const delayAfterUpload =
                        profileImage.imageId && this.state.uploadDelay;
                    const imageFromFile =
                        fileExists &&
                        (fileUploadInProgress || delayAfterUpload) ? (
                            <ImageFromFile
                                id={profileImage.id}
                                className={errorClasses}
                                rootClassName={css.uploadingImage}
                                aspectRatioClassName={css.squareAspectRatio}
                                file={profileImage.file}>
                                {uploadingOverlay}
                            </ImageFromFile>
                        ) : null;

                    // Avatar is rendered in hidden during the upload delay
                    // Upload delay smoothes image change process:
                    // responsive img has time to load srcset stuff before it is shown to user.
                    const avatarClasses = classNames(errorClasses, css.avatar, {
                        [css.avatarInvisible]: this.state.uploadDelay,
                    });
                    const avatarComponent =
                        !fileUploadInProgress && profileImage.imageId ? (
                            <Avatar
                                className={avatarClasses}
                                renderSizes="(max-width: 767px) 96px, 240px"
                                user={transientUser}
                                disableProfileLink
                            />
                        ) : null;

                    const chooseAvatarLabel =
                        profileImage.imageId || fileUploadInProgress ? (
                            <div className={css.avatarContainer}>
                                {imageFromFile}
                                {avatarComponent}
                                <div className={css.changeAvatar}>
                                    <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                                </div>
                            </div>
                        ) : (
                            <div className={css.avatarPlaceholder}>
                                <div className={css.avatarPlaceholderText}>
                                    <FormattedMessage id="ProfileSettingsForm.addYourProfilePicture" />
                                </div>
                                <div
                                    className={css.avatarPlaceholderTextMobile}>
                                    <FormattedMessage id="ProfileSettingsForm.addYourProfilePictureMobile" />
                                </div>
                            </div>
                        );

                    const submitError = updateProfileError ? (
                        <div className={css.error}>
                            <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
                        </div>
                    ) : null;

                    const classes = classNames(
                        rootClassName || css.root,
                        className
                    );
                    const submitInProgress = updateInProgress;
                    const submittedOnce =
                        Object.keys(this.submittedValues).length > 0;
                    const pristineSinceLastSubmit =
                        submittedOnce && isEqual(values, this.submittedValues);
                    const submitDisabled =
                        invalid ||
                        pristine ||
                        pristineSinceLastSubmit ||
                        uploadInProgress ||
                        submitInProgress;

                    const playerTypeOptions = findOptionsForSelectFilter(
                        'playerType',
                        this.props.filterConfig
                    );
                    const courtTypeOptions = findOptionsForSelectFilter(
                        'courtType',
                        this.props.filterConfig
                    );
                    const ustaOptions = findOptionsForSelectFilter(
                        'usta',
                        this.props.filterConfig
                    );
                    const ptrOptions = findOptionsForSelectFilter(
                        'ptr',
                        this.props.filterConfig
                    );
                    const usptaOptions = findOptionsForSelectFilter(
                        'uspta',
                        this.props.filterConfig
                    );

                    return (
                        <Form
                            className={classes}
                            onSubmit={e => {
                                this.submittedValues = values;
                                handleSubmit(e);
                            }}>
                            <div className={css.sectionContainer}>
                                {/* <ImageCropModal
                                    crop={this.state.crop}
                                    setCrop={this.setCrop}
                                    imgSrc={this.state.imgSrc}
                                    tempId={this.state.tempId}
                                    onImageUpload={onImageUpload}
                                    onManageDisableScrolling={
                                        onManageDisableScrolling
                                    }
                                    imageModalState={this.state.imageModalState}
                                    closeImageModal={() => {
                                        this.setState({
                                            imageModalState: false,
                                        });
                                    }}
                                />
                                <Field
                                    accept={ACCEPT_IMAGES}
                                    id="profileImage"
                                    name="profileImage"
                                    label={chooseAvatarLabel}
                                    type="file"
                                    form={null}
                                    uploadImageError={uploadImageError}
                                    disabled={uploadInProgress}
                                    validate={validators.composeValidators(validators.required("Need image"))}
                                >
                                    {fieldProps => {
                                        const {
                                            accept,
                                            id,
                                            input,
                                            label,
                                            disabled,
                                            uploadImageError,
                                        } = fieldProps;
                                        const { name, type } = input;
                                        const onChange = e => {
                                            const file = e.target.files[0];
                                            form.change(`profileImage`, file);
                                            form.blur(`profileImage`);
                                            if (file != null) {
                                                this.onSelectFile(e);
                                                this.setState({
                                                    tempId: `${file.name}`,
                                                    imageModalState: true,
                                                });
                                                // onImageUpload({ id: tempId, file });
                                            }
                                        };

                                        let error = null;

                                        if (
                                            isUploadImageOverLimitError(
                                                uploadImageError
                                            )
                                        ) {
                                            error = (
                                                <div className={css.error}>
                                                    <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                                                </div>
                                            );
                                        } else if (uploadImageError) {
                                            error = (
                                                <div className={css.error}>
                                                    <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                                                </div>
                                            );
                                        }

                                        return (
                                            <div
                                                className={
                                                    css.uploadAvatarWrapper
                                                }>
                                                <label
                                                    className={css.label}
                                                    htmlFor={id}>
                                                    {label}
                                                </label>
                                                <input
                                                    accept={accept}
                                                    id={id}
                                                    name={name}
                                                    className={
                                                        css.uploadAvatarInput
                                                    }
                                                    disabled={disabled}
                                                    onChange={onChange}
                                                    type={type}
                                                />
                                                {error}
                                            </div>
                                        );
                                    }}
                                </Field> */}
                                <Field
                                  accept={ACCEPT_IMAGES}
                                  id="profileImage"
                                  name="profileImage"
                                  label={chooseAvatarLabel}
                                  type="file"
                                  form={null}
                                  uploadImageError={uploadImageError}
                                  disabled={uploadInProgress}
                                >
                                  {fieldProps => {
                                    const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                                    const { name, type } = input;
                                    const onChange = e => {
                                      const file = e.target.files[0];
                                      form.change(`profileImage`, file);
                                      form.blur(`profileImage`);
                                      if (file != null) {
                                        const tempId = `${file.name}_${Date.now()}`;
                                        onImageUpload({ id: tempId, file });
                                      }
                                    };

                                    let error = null;

                                    if (isUploadImageOverLimitError(uploadImageError)) {
                                      error = (
                                        <div className={css.error}>
                                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                                        </div>
                                      );
                                    } else if (uploadImageError) {
                                      error = (
                                        <div className={css.error}>
                                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                                        </div>
                                      );
                                    }

                                    return (
                                      <div className={css.uploadAvatarWrapper}>
                                        <label className={css.label} htmlFor={id}>
                                          {label}
                                        </label>
                                        <input
                                          accept={accept}
                                          id={id}
                                          name={name}
                                          className={css.uploadAvatarInput}
                                          disabled={disabled}
                                          onChange={onChange}
                                          type={type}
                                        />
                                        {error}
                                      </div>
                                    );
                                  }}
                                </Field>
                                <div className={css.tip}>
                                    <FormattedMessage id="ProfileSettingsForm.tip" />
                                </div>
                                <div className={css.fileInfo}>
                                    <FormattedMessage id="ProfileSettingsForm.fileInfo" />
                                </div>
                            </div>
                            <div className={css.sectionContainer}>
                                <h3 className={css.sectionTitle}>
                                    <FormattedMessage id="ProfileSettingsForm.yourName" />
                                </h3>
                                <div className={css.nameContainer}>
                                    <FieldTextInput
                                        className={css.firstName}
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        label={firstNameLabel}
                                        placeholder={firstNamePlaceholder}
                                        validate={firstNameRequired}
                                    />
                                    <FieldTextInput
                                        className={css.lastName}
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        label={lastNameLabel}
                                        placeholder={lastNamePlaceholder}
                                        validate={lastNameRequired}
                                    />
                                </div>
                                <small className={css.bioInfo}>
                                    <FormattedMessage id="ProfileSettingsForm.nameInfo" />
                                </small>
                            </div>
                            <div className={classNames(css.sectionContainer)}>
                                <h3 className={css.sectionTitle}>
                                    <FormattedMessage id="ProfileSettingsForm.bioHeading" />
                                </h3>
                                <FieldTextInput
                                    type="textarea"
                                    id="bio"
                                    name="bio"
                                    // label={bioLabel}
                                    placeholder={bioPlaceholder}
                                    validate={validators.composeValidators(validators.required(bioRequiredMessage))}
                                />
                            </div>

                            <div className={classNames(css.sectionContainer)}>
                                <h3 className={css.sectionTitle}>
                                    <FormattedMessage id="ProfileSettingsForm.sportTypeHeading" />
                                </h3>
                                <FieldCheckboxGroup
                                    className={css.features}
                                    id="sportType"
                                    name="sportType"
                                    options={courtTypeOptions}
                                />
                            </div>

                            <div className={classNames(css.sectionContainer)}>
                                <h3 className={css.sectionTitle}>
                                    <FormattedMessage id="ProfileSettingsForm.playerTypeHeading" />
                                </h3>
                                <ul className={css.list}>
                                    {playerTypeOptions.map((option, index) => {
                                        const fieldId = `playerType.${option.key}`;
                                        return (
                                            <li
                                                key={fieldId}
                                                className={css.item}>
                                                <FieldCheckbox
                                                    id={fieldId}
                                                    name="playerType"
                                                    label={option.label}
                                                    value={option.key}
                                                    handleInputOnChange={e => {
                                                        var selectedPlayerType = this
                                                            .state.playerType;
                                                        if (!e.checked) {
                                                            // if checkbox is checked
                                                            if (
                                                                selectedPlayerType.includes(
                                                                    e.value
                                                                )
                                                            ) {
                                                                selectedPlayerType = selectedPlayerType.filter(
                                                                    type =>
                                                                        type !==
                                                                        e.value
                                                                );
                                                            } else {
                                                                selectedPlayerType.push(
                                                                    e.value
                                                                );
                                                            }
                                                        } else {
                                                            selectedPlayerType = selectedPlayerType.filter(
                                                                type =>
                                                                    type !==
                                                                    e.value
                                                            );
                                                        }
                                                        this.setState({
                                                            playerType: selectedPlayerType,
                                                        });
                                                    }}
                                                />
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div className={classNames(css.sectionContainer)}>
                                {this.state.playerType.includes('player') ? (
                                    <>
                                        <FieldSelect
                                            id="usta"
                                            name="usta"
                                            label={ustaLabel}
                                            className={
                                                css.instantBookingOptions
                                            }>
                                            <option value=""></option>
                                            {ustaOptions &&
                                                ustaOptions.map(usta => (
                                                    <option
                                                        key={usta.key}
                                                        value={usta.key}>
                                                        {usta.label}
                                                    </option>
                                                ))}
                                        </FieldSelect>
                                        <br />
                                    </>
                                ) : null}

                                {this.state.playerType.includes('coach') ? (
                                    <>
                                        <FieldSelect
                                            id="ptr"
                                            name="ptr"
                                            label={ptrLabel}
                                            className={
                                                css.instantBookingOptions
                                            }>
                                            <option value=""></option>
                                            {ptrOptions &&
                                                ptrOptions.map(ptr => (
                                                    <option
                                                        key={ptr.key}
                                                        value={ptr.key}>
                                                        {ptr.label}
                                                    </option>
                                                ))}
                                        </FieldSelect>
                                        <br />

                                        <FieldSelect
                                            id="uspta"
                                            name="uspta"
                                            label={usptaLabel}
                                            className={
                                                css.instantBookingOptions
                                            }>
                                            <option value=""></option>
                                            {usptaOptions &&
                                                usptaOptions.map(uspta => (
                                                    <option
                                                        key={uspta.key}
                                                        value={uspta.key}>
                                                        {uspta.label}
                                                    </option>
                                                ))}
                                        </FieldSelect>
                                        <br />
                                        <div
                                            className={classNames(
                                                css.sectionContainer
                                            )}>
                                            <h3 className={css.sectionTitle2}>
                                                <FormattedMessage id="ProfileSettingsForm.insuredHeading" />
                                            </h3>
                                            <FieldBoolean
                                                id="insured"
                                                name="insured"
                                                // label={bioLabel}
                                                placeholder={insuredPlaceholder}
                                            />
                                        </div>
                                    </>
                                ) : null}
                            </div>

                            {submitError}

                            {profileImage?.imageId === null && (
                                <div className={css.error}>
                                    <FormattedMessage id="ProfileSettingsForm.photoRequired" />
                                </div>
                            )}
                            <Button
                                className={css.submitButton}
                                type="submit"
                                inProgress={submitInProgress}
                                disabled={submitDisabled}
                                ready={pristineSinceLastSubmit}>
                                <FormattedMessage id="ProfileSettingsForm.saveChanges" />
                            </Button>
                        </Form>
                    );
                }}
            />
        );
    }
}

ProfileSettingsFormComponent.defaultProps = {
    rootClassName: null,
    className: null,
    uploadImageError: null,
    updateProfileError: null,
    updateProfileReady: false,
    filterConfig: config.custom.filters,
};

ProfileSettingsFormComponent.propTypes = {
    rootClassName: string,
    className: string,

    uploadImageError: propTypes.error,
    uploadInProgress: bool.isRequired,
    updateInProgress: bool.isRequired,
    updateProfileError: propTypes.error,
    updateProfileReady: bool,

    // from injectIntl
    intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
