import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
    Form,
    FieldTextInput,
    Button,
    NamedLink,
} from '../../components';

import css from './SignupForm.module.css';

const SignupFormComponent = props => {

    return (
        <FinalForm
            {...props}
            render={fieldRenderProps => {
                const {
                    rootClassName,
                    className,
                    formId,
                    handleSubmit,
                    inProgress,
                    invalid,
                    intl,
                    socialLoginButtonsMaybe,
                } = fieldRenderProps;


                // email
                const emailLabel = intl.formatMessage({
                    id: 'SignupForm.emailLabel',
                });
                const emailPlaceholder = intl.formatMessage({
                    id: 'SignupForm.emailPlaceholder',
                });
                const emailRequiredMessage = intl.formatMessage({
                    id: 'SignupForm.emailRequired',
                });
                const emailRequired = validators.required(emailRequiredMessage);
                const emailInvalidMessage = intl.formatMessage({
                    id: 'SignupForm.emailInvalid',
                });
                const emailValid = validators.emailFormatValid(emailInvalidMessage);

                // password
                const passwordLabel = intl.formatMessage({
                    id: 'SignupForm.passwordLabel',
                });
                const passwordPlaceholder = intl.formatMessage({
                    id: 'SignupForm.passwordPlaceholder',
                });
                const passwordRequiredMessage = intl.formatMessage({
                    id: 'SignupForm.passwordRequired',
                });
                const passwordMinLengthMessage = intl.formatMessage(
                    {
                        id: 'SignupForm.passwordTooShort',
                    },
                    {
                        minLength: validators.PASSWORD_MIN_LENGTH,
                    }
                );
                const passwordMaxLengthMessage = intl.formatMessage(
                    {
                        id: 'SignupForm.passwordTooLong',
                    },
                    {
                        maxLength: validators.PASSWORD_MAX_LENGTH,
                    }
                );

                const passwordMaxLength = validators.maxLength(
                    passwordMaxLengthMessage,
                    validators.PASSWORD_MAX_LENGTH
                );
                const passwordRequired = validators.requiredStringNoTrim(
                    passwordRequiredMessage
                );
                const complexPassword = validators.complexPassword(
                    'Requires 8 or more characters, combination of upper and lower case letters, a number and a symbol.'
                );
                const passwordValidators = validators.composeValidators(
                    passwordRequired,
                    passwordMaxLength,
                    complexPassword
                );

                // firstName
                const firstNameLabel = intl.formatMessage({
                    id: 'SignupForm.firstNameLabel',
                });
                const firstNamePlaceholder = intl.formatMessage({
                    id: 'SignupForm.firstNamePlaceholder',
                });
                const firstNameRequiredMessage = intl.formatMessage({
                    id: 'SignupForm.firstNameRequired',
                });
                const firstNameRequired = validators.required(
                    firstNameRequiredMessage
                );

                // lastName
                const lastNameLabel = intl.formatMessage({
                    id: 'SignupForm.lastNameLabel',
                });
                const lastNamePlaceholder = intl.formatMessage({
                    id: 'SignupForm.lastNamePlaceholder',
                });
                const lastNameRequiredMessage = intl.formatMessage({
                    id: 'SignupForm.lastNameRequired',
                });
                const lastNameRequired = validators.required(
                    lastNameRequiredMessage
                );

                const classes = classNames(rootClassName || css.root, className);
                const submitInProgress = inProgress;
                const submitDisabled = invalid || submitInProgress;

                return (
                    <Form className={classes} onSubmit={handleSubmit}>
                        <div className={css.steps}>
                            <div className={css.step}>
                                <FormattedMessage id="SignupForm.step1" />
                            </div>
                            <div className={css.step}>
                                <FormattedMessage id="SignupForm.step2" />
                            </div>
                        </div>
                        <h2 className={css.signupTitle}>
                            <FormattedMessage id="SignupForm.signupTitle" />
                        </h2>
                        <div>
                            <div className={css.double}>
                                <FieldTextInput
                                    className={classNames(css.field, css.firstNameRoot)}
                                    inputRootClass={css.input}
                                    type="text"
                                    id={
                                        formId ? `${formId}.fname` : 'fname'
                                    }
                                    name="fname"
                                    autoComplete="given-name"
                                    label={firstNameLabel}
                                    placeholder={firstNamePlaceholder}
                                    validate={firstNameRequired}
                                    icon={<div className={css.fieldIcon}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8.00002 7.99998C9.84097 7.99998 11.3334 6.5076 11.3334 4.66665C11.3334 2.8257 9.84097 1.33331 8.00002 1.33331C6.15907 1.33331 4.66669 2.8257 4.66669 4.66665C4.66669 6.5076 6.15907 7.99998 8.00002 7.99998Z" stroke="#121212" strokeOpacity="0.6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
                                            <path d="M13.7266 14.6667C13.7266 12.0867 11.16 10 7.99998 10C4.83998 10 2.27332 12.0867 2.27332 14.6667" stroke="#121212" strokeOpacity="0.6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
                                        </svg></div>}
                                />
                                <FieldTextInput
                                    className={classNames(css.field, css.lastNameRoot)}
                                    inputRootClass={css.input}
                                    type="text"
                                    id={
                                        formId ? `${formId}.lname` : 'lname'
                                    }
                                    name="lname"
                                    autoComplete="family-name"
                                    label={lastNameLabel}
                                    placeholder={lastNamePlaceholder}
                                    validate={lastNameRequired}
                                    icon={<div className={css.fieldIcon}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8.00002 7.99998C9.84097 7.99998 11.3334 6.5076 11.3334 4.66665C11.3334 2.8257 9.84097 1.33331 8.00002 1.33331C6.15907 1.33331 4.66669 2.8257 4.66669 4.66665C4.66669 6.5076 6.15907 7.99998 8.00002 7.99998Z" stroke="#121212" strokeOpacity="0.6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
                                            <path d="M13.7266 14.6667C13.7266 12.0867 11.16 10 7.99998 10C4.83998 10 2.27332 12.0867 2.27332 14.6667" stroke="#121212" strokeOpacity="0.6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
                                        </svg></div>}
                                />
                            </div>
                            <FieldTextInput
                                type="email"
                                className={css.field}
                                inputRootClass={css.input}
                                id={formId ? `${formId}.email` : 'email'}
                                name="email"
                                autoComplete="email"
                                label={emailLabel}
                                placeholder={emailPlaceholder}
                                validate={validators.composeValidators(
                                    emailRequired,
                                    emailValid
                                )}
                                icon={<div className={css.fieldIcon}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M11.3333 13.6666H4.66665C2.66665 13.6666 1.33331 12.6666 1.33331 10.3333V5.66665C1.33331 3.33331 2.66665 2.33331 4.66665 2.33331H11.3333C13.3333 2.33331 14.6666 3.33331 14.6666 5.66665V10.3333C14.6666 12.6666 13.3333 13.6666 11.3333 13.6666Z" stroke="#121212" strokeOpacity="0.6" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="none" />
                                        <path d="M11.3334 6L9.24668 7.66667C8.56002 8.21333 7.43335 8.21333 6.74668 7.66667L4.66669 6" stroke="#121212" strokeOpacity="0.6" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="none" />
                                    </svg>
                                </div>}
                            />
                            <FieldTextInput
                                className={css.field}
                                inputRootClass={css.input}
                                type="password"
                                id={
                                    formId
                                        ? `${formId}.password`
                                        : 'password'
                                }
                                name="password"
                                autoComplete="new-password"
                                label={passwordLabel}
                                placeholder={passwordPlaceholder}
                                validate={passwordValidators}
                                icon={<div className={css.fieldIcon}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M4 6.66665V5.33331C4 3.12665 4.66667 1.33331 8 1.33331C11.3333 1.33331 12 3.12665 12 5.33331V6.66665" stroke="#121212" strokeOpacity="0.6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
                                        <path d="M7.99998 12.3333C8.92045 12.3333 9.66665 11.5871 9.66665 10.6667C9.66665 9.74619 8.92045 9 7.99998 9C7.07951 9 6.33331 9.74619 6.33331 10.6667C6.33331 11.5871 7.07951 12.3333 7.99998 12.3333Z" stroke="#121212" strokeOpacity="0.6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
                                        <path d="M11.3333 14.6667H4.66665C1.99998 14.6667 1.33331 14 1.33331 11.3334V10C1.33331 7.33335 1.99998 6.66669 4.66665 6.66669H11.3333C14 6.66669 14.6666 7.33335 14.6666 10V11.3334C14.6666 14 14 14.6667 11.3333 14.6667Z" stroke="#121212" strokeOpacity="0.6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
                                    </svg>
                                </div>}
                            />
                        </div>

                        <div className={css.bottomWrapper}>

                            <Button
                                className={css.button}
                                type="submit"
                                inProgress={submitInProgress}
                                disabled={submitDisabled}>
                                <FormattedMessage id="SignupForm.signUp" />
                            </Button>


                        </div>
                        {socialLoginButtonsMaybe}
                        <p className={css.loginText}>
                            <FormattedMessage
                                id="SignupForm.haveAccount"
                                values={{
                                    loginLink: (
                                        <NamedLink
                                            name="LoginPage"
                                            className={css.loginLink}>
                                            <FormattedMessage id="SignupForm.logInLinkText" />
                                        </NamedLink>
                                    ),
                                }}
                            />
                        </p>
                    </Form>
                );
            }}
        />
    )
};

SignupFormComponent.defaultProps = {
    inProgress: false,
};

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
    inProgress: bool,
    onOpenTermsOfService: func.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
