import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const ALL_LISTINGS_SUCCESS = 'app/ProfilePage/ALL_LISTINGS_SUCCESS';

// ================ Reducer ================ //

const initialState = {
    userId: null,
    userListingRefs: [],
    userShowError: null,
    queryListingsError: null,
    reviews: [],
    queryReviewsError: null,
};

const resultIds = data => data.data.map(l => l.id);

export default function profilePageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case SET_INITIAL_STATE:
            return { ...initialState };
        case SHOW_USER_REQUEST:
            return { ...state, userShowError: null, userId: payload.userId };
        case SHOW_USER_SUCCESS:
            return state;
        case SHOW_USER_ERROR:
            return { ...state, userShowError: payload };

        case QUERY_LISTINGS_REQUEST:
            return {
                ...state,

                // Empty listings only when user id changes
                userListingRefs:
                    payload.userId === state.userId
                        ? state.userListingRefs
                        : [],

                queryListingsError: null,
            };
        case QUERY_LISTINGS_SUCCESS:
            return { ...state, userListingRefs: payload.listingRefs };
        case QUERY_LISTINGS_ERROR:
            return {
                ...state,
                userListingRefs: [],
                queryListingsError: payload,
            };
        case QUERY_REVIEWS_REQUEST:
            return { ...state, queryReviewsError: null };
        case QUERY_REVIEWS_SUCCESS:
            return { ...state, reviews: payload };
        case QUERY_REVIEWS_ERROR:
            return { ...state, reviews: [], queryReviewsError: payload };
        case ALL_LISTINGS_SUCCESS: {
            const allListingIds = resultIds(payload.data);
            return {
                ...state,
                allListingIds,
            };
        }

        default:
            return state;
    }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
    type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
    type: SHOW_USER_REQUEST,
    payload: { userId },
});

export const showUserSuccess = () => ({
    type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
    type: SHOW_USER_ERROR,
    error: true,
    payload: e,
});

export const queryListingsRequest = userId => ({
    type: QUERY_LISTINGS_REQUEST,
    payload: { userId },
});

export const queryListingsSuccess = listingRefs => ({
    type: QUERY_LISTINGS_SUCCESS,
    payload: { listingRefs },
});

export const queryListingsError = e => ({
    type: QUERY_LISTINGS_ERROR,
    error: true,
    payload: e,
});

export const queryReviewsRequest = () => ({
    type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
    type: QUERY_REVIEWS_SUCCESS,
    payload: reviews,
});

export const queryReviewsError = e => ({
    type: QUERY_REVIEWS_ERROR,
    error: true,
    payload: e,
});

export const allListingsSuccess = response => ({
    type: ALL_LISTINGS_SUCCESS,
    payload: { data: response.data },
});

// ================ Thunks ================ //

export const queryUserListings = userId => (dispatch, getState, sdk) => {
    dispatch(queryListingsRequest(userId));
    return sdk.listings
        .query({
            author_id: userId,
            include: ['author', 'images'],
            'fields.image': [
                'variants.landscape-crop',
                'variants.landscape-crop2x',
            ],
        })
        .then(response => {
            // Pick only the id and type properties from the response listings
            const listingRefs = response.data.data.map(({ id, type }) => ({
                id,
                type,
            }));
            dispatch(addMarketplaceEntities(response));
            dispatch(queryListingsSuccess(listingRefs));
            return response;
        })
        .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
    sdk.reviews
        .query({
            subject_id: userId,
            state: 'public',
            include: ['author', 'author.profileImage'],
            'fields.image': [
                'variants.square-small',
                'variants.square-small2x',
            ],
        })
        .then(response => {
            const reviews = denormalisedResponseEntities(response);
            dispatch(queryReviewsSuccess(reviews));
        })
        .catch(e => dispatch(queryReviewsError(e)));
};

export const showUser = userId => (dispatch, getState, sdk) => {
    dispatch(showUserRequest(userId));
    return sdk.users
        .show({
            id: userId,
            include: ['profileImage'],
            'fields.image': [
                'variants.square-small',
                'variants.square-small2x',
            ],
        })
        .then(response => {
            dispatch(addMarketplaceEntities(response));
            dispatch(showUserSuccess());
            return response;
        })
        .catch(e => dispatch(showUserError(storableError(e))));
};

export const getAllAvailableListing = () => (dispatch, getState, sdk) => {
    return sdk.listings
        .query({
            include: ['author', 'images'],
            'fields.listing.id': ['619db388-14ef-4bf3-a23e-2182aceb5fec'],
            'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
            'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
            'fields.image': [
                'variants.landscape-crop',
                'variants.landscape-crop2x',
            ],
            'limit.images': 1,
        })
        .then(response => {
            dispatch(addMarketplaceEntities(response));
            dispatch(allListingsSuccess(response));
            return response;
        })
        .catch(e => {
            // dispatch(allListingsError(storableError(e)));
            throw e;
        });
};

export const getFavoriteListings = ids => (dispatch, getState, sdk) => {
    return sdk.listings
        .query({
            ids,
            include: ['author', 'images'],
            'fields.listing.id': ['619db388-14ef-4bf3-a23e-2182aceb5fec'],
            'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
            'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
            'fields.image': [
                'variants.landscape-crop',
                'variants.landscape-crop2x',
            ],
            'limit.images': 1,
        })
        .then(response => {
            // dispatch(addMarketplaceEntities(response));
            // dispatch(allListingsSuccess(response));
            return response;
        })
        .catch(e => {
            // dispatch(allListingsError(storableError(e)));
            throw e;
        });
};

export const loadData = params => (dispatch, getState, sdk) => {
    const userId = new UUID(params.id);

    // Clear state so that previously loaded data is not visible
    // in case this page load fails.
    dispatch(setInitialState());

    return Promise.all([
        dispatch(fetchCurrentUser()),
        dispatch(showUser(userId)),
        dispatch(queryUserListings(userId)),
        dispatch(queryUserReviews(userId)),
    ]);
};
