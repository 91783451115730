import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import Decimal from 'decimal.js';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import {
    propTypes,
    LINE_ITEM_CUSTOMER_COMMISSION,
    LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

/**
 * Calculates the total price in sub units for multiple line items.
 */
const lineItemsTotal = lineItems => {
    const amount = lineItems.reduce((total, item) => {
        return total.plus(item.lineTotal.amount);
    }, new Decimal(0));
    const currency = lineItems[0]
        ? lineItems[0].lineTotal.currency
        : config.currency;
    return new Money(amount, currency);
};

/**
 * Checks if line item represents commission
 */
const isCommission = (lineItem, isProvider) => {
    return (
        lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
        lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION ||
        (isProvider && lineItem.code === 'line-item/booking-fee')
    );
};

/**
 * Returns non-commission, reversal line items
 */
const nonCommissionReversalLineItems = (transaction, isProvider) => {
    return transaction.attributes.lineItems.filter(
        item => !isCommission(item, isProvider) && item.reversal
    );
};

const LineItemRefundMaybe = props => {
    const { transaction, transactions, intl, isProvider } = props;
    // console.log(transactions)

    // all non-commission, reversal line items
    const refundLineItems = nonCommissionReversalLineItems(transaction);
    const _refundLineItems = transactions.map(_tx => [
        ...nonCommissionReversalLineItems(_tx, isProvider),
    ]);
    // const refund = lineItemsTotal(refundLineItems);
    const _refund = _refundLineItems
        .filter(ref => ref.length > 0)
        .map(refundLineItem => [lineItemsTotal(refundLineItem)]);

    // const formattedRefund = refundLineItems.length > 0 ? formatMoney(intl, refund) : null;
    const _formattedRefund =
        _refundLineItems &&
        _refundLineItems.length > 0 &&
        _refund &&
        _refund.length > 0 &&
        _refund.map(_ref => [formatMoney(intl, _ref[0])]);

    var totalRefund = 0;
    if (_formattedRefund && _formattedRefund.length > 0) {
        _formattedRefund.map(_totalRefund => {
            const amount = _totalRefund[0].split('$');
            totalRefund = totalRefund + Number(amount[1]);
        });
    }

    return _formattedRefund && _formattedRefund.length > 0 ? (
        <div className={css.lineItem}>
            <span className={css.itemLabel}>
                <FormattedMessage id="BookingBreakdown.refund" />
            </span>
            {/* <span className={css.itemValue}>{formattedRefund}</span> */}
            <span className={css.itemValue}>{`-$${totalRefund.toFixed(
                2
            )}`}</span>
        </div>
    ) : null;
};

LineItemRefundMaybe.propTypes = {
    transaction: propTypes.transaction.isRequired,
    intl: intlShape.isRequired,
};

export default LineItemRefundMaybe;
