import { bool } from 'prop-types';
import React from 'react';

import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { LINE_ITEM_PROVIDER_COMMISSION, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';
const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

// Validate the assumption that the commission exists and the amount
// is zero or negative.
const isValidCommissions = commissionLineItems => {
    return !commissionLineItems
        .map(_li => _li.lineTotal instanceof Money && _li.lineTotal.amount <= 0)
        .includes(false);
};

const LineItemProviderCommissionMaybe = props => {
    const { transaction, transactions, isProvider, intl } = props;

    const providerCommissionLineItem = transactions
        .map(_t =>
            _t.attributes.lineItems.find(
                item =>
                    item.code === LINE_ITEM_PROVIDER_COMMISSION &&
                    !item.reversal
            )
        )
        .map(_li => _li);

    // If commission is passed it will be shown as a fee already reduces from the total price
    let commissionItem = null;

    // Flex Template for Web is using the default transaction process (https://www.sharetribe.com/docs/background/transaction-process/#sharetribe-flex-default-transaction-process)
    // which containt provider commissions so by default the providerCommissionLineItem should exist.
    // If you are not using provider commisison you might want to remove this whole component from BookingBreakdown.js file.
    if (isProvider && providerCommissionLineItem.length) {
        if (!isValidCommissions(providerCommissionLineItem)) {
            // eslint-disable-next-line no-console
            console.error(
                'invalid commission line item:',
                providerCommissionLineItem
            );
            throw new Error(
                'Commission should be present and the value should be zero or negative'
            );
        }

        const totalCommission = providerCommissionLineItem
            .map(_li => _li.lineTotal.amount)
            .reduce((a, b) => a + b, 0);
        const commission = new Money(
            totalCommission,
            providerCommissionLineItem[0].lineTotal.currency
        );
        const formattedCommission = totalCommission
            ? formatMoney(intl, commission)
            : null;

        commissionItem = (
            <div className={css.lineItem}>
                <span className={css.itemLabel}>
                    <FormattedMessage id="BookingBreakdown.commission" />
                </span>
                <span className={css.itemValue}>{formattedCommission}</span>
            </div>
        );
    }

    return commissionItem;
};

LineItemProviderCommissionMaybe.propTypes = {
    transaction: propTypes.transaction.isRequired,
    isProvider: bool.isRequired,
    intl: intlShape.isRequired,
};

export default LineItemProviderCommissionMaybe;
