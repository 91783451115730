import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { TRANSITIONS, txIsAccepted } from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createEvent } from 'ics';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

const sortedTransactions = txs =>
    reverse(
        sortBy(txs, tx => {
            return tx.attributes ? tx.attributes.lastTransitionedAt : null;
        })
    );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST =
    'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS =
    'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR =
    'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
    entities.map(entity => ({
        id: entity.id,
        type: entity.type,
    }));

const initialState = {
    fetchInProgress: false,
    fetchOrdersOrSalesError: null,
    pagination: null,
    transactionRefs: [],
};

export default function checkoutPageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ORDERS_OR_SALES_REQUEST:
            return {
                ...state,
                fetchInProgress: true,
                fetchOrdersOrSalesError: null,
            };
        case FETCH_ORDERS_OR_SALES_SUCCESS: {
            const transactions = sortedTransactions(payload.data.data);
            return {
                ...state,
                fetchInProgress: false,
                transactionRefs: entityRefs(transactions),
                pagination: payload.data.meta,
            };
        }
        case FETCH_ORDERS_OR_SALES_ERROR:
            console.error(payload); // eslint-disable-line
            return {
                ...state,
                fetchInProgress: false,
                fetchOrdersOrSalesError: payload,
            };

        default:
            return state;
    }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({
    type: FETCH_ORDERS_OR_SALES_REQUEST,
});
const fetchOrdersOrSalesSuccess = response => ({
    type: FETCH_ORDERS_OR_SALES_SUCCESS,
    payload: response,
});
const fetchOrdersOrSalesError = e => ({
    type: FETCH_ORDERS_OR_SALES_ERROR,
    error: true,
    payload: e,
});

// ================ Thunks ================ //

const createICSAsync = event => {
    return new Promise((resolve, reject) =>
        createEvent(event, (error, value) => {
            if (error) {
                console.error(error);
                reject(error);
            }

            resolve(value);
        })
    );
};

const splitDateToArray = date => [
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
];

export const getBookingICS = async (tx, isOrder) => {
    if (!tx || !tx.listing || !tx.booking) return null;
    const geoLocation = tx?.listing?.attributes?.geolocation;
    const location = Object?.values(
        tx?.listing?.attributes?.publicData?.location
    )?.join(', ');
    const listingTitle = tx?.listing?.attributes?.title;
    const customerName = tx?.customer.attributes.profile.displayName;
    const startDate = new Date(tx?.booking?.attributes?.start);
    const endDate = new Date(tx?.booking?.attributes?.end);
    const event = {
        start: splitDateToArray(startDate),
        end: splitDateToArray(endDate),
        title: isOrder
            ? `${listingTitle}`
            : `${customerName} booked ${listingTitle}`,
        // description: 'Annual 10-kilometer run in Boulder, Colorado',
        location,
        // url: 'http://www.bolderboulder.com/',
        geo: { lat: geoLocation.lat, lon: geoLocation.lng },
        // categories: ['10k races', 'Memorial Day Weekend', 'Boulder CO'],
        status: 'CONFIRMED',
        // busyStatus: 'BUSY',
        // organizer: { name: 'Admin', email: 'Race@BolderBOULDER.com' },
        // attendees: [
        //     { name: 'Adam Gibbons', email: 'adam@example.com', rsvp: true, partstat: 'ACCEPTED', role: 'REQ-PARTICIPANT' },
        //     { name: 'Brittany Seaton', email: 'brittany@example2.org', dir: 'https://linkedin.com/in/brittanyseaton', role: 'OPT-PARTICIPANT' }
        // ]
    };

    return await createICSAsync(event);
};

const deepCopy = ori =>
    Object.assign(Object.create(Object.getPrototypeOf(ori)), ori);

export const groupTransactions = transactions => {
    let sortedTrans = [];
    // sorting transaction that have same groupId into one object
    transactions.forEach(_t => {
        const groupId =
            _t.attributes &&
            _t.attributes.metadata &&
            _t.attributes.metadata.groupId;

        if (groupId) {
            const isIncluded =
                sortedTrans.filter(
                    _tt => _tt.attributes.metadata.groupId === groupId
                ).length > 0;
            // create new object to store same groupId trnsactions
            if (!isIncluded) {
                sortedTrans.push({
                    ..._t,
                    transactions: [_t],
                    id: new UUID(groupId),
                });
            } else {
                // add transaction to existing obj
                sortedTrans = sortedTrans.map(_st => {
                    if (_st.id.uuid === groupId) {
                        const newTransactions = [..._st.transactions, _t];
                        const newTx = deepCopy(_st);
                        // modify parent transactionBooking DisplayStart & DisplayEnd to show in Inbox

                        newTx.transactions = newTransactions;
                        // newTx.booking.attributes = newBookingAttributes;
                        return newTx;
                    } else {
                        return _st;
                    }
                });
            }
        } else {
            sortedTrans = [...sortedTrans, { ..._t, icsEvent: {} }];
        }
    });

    return sortedTrans;
};

const INBOX_PAGE_SIZE = 10;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
    const { tab } = params;

    const onlyFilterValues = {
        orders: 'order',
        sales: 'sale',
    };

    const onlyFilter = onlyFilterValues[tab];
    if (!onlyFilter) {
        return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
    }

    dispatch(fetchOrdersOrSalesRequest());

    const { page = 1 } = parse(search);

    const apiQueryParams = {
        only: onlyFilter,
        lastTransitions: TRANSITIONS,
        include: [
            'provider',
            'provider.profileImage',
            'customer',
            'customer.profileImage',
            'booking',
            'listing',
            'messages',
            'messages.sender',
        ],
        'fields.transaction': [
            'lastTransition',
            'lastTransitionedAt',
            'transitions',
            'payinTotal',
            'payoutTotal',
            'metadata',
            'lineItems',
        ],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        page,
        per_page: INBOX_PAGE_SIZE,
    };

    return sdk.transactions
        .query(apiQueryParams)
        .then(response => {
            dispatch(addMarketplaceEntities(response));
            dispatch(fetchOrdersOrSalesSuccess(response));
            return response;
        })
        .catch(e => {
            dispatch(fetchOrdersOrSalesError(storableError(e)));
            throw e;
        });
};
