import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
    Button,
    Form,
    FieldCurrencyInput,
    FieldSelect,
} from '../../components';
import css from './EditListingPricingForm.module.css';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
    <FinalForm
        {...props}
        render={formRenderProps => {
            const {
                className,
                disabled,
                ready,
                handleSubmit,
                intl,
                invalid,
                pristine,
                saveActionMsg,
                updated,
                updateInProgress,
                fetchErrors,
            } = formRenderProps;

            const unitType = config.bookingUnitType;
            const isNightly = unitType === LINE_ITEM_NIGHT;
            const isDaily = unitType === LINE_ITEM_DAY;

            const translationKey = isNightly
                ? 'EditListingPricingForm.pricePerNight'
                : isDaily
                ? 'EditListingPricingForm.pricePerDay'
                : 'EditListingPricingForm.pricePerUnit';

            const pricePerUnitMessage = intl.formatMessage({
                id: translationKey,
            });

            const pricePlaceholderMessage = intl.formatMessage({
                id: 'EditListingPricingForm.priceInputPlaceholder',
            });

            const priceRequired = validators.required(
                intl.formatMessage({
                    id: 'EditListingPricingForm.priceRequired',
                })
            );
            const minPrice = new Money(
                config.listingMinimumPriceSubUnits,
                config.currency
            );
            const minPriceRequired = validators.moneySubUnitAmountAtLeast(
                intl.formatMessage(
                    {
                        id: 'EditListingPricingForm.priceTooLow',
                    },
                    {
                        minPrice: formatMoney(intl, minPrice),
                    }
                ),
                config.listingMinimumPriceSubUnits
            );
            const priceValidators = config?.listingMinimumPriceSubUnits
                ? validators.composeValidators(priceRequired, minPriceRequired)
                : priceRequired;

            const classes = classNames(css.root, className);
            const submitReady = (updated && pristine) || ready;
            const submitInProgress = updateInProgress;
            const submitDisabled = invalid || disabled || submitInProgress;
            const { updateListingError, showListingsError } = fetchErrors || {};

            return (
                <Form onSubmit={handleSubmit} className={classes}>
                    {updateListingError ? (
                        <p className={css.error}>
                            <FormattedMessage id="EditListingPricingForm.updateFailed" />
                        </p>
                    ) : null}
                    {showListingsError ? (
                        <p className={css.error}>
                            <FormattedMessage id="EditListingPricingForm.showListingFailed" />
                        </p>
                    ) : null}
                    <FieldCurrencyInput
                        id="price"
                        name="price"
                        className={css.priceInput}
                        autoFocus
                        label={pricePerUnitMessage}
                        placeholder={pricePlaceholderMessage}
                        currencyConfig={config.currencyConfig}
                        validate={priceValidators}
                    />

                    <FieldSelect
                        id="isInstantBookingEnabled"
                        name="isInstantBookingEnabled"
                        label="Enable instant booking?"
                        className={css.instantBookingOptions}>
                        <option value="">
                            Select ‘Yes’ to automatically accept booking
                        </option>
                        <option>Yes</option>
                        <option>No</option>
                    </FieldSelect>

                    <p>
                        <FormattedMessage id="EditListingPricingForm.instantBookingNotes" />
                    </p>

                    {/* Discount */}
                    <FieldSelect
                        id="isDiscountBookingEnabled"
                        name="isDiscountBookingEnabled"
                        label="Enable Discount booking?"
                        className={css.instantBookingOptions}>
                        <option value={""} hidden>
                            Select an option
                        </option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                    </FieldSelect>

                    <p>
                        If player books 5+ hours in one transaction, 10%
                        discount is applied.{' '}
                    </p>

                    <Button
                        className={css.submitButton}
                        type="submit"
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                        ready={submitReady}>
                        {saveActionMsg}
                    </Button>
                </Form>
            );
        }}
    />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
    fetchErrors: shape({
        showListingsError: propTypes.error,
        updateListingError: propTypes.error,
    }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
