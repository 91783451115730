import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldSelect } from '../../components';

import css from './EditListingOthersForm.module.css';

const TITLE_MAX_LENGTH = 60;

const EditListingOthersFormComponent = props => (
    <FinalForm
        {...props}
        render={formRenderProps => {
            const {
                certificateOptions,
                className,
                disabled,
                ready,
                handleSubmit,
                intl,
                invalid,
                pristine,
                saveActionMsg,
                updated,
                updateInProgress,
                fetchErrors,
            } = formRenderProps;

            const howManyPlayersAllowedMessage = intl.formatMessage({
                id: 'EditListingOthersForm.howManyPlayersAllowed',
            });
            const howManyPlayersAllowedPlaceholderMessage = intl.formatMessage({
                id: 'EditListingOthersForm.howManyPlayersAllowedPlaceholder',
            });
            const howManyPlayersAllowedRequireddMessage = intl.formatMessage({
                id: 'EditListingOthersForm.howManyPlayersAllowedRequired',
            });
            const maxLengthMessage = intl.formatMessage(
                { id: 'EditListingOthersForm.maxLength' },
                {
                    maxLength: TITLE_MAX_LENGTH,
                }
            );

            const parkingSpotsMessage = intl.formatMessage({
                id: 'EditListingOthersForm.parkingSpots',
            });
            const parkingSpotsPlaceholderMessage = intl.formatMessage({
                id: 'EditListingOthersForm.parkingSpotsPlaceholder',
            });
            const parkingSpotsMessageRequiredMessage = intl.formatMessage({
                id: 'EditListingOthersForm.parkingSpotsRequired',
            });

            const rulesMessage = intl.formatMessage({
                id: 'EditListingOthersForm.rules',
            });
            const rulesPlaceholderMessage = intl.formatMessage({
                id: 'EditListingOthersForm.rulesPlaceholder',
            });
            const maxLength60Message = maxLength(
                maxLengthMessage,
                TITLE_MAX_LENGTH
            );
            const rulesRequiredMessage = intl.formatMessage({
                id: 'EditListingOthersForm.rulesRequired',
            });

            const notesMessage = intl.formatMessage({
                id: 'EditListingOthersForm.notes',
            });
            const notesPlaceholderMessage = intl.formatMessage({
                id: 'EditListingOthersForm.notesPlaceholder',
            });
            const notesRequiredMessage = intl.formatMessage({
                id: 'EditListingOthersForm.notesRequired',
            });

            const {
                updateListingError,
                createListingDraftError,
                showListingsError,
            } = fetchErrors || {};
            const errorMessageUpdateListing = updateListingError ? (
                <p className={css.error}>
                    <FormattedMessage id="EditListingOthersForm.updateFailed" />
                </p>
            ) : null;

            // This error happens only on first tab (of EditListingWizard)
            const errorMessageCreateListingDraft = createListingDraftError ? (
                <p className={css.error}>
                    <FormattedMessage id="EditListingOthersForm.createListingDraftError" />
                </p>
            ) : null;

            const errorMessageShowListing = showListingsError ? (
                <p className={css.error}>
                    <FormattedMessage id="EditListingOthersForm.showListingFailed" />
                </p>
            ) : null;

            const classes = classNames(css.root, className);
            const submitReady = (updated && pristine) || ready;
            const submitInProgress = updateInProgress;
            const submitDisabled = invalid || disabled || submitInProgress;

            return (
                <Form className={classes} onSubmit={handleSubmit}>
                    {errorMessageCreateListingDraft}
                    {errorMessageUpdateListing}
                    {errorMessageShowListing}

                    <FieldSelect
                        id="howManyPlayersAllowed"
                        name="howManyPlayersAllowed"
                        className={css.title}
                        label={howManyPlayersAllowedMessage}
                        validate={composeValidators(
                            required(howManyPlayersAllowedRequireddMessage)
                        )}>
                        <option key={'0'} value={''}>
                            {'Please select'}
                        </option>
                        <option key={'1'} value={'1'}>
                            {'1 Player'}
                        </option>
                        <option key={'2'} value={'2'}>
                            {'2 Players'}
                        </option>
                        <option key={'3'} value={'3'}>
                            {'3 Players'}
                        </option>
                        <option key={'4'} value={'4'}>
                            {'4 Players'}
                        </option>
                        <option key={'5'} value={'5+'}>
                            {'5+ Players'}
                        </option>
                    </FieldSelect>

                    {/* <FieldTextInput
            id="howManyPlayersAllowed"
            name="howManyPlayersAllowed"
            className={css.title}
            type="text"
            label={howManyPlayersAllowedMessage}
            placeholder={howManyPlayersAllowedPlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(howManyPlayersAllowedRequireddMessage), maxLength60Message)}
            autoFocus
          /> */}

                    <FieldSelect
                        id="parkingSpots"
                        name="parkingSpots"
                        className={css.title}
                        label={parkingSpotsMessage}
                        validate={composeValidators(
                            required(parkingSpotsMessageRequiredMessage)
                        )}>
                        <option key={'0'} value={''}>
                            {'Please select'}
                        </option>
                        <option key={'1'} value={'1'}>
                            {'1 Parking space'}
                        </option>
                        <option key={'2'} value={'2'}>
                            {'2 Parking spaces'}
                        </option>
                        <option key={'3'} value={'3'}>
                            {'3 Parking spaces'}
                        </option>
                        <option key={'4'} value={'4'}>
                            {'4 Parking spaces'}
                        </option>
                        <option key={'5'} value={'5+'}>
                            {'5+ Parking spaces'}
                        </option>
                    </FieldSelect>

                    {/* <FieldTextInput
            id="parkingSpots"
            name="parkingSpots"
            className={css.title}
            type="text"
            label={parkingSpotsMessage}
            placeholder={parkingSpotsPlaceholderMessage}
            validate={composeValidators(required(parkingSpotsMessageRequiredMessage))}
            autoFocus
          /> */}

                    <FieldTextInput
                        id="rules"
                        name="rules"
                        className={css.description}
                        type="textarea"
                        label={rulesMessage}
                        placeholder={rulesPlaceholderMessage}
                        validate={composeValidators(
                            required(rulesRequiredMessage)
                        )}
                    />

                    <FieldTextInput
                        id="notes"
                        name="notes"
                        className={css.description}
                        type="textarea"
                        label={notesMessage}
                        placeholder={notesPlaceholderMessage}
                    />

                    <Button
                        className={css.submitButton}
                        type="submit"
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                        ready={submitReady}>
                        {saveActionMsg}
                    </Button>
                </Form>
            );
        }}
    />
);

EditListingOthersFormComponent.defaultProps = {
    className: null,
    fetchErrors: null,
};

EditListingOthersFormComponent.propTypes = {
    className: string,
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
    fetchErrors: shape({
        createListingDraftError: propTypes.error,
        showListingsError: propTypes.error,
        updateListingError: propTypes.error,
    }),
    certificateOptions: arrayOf(
        shape({
            key: string.isRequired,
            label: string.isRequired,
        })
    ),
};

export default compose(injectIntl)(EditListingOthersFormComponent);
