import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import moment from 'moment';
import {
    LINE_ITEM_NIGHT,
    LINE_ITEM_UNITS,
    DATE_TYPE_DATE,
    propTypes,
} from '../../util/types';
import css from './BookingTimeForm.module.css';
import { CgCloseO } from 'react-icons/cg';

export const BookingDatesList = ({
    indexKey,
    booking,
    bookingData,
    removeBookingDates,
}) => {
    const {
        bookingEndDate,
        bookingEndTime,
        bookingStartDate,
        bookingStartTime,
    } = booking;
    const { unitType, timeZone } = bookingData;

    const timeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
    };

    const dateFormatOptions = {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
    };

    const timeZoneMaybe = timeZone ? { timeZone } : null;

    const localStartDate = bookingStartDate;
    const localEndDateRaw = bookingEndDate;

    // const isNightly = unitType === LINE_ITEM_NIGHT;
    // const isUnit = unitType === LINE_ITEM_UNITS;
    // const endDay =
    //     isUnit || isNightly ? localEndDateRaw : moment(localEndDateRaw).subtract(1, 'days');

    return (
        <div key={indexKey} className={css.dateListContainer}>
            <div className={css.dateListItem}>
                <FormattedDate
                    value={bookingStartDate.date}
                    {...dateFormatOptions}
                    {...timeZoneMaybe}
                />
            </div>
            <div className={css.dateListItemTime}>
                <FormattedDate
                    value={bookingStartDate.date}
                    {...timeFormatOptions}
                    {...timeZoneMaybe}
                />
                -
                <FormattedDate
                    value={bookingEndDate.date}
                    {...timeFormatOptions}
                    {...timeZoneMaybe}
                />
            </div>
            <div className={css.removeButton}>
                <CgCloseO
                    className="btn"
                    onClick={() => removeBookingDates(indexKey)}>
                    {' '}
                    X{' '}
                </CgCloseO>
            </div>
        </div>
    );
};
