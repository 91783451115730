import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
    isTransactionsTransitionInvalidTransition,
    storableError,
} from '../../util/errors';
import {
    txIsEnquired,
    getReview1Transition,
    getReview2Transition,
    txIsInFirstReviewBy,
    TRANSITION_ACCEPT,
    TRANSITION_DECLINE,
    TRANSITIONS,
    TRANSITION_CUSTOMER_CANCEL,
    TRANSITION_PROVIDER_CANCEL,
    TRANSITION_CUSTOMER_DECLINE,
    getUserTxRole,
} from '../../util/transaction';
import {
    listingUpdateRating,
    transactionLineItems,
    listingUpdateHours,
    sendSMS,
    updateTxSeenBy,
} from '../../util/api';
import * as log from '../../util/log';
import {
    updatedEntities,
    denormalisedEntities,
    denormalisedResponseEntities,
} from '../../util/data';
import {
    findNextBoundary,
    nextMonthFn,
    monthIdStringInTimeZone,
} from '../../util/dates';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUserNotifications } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

const MESSAGES_PAGE_SIZE = 100;
const CUSTOMER = 'customer';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/TransactionPage/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST =
    'app/TransactionPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS =
    'app/TransactionPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTIONS_SUCCESS =
    'app/TransactionPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTION_ERROR =
    'app/TransactionPage/FETCH_TRANSACTION_ERROR';

export const FETCH_TRANSITIONS_REQUEST =
    'app/TransactionPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS =
    'app/TransactionPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR =
    'app/TransactionPage/FETCH_TRANSITIONS_ERROR';

export const ACCEPT_SALE_REQUEST = 'app/TransactionPage/ACCEPT_SALE_REQUEST';
export const ACCEPT_SALE_SUCCESS = 'app/TransactionPage/ACCEPT_SALE_SUCCESS';
export const ACCEPT_SALE_ERROR = 'app/TransactionPage/ACCEPT_SALE_ERROR';

export const DECLINE_SALE_REQUEST = 'app/TransactionPage/DECLINE_SALE_REQUEST';
export const DECLINE_SALE_SUCCESS = 'app/TransactionPage/DECLINE_SALE_SUCCESS';
export const DECLINE_SALE_ERROR = 'app/TransactionPage/DECLINE_SALE_ERROR';

export const FETCH_MESSAGES_REQUEST =
    'app/TransactionPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS =
    'app/TransactionPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/TransactionPage/FETCH_MESSAGES_ERROR';

export const SEND_MESSAGE_REQUEST = 'app/TransactionPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/TransactionPage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/TransactionPage/SEND_MESSAGE_ERROR';

export const SEND_REVIEW_REQUEST = 'app/TransactionPage/SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCESS = 'app/TransactionPage/SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_ERROR = 'app/TransactionPage/SEND_REVIEW_ERROR';

export const FETCH_TIME_SLOTS_REQUEST =
    'app/TransactionPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS =
    'app/TransactionPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR =
    'app/TransactionPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST =
    'app/TransactionPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS =
    'app/TransactionPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR =
    'app/TransactionPage/FETCH_LINE_ITEMS_ERROR';

export const SCROLL_TO_BOTTOM = 'app/TransactionPage/SCROLL_TO_BOTTOM';

// ================ Reducer ================ //

const initialState = {
    fetchTransactionInProgress: false,
    fetchTransactionError: null,
    transactionRef: null,
    acceptInProgress: false,
    acceptSaleError: null,
    declineInProgress: false,
    declineSaleError: null,
    cancelInProgress: false,
    fetchMessagesInProgress: false,
    fetchMessagesError: null,
    totalMessages: 0,
    totalMessagePages: 0,
    oldestMessagePageFetched: 0,
    currentMessagesTxId: null,
    messages: [],
    transactions: [],
    initialMessageFailedToTransaction: null,
    savePaymentMethodFailed: false,
    sendMessageInProgress: false,
    sendMessageError: null,
    sendReviewInProgress: false,
    sendReviewError: null,
    monthlyTimeSlots: {
        // '2019-12': {
        //   timeSlots: [],
        //   fetchTimeSlotsError: null,
        //   fetchTimeSlotsInProgress: null,
        // },
    },
    fetchTransitionsInProgress: false,
    fetchTransitionsError: null,
    processTransitions: null,
    lineItems: null,
    fetchLineItemsInProgress: false,
    fetchLineItemsError: null,
    scrollToBottom: false,
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
    return a
        .filter(
            aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)
        )
        .concat(b);
};

export default function checkoutPageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case SET_INITIAL_VALUES:
            return { ...initialState, ...payload };

        case FETCH_TRANSACTION_REQUEST:
            return {
                ...state,
                fetchTransactionInProgress: true,
                fetchTransactionError: null,
            };
        case FETCH_TRANSACTION_SUCCESS: {
            const transactionRef = {
                id: payload.data.data.id,
                type: 'transaction',
            };
            return {
                ...state,
                fetchTransactionInProgress: false,
                transactionRef,
            };
        }
        case FETCH_TRANSACTIONS_SUCCESS: {
            return { ...state, transactions: payload };
        }
        case FETCH_TRANSACTION_ERROR:
            console.error(payload); // eslint-disable-line
            return {
                ...state,
                fetchTransactionInProgress: false,
                fetchTransactionError: payload,
            };

        case FETCH_TRANSITIONS_REQUEST:
            return {
                ...state,
                fetchTransitionsInProgress: true,
                fetchTransitionsError: null,
            };
        case FETCH_TRANSITIONS_SUCCESS:
            return {
                ...state,
                fetchTransitionsInProgress: false,
                processTransitions: payload,
            };
        case FETCH_TRANSITIONS_ERROR:
            console.error(payload); // eslint-disable-line
            return {
                ...state,
                fetchTransitionsInProgress: false,
                fetchTransitionsError: payload,
            };

        case ACCEPT_SALE_REQUEST:
            return {
                ...state,
                acceptInProgress: true,
                acceptSaleError: null,
                declineSaleError: null,
            };
        case ACCEPT_SALE_SUCCESS:
            return { ...state, acceptInProgress: false };
        case ACCEPT_SALE_ERROR:
            return {
                ...state,
                acceptInProgress: false,
                acceptSaleError: payload,
            };

        case DECLINE_SALE_REQUEST:
            return {
                ...state,
                declineInProgress: true,
                declineSaleError: null,
                acceptSaleError: null,
            };
        case DECLINE_SALE_SUCCESS:
            return { ...state, declineInProgress: false };
        case DECLINE_SALE_ERROR:
            return {
                ...state,
                declineInProgress: false,
                declineSaleError: payload,
            };

        case FETCH_MESSAGES_REQUEST:
            return {
                ...state,
                fetchMessagesInProgress: true,
                fetchMessagesError: null,
            };
        case FETCH_MESSAGES_SUCCESS: {
            const sameId =
                JSON.stringify(state.currentMessagesTxId) ===
                JSON.stringify(payload.txId);

            const oldestMessagePageFetched =
                state.oldestMessagePageFetched > payload.page
                    ? state.oldestMessagePageFetched
                    : payload.page;

            return {
                ...state,
                fetchMessagesInProgress: false,
                messages: sameId
                    ? mergeEntityArrays(state.messages, payload.messages)
                    : payload.messages,
                currentMessagesTxId: payload.txId,
                totalMessages: [payload.totalItems],
                totalMessagePages: payload.totalPages,
                oldestMessagePageFetched,
            };
        }
        case FETCH_MESSAGES_ERROR:
            return {
                ...state,
                fetchMessagesInProgress: false,
                fetchMessagesError: payload,
            };

        case SEND_MESSAGE_REQUEST:
            return {
                ...state,
                sendMessageInProgress: true,
                sendMessageError: null,
                initialMessageFailedToTransaction: null,
            };
        case SEND_MESSAGE_SUCCESS:
            return { ...state, sendMessageInProgress: false };
        case SEND_MESSAGE_ERROR:
            return {
                ...state,
                sendMessageInProgress: false,
                sendMessageError: payload,
            };

        case SEND_REVIEW_REQUEST:
            return {
                ...state,
                sendReviewInProgress: true,
                sendReviewError: null,
            };
        case SEND_REVIEW_SUCCESS:
            return { ...state, sendReviewInProgress: false };
        case SEND_REVIEW_ERROR:
            return {
                ...state,
                sendReviewInProgress: false,
                sendReviewError: payload,
            };

        case FETCH_TIME_SLOTS_REQUEST: {
            const monthlyTimeSlots = {
                ...state.monthlyTimeSlots,
                [payload]: {
                    ...state.monthlyTimeSlots[payload],
                    fetchTimeSlotsError: null,
                    fetchTimeSlotsInProgress: true,
                },
            };
            return { ...state, monthlyTimeSlots };
        }
        case FETCH_TIME_SLOTS_SUCCESS: {
            const monthId = payload.monthId;
            const monthlyTimeSlots = {
                ...state.monthlyTimeSlots,
                [monthId]: {
                    ...state.monthlyTimeSlots[monthId],
                    fetchTimeSlotsInProgress: false,
                    timeSlots: payload.timeSlots,
                },
            };
            return { ...state, monthlyTimeSlots };
        }
        case FETCH_TIME_SLOTS_ERROR: {
            const monthId = payload.monthId;
            const monthlyTimeSlots = {
                ...state.monthlyTimeSlots,
                [monthId]: {
                    ...state.monthlyTimeSlots[monthId],
                    fetchTimeSlotsInProgress: false,
                    fetchTimeSlotsError: payload.error,
                },
            };
            return { ...state, monthlyTimeSlots };
        }

        case FETCH_LINE_ITEMS_REQUEST:
            return {
                ...state,
                fetchLineItemsInProgress: true,
                fetchLineItemsError: null,
            };
        case FETCH_LINE_ITEMS_SUCCESS:
            return {
                ...state,
                fetchLineItemsInProgress: false,
                lineItems: payload,
            };
        case FETCH_LINE_ITEMS_ERROR:
            return {
                ...state,
                fetchLineItemsInProgress: false,
                fetchLineItemsError: payload,
            };
        case SCROLL_TO_BOTTOM:
            return { ...state, scrollToBottom: !state.scrollToBottom };
        default:
            return state;
    }
}

// ================ Selectors ================ //

export const acceptOrDeclineInProgress = state => {
    return (
        state.TransactionPage.acceptInProgress ||
        state.TransactionPage.declineInProgress
    );
};

export const cancelInProgress = state => {
    return state.TransactionPage.cancelInProgress;
};

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
    type: SET_INITIAL_VALUES,
    payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
    type: FETCH_TRANSACTION_SUCCESS,
    payload: response,
});

const fetchTransactionsSuccess = response => ({
    type: FETCH_TRANSACTIONS_SUCCESS,
    payload: response,
});

const fetchTransactionError = e => ({
    type: FETCH_TRANSACTION_ERROR,
    error: true,
    payload: e,
});

const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
    type: FETCH_TRANSITIONS_SUCCESS,
    payload: response,
});
const fetchTransitionsError = e => ({
    type: FETCH_TRANSITIONS_ERROR,
    error: true,
    payload: e,
});

const acceptSaleRequest = () => ({ type: ACCEPT_SALE_REQUEST });
const acceptSaleSuccess = () => ({ type: ACCEPT_SALE_SUCCESS });
const acceptSaleError = e => ({
    type: ACCEPT_SALE_ERROR,
    error: true,
    payload: e,
});

const declineSaleRequest = () => ({ type: DECLINE_SALE_REQUEST });
const declineSaleSuccess = () => ({ type: DECLINE_SALE_SUCCESS });
const declineSaleError = e => ({
    type: DECLINE_SALE_ERROR,
    error: true,
    payload: e,
});

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (txId, messages, pagination) => ({
    type: FETCH_MESSAGES_SUCCESS,
    payload: { txId, messages, ...pagination },
});
const fetchMessagesError = e => ({
    type: FETCH_MESSAGES_ERROR,
    error: true,
    payload: e,
});

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
const sendMessageError = e => ({
    type: SEND_MESSAGE_ERROR,
    error: true,
    payload: e,
});

const sendReviewRequest = () => ({ type: SEND_REVIEW_REQUEST });
const sendReviewSuccess = () => ({ type: SEND_REVIEW_SUCCESS });
const sendReviewError = e => ({
    type: SEND_REVIEW_ERROR,
    error: true,
    payload: e,
});

export const fetchTimeSlotsRequest = monthId => ({
    type: FETCH_TIME_SLOTS_REQUEST,
    payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
    type: FETCH_TIME_SLOTS_SUCCESS,
    payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
    type: FETCH_TIME_SLOTS_ERROR,
    error: true,
    payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
    type: FETCH_LINE_ITEMS_SUCCESS,
    payload: lineItems,
});
export const fetchLineItemsError = error => ({
    type: FETCH_LINE_ITEMS_ERROR,
    error: true,
    payload: error,
});

// ================ Thunks ================ //

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
    return sdk.timeslots.query(params).then(response => {
        return denormalisedResponseEntities(response);
    });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (
    dispatch,
    getState,
    sdk
) => {
    const monthId = monthIdStringInTimeZone(start, timeZone);

    dispatch(fetchTimeSlotsRequest(monthId));

    // The maximum pagination page size for timeSlots is 500
    const extraParams = {
        per_page: 500,
        page: 1,
    };

    return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
        .then(timeSlots => {
            dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
        })
        .catch(e => {
            dispatch(fetchTimeSlotsError(monthId, storableError(e)));
        });
};

// Helper function for fetchTransaction call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
    const hasWindow = typeof window !== 'undefined';
    const attributes = listing.attributes;
    // Listing could be ownListing entity too, so we just check if attributes key exists
    const hasTimeZone =
        attributes &&
        attributes.availabilityPlan &&
        attributes.availabilityPlan.timezone;

    // Fetch time-zones on client side only.
    if (hasWindow && listing.id && hasTimeZone) {
        const tz = listing.attributes.availabilityPlan.timezone;
        const nextBoundary = findNextBoundary(tz, new Date());

        const nextMonth = nextMonthFn(nextBoundary, tz);
        const nextAfterNextMonth = nextMonthFn(nextMonth, tz);

        return Promise.all([
            dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
            dispatch(
                fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)
            ),
        ]);
    }

    // By default return an empty array
    return Promise.all([]);
};

const listingRelationship = txResponse => {
    return txResponse.data.data.relationships.listing.data;
};

export const fetchTransaction = (id, txRole) => (dispatch, getState, sdk) => {
    dispatch(fetchTransactionRequest());
    let txResponse = null;
    let txResponses = [];
    let groupId = null;

    return sdk.transactions
        .show(
            {
                id,
                include: [
                    'customer',
                    'customer.profileImage',
                    'provider',
                    'provider.profileImage',
                    'listing',
                    'booking',
                    'reviews',
                    'reviews.author',
                    'reviews.subject',
                    'metadata',
                ],
                ...IMAGE_VARIANTS,
            },
            { expand: true }
        )
        .then(async res => {
            const curTran = res.data.data;
            groupId = curTran?.attributes?.metadata?.groupId;
            const newRes = {};
            newRes.response = res;

            updateTxSeenBy({ transactionId: id, txRole });
            dispatch(fetchCurrentUserNotifications());

            if (groupId) {
                const transactionListRes = await sdk.transactions.query(
                    {
                        lastTransitions: TRANSITIONS,
                        include: [
                            'provider',
                            'provider.profileImage',
                            'customer',
                            'customer.profileImage',
                            'booking',
                            'listing',
                        ],
                        'fields.transaction': [
                            'lastTransition',
                            'lastTransitionedAt',
                            'transitions',
                            'payinTotal',
                            'payoutTotal',
                            'metadata',
                            'lineItems',
                        ],
                        ...IMAGE_VARIANTS,
                    },
                    { expand: true }
                );

                txResponses = transactionListRes;
                const transactions =
                    transactionListRes &&
                    transactionListRes.data.data.filter(
                        _rst =>
                            curTran.attributes.metadata.groupId ===
                            _rst.attributes?.metadata?.groupId
                    );
                newRes.transactions = transactions;
            }
            return newRes;
        })
        .then(async res => {
            const response = res.response;
            if (res.transactions) {
                response.data.data.transactions = res.transactions;
            }
            txResponse = response;

            const listingId = listingRelationship(response).id;
            const entities = updatedEntities({}, response.data);
            const listingRef = { id: listingId, type: 'listing' };
            const transactionRef = { id, type: 'transaction' };
            const denormalised = denormalisedEntities(entities, [
                listingRef,
                transactionRef,
            ]);
            const listing = denormalised[0];
            const transaction = denormalised[1];

            // Fetch time slots for transactions that are in enquired state
            const canFetchTimeslots =
                txRole === 'customer' &&
                config.enableAvailability &&
                transaction &&
                txIsEnquired(transaction);

            if (canFetchTimeslots) {
                fetchMonthlyTimeSlots(dispatch, listing);
            }

            const canFetchListing =
                listing && listing.attributes && !listing.attributes.deleted;
            if (canFetchListing) {
                const listing = await sdk.listings.show({
                    id: listingId,
                    include: ['author', 'author.profileImage', 'images'],
                    ...IMAGE_VARIANTS,
                });
                listing.data.data.transactions = res.transactions;
                return listing;
            } else {
                return response;
            }
        })
        .then(response => {
            dispatch(addMarketplaceEntities(response));
            dispatch(addMarketplaceEntities(txResponse));
            dispatch(fetchTransactionSuccess(txResponse));
            if (groupId) {
                dispatch(addMarketplaceEntities(txResponses));
                dispatch(
                    fetchTransactionsSuccess(txResponse.data.data.transactions)
                );
            }
            return response;
        })
        .catch(e => {
            dispatch(fetchTransactionError(storableError(e)));
            throw e;
        });
};

export const acceptSale = (
    id,
    listing,
    quantity,
    transactions,
    isLastTx,
    currentCustomer
) => (dispatch, getState, sdk) => {
    if (acceptOrDeclineInProgress(getState())) {
        return Promise.reject(
            new Error('Accept or decline already in progress')
        );
    }
    dispatch(acceptSaleRequest());
    if (isLastTx) {
        const URL = `${window.location.origin}/app/order/${id.uuid}`;
        sendSMS({
            params: {
                Message: `RentMyCourt: Your booking has been confirmed. Check it out at ${URL}`,
            },
            userToMessage: currentCustomer,
        });
    }

    return sdk.transactions
        .transition(
            {
                id,
                transition: TRANSITION_ACCEPT,
                params: {
                    metadata: { seenByProvider: false, seenByCustomer: false },
                },
            },
            { expand: true }
        )
        .then(response => {
            dispatch(acceptSaleSuccess());
            dispatch(fetchCurrentUserNotifications());
            // listingUpdateHours({ listingId: listing.id, publicData: { hoursPlayed: quantity } })
            if (isLastTx) {
                dispatch(addMarketplaceEntities(response));
                if (typeof window !== 'undefined') {
                    window.location.reload();
                }
            }
            return response;
        })
        .catch(e => {
            dispatch(acceptSaleError(storableError(e)));
            log.error(e, 'accept-sale-failed', {
                txId: id,
                transition: TRANSITION_ACCEPT,
            });
            throw e;
        });
};

export const cancelBooking = transaction => (dispatch, getState, sdk) => {
    if (cancelInProgress(getState())) {
        return Promise.reject(new Error('Cancel already in progress'));
    }
    const { currentUser } = getState().user;
    // dispatch(acceptSaleRequest());

    const userRole = getUserTxRole(currentUser.id, transaction);
    var transition =
        userRole === CUSTOMER
            ? TRANSITION_CUSTOMER_CANCEL
            : TRANSITION_PROVIDER_CANCEL;
    if (transaction.attributes.lastTransition == 'transition/confirm-payment') {
        transition = TRANSITION_CUSTOMER_DECLINE;
    }

    return sdk.transactions
        .transition(
            {
                id: transaction.id,
                transition: transition,
                params: {
                    metadata: { seenByProvider: false, seenByCustomer: false },
                },
            },
            { expand: true }
        )
        .then(response => {
            dispatch(addMarketplaceEntities(response));
            dispatch(acceptSaleSuccess());
            dispatch(fetchCurrentUserNotifications());
            if (typeof window !== 'undefined') {
                window.location.reload();
            }
            return response;
        })
        .catch(e => {
            dispatch(acceptSaleError(storableError(e)));
            log.error(e, 'cancel-sale-failed', {
                txId: transaction.id,
                transition: transition,
            });
            throw e;
        });
};

export const declineSale = id => (dispatch, getState, sdk) => {
    if (acceptOrDeclineInProgress(getState())) {
        return Promise.reject(
            new Error('Accept or decline already in progress')
        );
    }
    dispatch(declineSaleRequest());

    return sdk.transactions
        .transition(
            {
                id,
                transition: TRANSITION_DECLINE,
                params: {
                    metadata: { seenByProvider: false, seenByCustomer: false },
                },
            },
            { expand: true }
        )
        .then(response => {
            dispatch(addMarketplaceEntities(response));
            dispatch(declineSaleSuccess());
            dispatch(fetchCurrentUserNotifications());
            return response;
        })
        .catch(e => {
            dispatch(declineSaleError(storableError(e)));
            log.error(e, 'reject-sale-failed', {
                txId: id,
                transition: TRANSITION_DECLINE,
            });
            throw e;
        });
};

export const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
    const paging = { page, per_page: MESSAGES_PAGE_SIZE };
    dispatch(fetchMessagesRequest());
    return sdk.messages
        .query({
            transaction_id: txId,
            include: ['sender', 'sender.profileImage'],
            ...IMAGE_VARIANTS,
            ...paging,
        })
        .then(response => {
            const messages = denormalisedResponseEntities(response);
            const {
                totalItems,
                totalPages,
                page: fetchedPage,
            } = response.data.meta;
            const pagination = { totalItems, totalPages, page: fetchedPage };
            const totalMessages = getState().TransactionPage.totalMessages;
            // Original fetchMessages call succeeded
            dispatch(fetchMessagesSuccess(txId, messages, pagination));

            // Check if totalItems has changed between fetched pagination pages
            // if totalItems has changed, fetch first page again to include new incoming messages.
            // TODO if there're more than 100 incoming messages,
            // this should loop through most recent pages instead of fetching just the first one.
            if (totalItems > totalMessages && page > 1) {
                dispatch(fetchMessages(txId, 1))
                    .then(() => {
                        // Original fetch was enough as a response for user action,
                        // this just includes new incoming messages
                    })
                    .catch(() => {
                        // Background update, no need to to do anything atm.
                    });
            }
        })
        .catch(e => {
            dispatch(fetchMessagesError(storableError(e)));
            throw e;
        });
};

export const fetchMoreMessages = txId => (dispatch, getState, sdk) => {
    const state = getState();
    const {
        oldestMessagePageFetched,
        totalMessagePages,
    } = state.TransactionPage;
    const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

    // In case there're no more old pages left we default to fetching the current cursor position
    const nextPage = hasMoreOldMessages
        ? oldestMessagePageFetched + 1
        : oldestMessagePageFetched;

    return dispatch(fetchMessages(txId, nextPage));
};

export const sendMessage = (txId, message) => (dispatch, getState, sdk) => {
    dispatch(sendMessageRequest());

    return sdk.messages
        .send({ transactionId: txId, content: message })
        .then(response => {
            const messageId = response.data.data.id;

            updateTxSeenBy({ transactionId: txId, txRole: 'both' });
            dispatch(fetchCurrentUserNotifications());

            // We fetch the first page again to add sent message to the page data
            // and update possible incoming messages too.
            // TODO if there're more than 100 incoming messages,
            // this should loop through most recent pages instead of fetching just the first one.
            return dispatch(fetchMessages(txId, 1))
                .then(() => {
                    dispatch(sendMessageSuccess());
                    dispatch({
                        type: SCROLL_TO_BOTTOM,
                    });
                    return messageId;
                })
                .catch(() => dispatch(sendMessageSuccess()));
        })
        .catch(e => {
            dispatch(sendMessageError(storableError(e)));
            // Rethrow so the page can track whether the sending failed, and
            // keep the message in the form for a retry.
            throw e;
        });
};

const REVIEW_TX_INCLUDES = ['reviews', 'reviews.author', 'reviews.subject'];
const IMAGE_VARIANTS = {
    'fields.image': [
        // Profile images
        'variants.square-small',
        'variants.square-small2x',

        // Listing images:
        'variants.landscape-crop',
        'variants.landscape-crop2x',
    ],
};

// If other party has already sent a review, we need to make transition to
// TRANSITION_REVIEW_2_BY_<CUSTOMER/PROVIDER>
const sendReviewAsSecond = (id, params, role, dispatch, sdk) => {
    const transition = getReview2Transition(role === CUSTOMER);

    const include = REVIEW_TX_INCLUDES;

    return sdk.transactions
        .transition(
            { id, transition, params },
            { expand: true, include, ...IMAGE_VARIANTS }
        )
        .then(response => {
            dispatch(addMarketplaceEntities(response));
            dispatch(sendReviewSuccess());
            return response;
        })
        .catch(e => {
            dispatch(sendReviewError(storableError(e)));

            // Rethrow so the page can track whether the sending failed, and
            // keep the message in the form for a retry.
            throw e;
        });
};

// If other party has not yet sent a review, we need to make transition to
// TRANSITION_REVIEW_1_BY_<CUSTOMER/PROVIDER>
// However, the other party might have made the review after previous data synch point.
// So, error is likely to happen and then we must try another state transition
// by calling sendReviewAsSecond().
// const sendReviewAsFirst = (id, params, role, dispatch, sdk) => {
//   const transition = getReview1Transition(role === CUSTOMER);
//   const include = REVIEW_TX_INCLUDES;

//   return sdk.transactions
//     .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
//     .then(response => {
//       dispatch(addMarketplaceEntities(response));
//       dispatch(sendReviewSuccess());
//       return response;
//     })
//     .catch(e => {
//       // If transaction transition is invalid, lets try another endpoint.
//       if (isTransactionsTransitionInvalidTransition(e)) {
//         return sendReviewAsSecond(id, params, role, dispatch, sdk);
//       } else {
//         dispatch(sendReviewError(storableError(e)));

//         // Rethrow so the page can track whether the sending failed, and
//         // keep the message in the form for a retry.
//         throw e;
//       }
//     });
// };

const sendReviewAsFirst = (id, params, role, dispatch, sdk, listing) => {
    const transition = getReview1Transition(role === CUSTOMER);
    const include = REVIEW_TX_INCLUDES;
    const { reviewRating } = params;

    return sdk.transactions
        .transition(
            { id, transition, params },
            { expand: true, include, ...IMAGE_VARIANTS }
        )
        .then(response => {
            dispatch(addMarketplaceEntities(response));
            dispatch(sendReviewSuccess());

            // update the listing publicData for rating search query
            if (role === CUSTOMER) {
                var listingPublicData =
                    listing &&
                    listing.attributes &&
                    listing.attributes.publicData;
                var currentRatingCount =
                    listingPublicData && listingPublicData.ratingCount
                        ? listingPublicData.ratingCount
                        : 0;
                var currentTotalRatingAccumulated =
                    listingPublicData && listingPublicData.ratingTotal
                        ? listingPublicData.ratingTotal
                        : 0;

                var ratingCount = currentRatingCount + 1;
                var ratingTotalAccumulated =
                    Number(currentTotalRatingAccumulated) +
                    Number(reviewRating);
                var ratingScore =
                    Number(ratingTotalAccumulated) / Number(ratingCount);

                const listingReviewData = {
                    listingId: listing.id.uuid,
                    publicData: {
                        ratingCount: ratingCount,
                        ratingTotal: ratingTotalAccumulated,
                        ratingScore: ratingScore * 10, // need to save it in integer form because sharetribe use long type for the sorting filtration and cannot read the decimal value
                    },
                };

                listingUpdateRating(listingReviewData)
                    .then(res => {
                        console.log(res);
                        // return dispatch(updateSuccess());
                    })
                    .catch(e => {
                        console.log(e);
                        // return dispatch(confirmError(storableError(e)));
                    });
            }

            return response;
        })
        .catch(e => {
            // If transaction transition is invalid, lets try another endpoint.
            if (isTransactionsTransitionInvalidTransition(e)) {
                return sendReviewAsSecond(id, params, role, dispatch, sdk);
            } else {
                dispatch(sendReviewError(storableError(e)));

                // Rethrow so the page can track whether the sending failed, and
                // keep the message in the form for a retry.
                throw e;
            }
        });
};

export const sendReview = (
    role,
    tx,
    reviewRating,
    reviewContent,
    currentListing
) => (dispatch, getState, sdk) => {
    const params = { reviewRating, reviewContent };
    console.log(reviewRating);

    const txStateOtherPartyFirst = txIsInFirstReviewBy(tx, role !== CUSTOMER);

    dispatch(sendReviewRequest());

    return txStateOtherPartyFirst
        ? sendReviewAsSecond(tx.id, params, role, dispatch, sdk)
        : sendReviewAsFirst(tx.id, params, role, dispatch, sdk, currentListing);
};

const isNonEmpty = value => {
    return typeof value === 'object' || Array.isArray(value)
        ? !isEmpty(value)
        : !!value;
};

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
    dispatch(fetchTransitionsRequest());

    return sdk.processTransitions
        .query({ transactionId: id })
        .then(res => {
            dispatch(fetchTransitionsSuccess(res.data.data));
        })
        .catch(e => {
            dispatch(fetchTransitionsError(storableError(e)));
        });
};

export const fetchTransactionLineItems = ({
    bookingData,
    listingId,
    isOwnListing,
}) => dispatch => {
    dispatch(fetchLineItemsRequest());
    transactionLineItems({ bookingData, listingId, isOwnListing })
        .then(response => {
            const lineItems = response.data;
            dispatch(fetchLineItemsSuccess(lineItems));
        })
        .catch(e => {
            dispatch(fetchLineItemsError(storableError(e)));
            log.error(e, 'fetching-line-items-failed', {
                listingId: listingId.uuid,
                bookingData: bookingData,
            });
        });
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => (dispatch, getState, sdk) => {
    const txId = new UUID(params.id);
    const state = getState().TransactionPage;
    const txRef = state.transactionRef;
    const txRole = params.transactionRole;

    // In case a transaction reference is found from a previous
    // data load -> clear the state. Otherwise keep the non-null
    // and non-empty values which may have been set from a previous page.
    const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
    dispatch(setInitialValues(initialValues));

    // Sale / order (i.e. transaction entity in API)
    return Promise.all([
        dispatch(fetchTransaction(txId, txRole)),
        dispatch(fetchMessages(txId, 1)),
        dispatch(fetchNextTransitions(txId)),
    ]).then(response => {
        dispatch({
            type: SCROLL_TO_BOTTOM,
        });
        return response;
    });
};
