import React from 'react';

import { useToasts, ToastProvider } from 'react-toast-notifications';

const ToastButton = ({ content, children, onClick, style, className }) => {
    const { addToast } = useToasts();
    return (
        <div
            onClick={() => {
                onClick();
                addToast(content, {
                    appearance: 'info',
                    autoDismiss: true,
                });
            }}
            className={className}>
            {children}
        </div>
    );
};

const Toast = props => {
    return (
        <ToastProvider>
            <ToastButton {...props} />
        </ToastProvider>
    );
};
export default Toast;
