import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import {
    FormattedMessage,
    injectIntl,
    intlShape,
    FormattedDate,
} from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldTextInput, SecondaryButton } from '../../components';
import { propTypes } from '../../util/types';
import moment from 'moment';
import { Modal } from '../../components';
import css from './SendMessageForm.module.css';
import { getUserTxRole } from '../../util/transaction';
import { useState } from 'react';
import { format } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { ensureListing } from '../../util/data';
import * as validators from '../../util/validators';

const BLUR_TIMEOUT_MS = 100;

const IconSendMessage = () => {
    return (
        <svg
            className={css.sendIcon}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg">
            <g
                className={css.strokeMatter}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round">
                <path d="M12.91 1L0 7.003l5.052 2.212z" />
                <path d="M10.75 11.686L5.042 9.222l7.928-8.198z" />
                <path d="M5.417 8.583v4.695l2.273-2.852" />
            </g>
        </svg>
    );
};

class SendMessageFormComponent extends Component {
    constructor(props) {
        super(props);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.blurTimeoutId = null;
    }

    handleFocus() {
        this.props.onFocus();
        window.clearTimeout(this.blurTimeoutId);
    }

    handleBlur() {
        // We only trigger a blur if another focus event doesn't come
        // within a timeout. This enables keeping the focus synced when
        // focus is switched between the message area and the submit
        // button.
        this.blurTimeoutId = window.setTimeout(() => {
            this.props.onBlur();
        }, BLUR_TIMEOUT_MS);
    }

    render() {
        const {
            stateData,
            cancelBooking,
            transaction,
            currentUser,
            intl,
        } = this.props;
        const userRole = getUserTxRole(currentUser.id, transaction);
        const { displayStart } =
            (transaction.booking && transaction.booking.attributes) || {};
        const durationLeftBeforeBookingStart = moment.duration(
            moment(displayStart).diff(moment(new Date()))
        );
        const isTimePassed = moment().isAfter(moment(displayStart));
        const isMoreThan48h =
            durationLeftBeforeBookingStart.asHours() > 48 ? true : false;
        const confirmCancelBookingMsg = intl.formatMessage({
            id: 'SendMessageForm.confirmCancelBooking',
        });

        return (
            <FinalForm
                {...this.props}
                render={formRenderProps => {
                    const {
                        rootClassName,
                        className,
                        messagePlaceholder,
                        handleSubmit,
                        inProgress,
                        sendMessageError,
                        invalid,
                        form,
                        formId,
                        values,
                    } = formRenderProps;

                    const classes = classNames(
                        rootClassName || css.root,
                        className
                    );
                    const submitInProgress = inProgress;
                    const submitDisabled = invalid || submitInProgress;
                    const emailPhoneWebsiteInvalidMessage = intl.formatMessage({
                        id: 'TransactionPanel.noEmailPhoneNoWebsiteMessage',
                    });
                    const [isTheModalOpen, setIsTheModalOpen] = useState(false);

                    const onManageDisableScrolling = (
                        componentId,
                        scrollingDisabled = true
                    ) => {
                    };

                    const startDate =
                        transaction.booking?.attributes?.displayStart;
                    const endDate = transaction.booking?.attributes?.displayEnd;

                    const listingAttributes = ensureListing(
                        this.props.transaction.listing
                    ).attributes;
                    const timeZone = listingAttributes.availabilityPlan
                        ? listingAttributes.availabilityPlan.timezone
                        : 'Etc/UTC';

                    const timeFormatOptions = {
                        hour: 'numeric',
                        minute: 'numeric',
                    };

                    const dateFormatOptions = {
                        month: 'short',
                        day: 'numeric',
                    };

                    const timeZoneMaybe = timeZone ? { timeZone } : null;

                    const emailPhoneWebsiteBlocker = validators.blockEmailFormat(
                        emailPhoneWebsiteInvalidMessage
                    );

                    const showInvalidMessage =
                        values?.message?.length && invalid;

                    return (
                        <>
                            <Form
                                className={classes}
                                onSubmit={values => handleSubmit(values, form)}>
                                <FieldTextInput
                                    inputRootClass={css.textarea}
                                    type="textarea"
                                    id={
                                        formId ? `${formId}.message` : 'message'
                                    }
                                    name="message"
                                    placeholder={messagePlaceholder}
                                    onFocus={this.handleFocus}
                                    onBlur={this.handleBlur}
                                    validate={validators.composeValidators(
                                        emailPhoneWebsiteBlocker
                                    )}
                                />
                                {showInvalidMessage && (
                                    <p
                                        className={
                                            css.noEmailPhoneNoWebsiteMessage
                                        }>
                                        {emailPhoneWebsiteInvalidMessage}
                                    </p>
                                )}
                                <div className={css.submitContainer}>
                                    <div className={css.errorContainer}>
                                        {sendMessageError ? (
                                            <p className={css.error}>
                                                <FormattedMessage id="SendMessageForm.sendFailed" />
                                            </p>
                                        ) : null}
                                        {stateData &&
                                        (stateData === 'accepted' ||
                                            (stateData === 'requested' &&
                                                userRole === 'customer')) &&
                                        !isTimePassed
                                            ? (function() {
                                                  var showCancelBooking = false;
                                                  var isCancelBeforeAcceptance = true;
                                                  if (isMoreThan48h) {
                                                      if (
                                                          [
                                                              'provider',
                                                              'customer',
                                                          ].includes(userRole)
                                                      ) {
                                                          showCancelBooking = true;
                                                      }
                                                  } else {
                                                      if (
                                                          userRole ===
                                                          'provider'
                                                      ) {
                                                          showCancelBooking = true;
                                                      }
                                                      if (
                                                          stateData ===
                                                              'requested' &&
                                                          userRole ===
                                                              'customer'
                                                      ) {
                                                          showCancelBooking = true;
                                                          isCancelBeforeAcceptance = true;
                                                      }
                                                  }
                                                  if (showCancelBooking) {
                                                      return (
                                                          <a
                                                              onClick={() => {
                                                                  setIsTheModalOpen(
                                                                      true
                                                                  );
                                                                  // if (typeof window !== 'undefined') {
                                                                  //   if (window.confirm(confirmCancelBookingMsg)) {
                                                                  //     window.sessionStorage.setItem('isCancelBeforeAcceptance', isCancelBeforeAcceptance);
                                                                  //     cancelBooking(transaction)
                                                                  //   }
                                                                  // }
                                                              }}
                                                              className={
                                                                  css.cancelBooking
                                                              }>
                                                              Cancel Booking
                                                          </a>
                                                      );
                                                  }
                                              })()
                                            : null}
                                    </div>
                                    <SecondaryButton
                                        rootClassName={css.submitButton}
                                        inProgress={submitInProgress}
                                        disabled={submitDisabled}
                                        onFocus={this.handleFocus}
                                        onBlur={this.handleBlur}>
                                        <IconSendMessage />
                                        <FormattedMessage id="SendMessageForm.sendMessage" />
                                    </SecondaryButton>
                                </div>
                            </Form>
                            <Modal
                                id="cancelTransaction"
                                containerClassName=""
                                contentClassName=""
                                isOpen={isTheModalOpen}
                                onClose={() => setIsTheModalOpen(false)}
                                onManageDisableScrolling={
                                    onManageDisableScrolling
                                }
                                usePortal
                                closeButtonMessage="">
                                <p className={css.modalTitle}>
                                    Are you sure you want to cancel this
                                    booking?
                                </p>
                                <p className={css.modalMessage}>
                                    <span>
                                        <FormattedDate
                                            value={startDate}
                                            {...dateFormatOptions}
                                            {...timeZoneMaybe}
                                        />
                                    </span>
                                    <br />
                                    <span>
                                        <FormattedDate
                                            value={startDate}
                                            {...timeFormatOptions}
                                            {...timeZoneMaybe}
                                        />{' '}
                                        -{' '}
                                        <FormattedDate
                                            value={endDate}
                                            {...timeFormatOptions}
                                            {...timeZoneMaybe}
                                        />
                                    </span>
                                </p>
                                <br />
                                <div className={css.buttonsContainer}>
                                    <span
                                        className={css.reconsider}
                                        onClick={() => {
                                            setIsTheModalOpen(false);
                                        }}>
                                        Reconsider
                                    </span>
                                    <button
                                        className={css.theCancelButton}
                                        onClick={() => {
                                            cancelBooking(transaction);
                                            setIsTheModalOpen(false);
                                        }}>
                                        Cancel Booking
                                    </button>
                                </div>
                            </Modal>
                        </>
                    );
                }}
            />
        );
    }
}

SendMessageFormComponent.defaultProps = {
    rootClassName: null,
    className: null,
    inProgress: false,
    messagePlaceholder: null,
    onFocus: () => null,
    onBlur: () => null,
    sendMessageError: null,
    stateData: null,
};

SendMessageFormComponent.propTypes = {
    rootClassName: string,
    className: string,
    inProgress: bool,

    messagePlaceholder: string,
    onSubmit: func.isRequired,
    onFocus: func,
    onBlur: func,
    sendMessageError: propTypes.error,

    // from injectIntl
    intl: intlShape.isRequired,
};

const SendMessageForm = compose(injectIntl)(SendMessageFormComponent);

SendMessageForm.displayName = 'SendMessageForm';

export default SendMessageForm;
