import React, { Component } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { Menu, MenuContent, MenuItem, MenuLabel } from '..';
import css from './SelectSingleFilterPopup.module.css';

const optionLabel = (options, key) => {
    const option = options.find(o => o.key === key);
    return option ? option.label : key;
};

const getQueryParamName = queryParamNames => {
    return Array.isArray(queryParamNames)
        ? queryParamNames[0]
        : queryParamNames;
};

class SelectSingleFilterPopup extends Component {
    constructor(props) {
        super(props);

        this.state = { isOpen: false };
        this.onToggleActive = this.onToggleActive.bind(this);
        this.selectOption = this.selectOption.bind(this);
    }

    onToggleActive(isOpen) {
        this.setState({ isOpen: isOpen });
    }

    selectOption(queryParamName, option) {
        this.setState({ isOpen: false });
        this.props.onSelect({ [queryParamName]: option });
    }

    render() {
        const {
            rootClassName,
            className,
            label,
            options,
            queryParamNames,
            initialValues,
            contentPlacementOffset,
        } = this.props;

        const queryParamName = getQueryParamName(queryParamNames);
        const initialValue =
            initialValues && initialValues[queryParamNames]
                ? initialValues[queryParamNames]
                : null;

        // resolve menu label text and class
        const menuLabel = initialValue
            ? optionLabel(options, initialValue)
            : label;
        const menuLabelClass = initialValue
            ? css.menuLabelSelected
            : css.menuLabel;

        const classes = classNames(rootClassName || css.root, className);

        return (
            <Menu
                className={classes}
                useArrow={false}
                contentPlacementOffset={contentPlacementOffset}
                onToggleActive={this.onToggleActive}
                isOpen={this.state.isOpen}>
                <MenuLabel className={menuLabelClass}>{menuLabel}</MenuLabel>
                <MenuContent className={css.menuContent}>
                    {options.map(option => {
                        // check if this option is selected
                        const selected = initialValue === option.key;
                        // menu item border class
                        const menuItemBorderClass = selected
                            ? css.menuItemBorderSelected
                            : css.menuItemBorder;

                        return (
                            <MenuItem key={option.key}>
                                <button
                                    className={css.menuItem}
                                    onClick={() =>
                                        this.selectOption(
                                            queryParamName,
                                            option.key
                                        )
                                    }>
                                    <span className={menuItemBorderClass} />
                                    {option.label}
                                </button>
                            </MenuItem>
                        );
                    })}
                    <MenuItem key={'clearLink'}>
                        <button
                            className={css.clearMenuItem}
                            onClick={() =>
                                this.selectOption(queryParamName, null)
                            }>
                            <FormattedMessage
                                id={'SelectSingleFilter.popupClear'}
                            />
                        </button>
                    </MenuItem>
                </MenuContent>
            </Menu>
        );
    }
}

SelectSingleFilterPopup.defaultProps = {
    rootClassName: null,
    className: null,
    initialValues: null,
    contentPlacementOffset: 0,
};

SelectSingleFilterPopup.propTypes = {
    rootClassName: string,
    className: string,
    queryParamNames: arrayOf(string).isRequired,
    label: node.isRequired,
    onSelect: func.isRequired,
    options: arrayOf(
        shape({
            key: string.isRequired,
            label: string.isRequired,
        })
    ).isRequired,
    initialValues: object,
    contentPlacementOffset: number,
};

export default SelectSingleFilterPopup;
