import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconReviewStar } from '../../components';
import IconReviewBall from '../IconReviewStar/IconReviewBall';
import { REVIEW_RATINGS } from '../../util/types';
import css from '../../containers/ListingPage/ListingPage.module.css';

const ReviewRating = props => {
    const { className, rootClassName, reviewStarClassName, rating } = props;
    const classes = classNames(rootClassName, className);

    const stars = REVIEW_RATINGS;
    return (
        <span className={classes}>
            <span className={css.ratingContainer}>
                {stars.map(star => (
                    <IconReviewBall
                        key={`star-${star}`}
                        className={reviewStarClassName}
                        isFilled={star <= rating}
                        size={2}
                    />
                ))}
            </span>
        </span>
    );
};

ReviewRating.defaultProps = {
    rootClassName: null,
    className: null,
    reviewStarClassName: null,
};

const { string, oneOf } = PropTypes;

ReviewRating.propTypes = {
    rating: oneOf(REVIEW_RATINGS).isRequired,
    reviewStartClassName: string,
    rootClassName: string,
    className: string,
};

export default ReviewRating;
