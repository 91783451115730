import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, string } from 'prop-types';
import {
    FormattedDate,
    FormattedMessage,
    injectIntl,
    intlShape,
} from '../../util/reactIntl';
import classNames from 'classnames';
import {
    TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
    txIsAccepted,
    txIsCanceled,
    txIsDeclined,
    txIsEnquired,
    txIsPaymentExpired,
    txIsPaymentPending,
    txIsRequested,
    txIsDelivered,
    txHasBeenDelivered,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
    ensureListing,
    ensureTransaction,
    ensureUser,
    userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
    AvatarLarge,
    BookingPanel,
    NamedLink,
    ReviewModal,
    UserDisplayName,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
    HEADING_ENQUIRED,
    HEADING_PAYMENT_PENDING,
    HEADING_PAYMENT_EXPIRED,
    HEADING_REQUESTED,
    HEADING_ACCEPTED,
    HEADING_DECLINED,
    HEADING_CANCELED,
    HEADING_DELIVERED,
} from './PanelHeading';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './TransactionPanel.module.css';
import { listingUpdateHours } from '../../util/api';
import EnquiryMessage from './EnquiryMessage';
import Toast from './Toast';
import copySolid from './copy-solid.svg';
const { UUID } = sdkTypes;

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
    const authorDisplayName = (
        <UserDisplayName user={currentProvider} intl={intl} />
    );
    const customerDisplayName = (
        <UserDisplayName user={currentCustomer} intl={intl} />
    );

    let otherUserDisplayName = '';
    let otherUserDisplayNameString = '';
    const currentUserIsCustomer =
        currentUser.id &&
        currentCustomer.id &&
        currentUser.id.uuid === currentCustomer.id.uuid;
    const currentUserIsProvider =
        currentUser.id &&
        currentProvider.id &&
        currentUser.id.uuid === currentProvider.id.uuid;

    if (currentUserIsCustomer) {
        otherUserDisplayName = authorDisplayName;
        otherUserDisplayNameString = userDisplayNameAsString(
            currentProvider,
            ''
        );
    } else if (currentUserIsProvider) {
        otherUserDisplayName = customerDisplayName;
        otherUserDisplayNameString = userDisplayNameAsString(
            currentCustomer,
            ''
        );
    }

    return {
        authorDisplayName,
        customerDisplayName,
        otherUserDisplayName,
        otherUserDisplayNameString,
    };
};

export class TransactionPanelComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendMessageFormFocused: false,
            isReviewModalOpen: false,
            reviewSubmitted: false,
            bookingsDatesArray: [],
            activeTab: null,
        };
        this.isMobSaf = false;
        this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

        this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
        this.onSubmitReview = this.onSubmitReview.bind(this);
        this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
        this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
        this.onMessageSubmit = this.onMessageSubmit.bind(this);
        this.scrollToMessage = this.scrollToMessage.bind(this);
        this.handleCancelBooking = this.handleCancelBooking.bind(this);
        this.addNewBooking = this.addNewBooking.bind(this);
        this.deleteBooking = this.deleteBooking.bind(this);
    }

    componentDidMount() {
        this.isMobSaf = isMobileSafari();
    }

    onOpenReviewModal() {
        this.setState({ isReviewModalOpen: true });
    }

    handleOnChangeChatTab(id) {
        this.setState({ activeTab: id });
        const _id = new UUID(id);
        this.props.onFetchMessages(_id, 1);
    }

    addNewBooking = values => {
        this.setState({
            bookingsDatesArray: [...this.state.bookingsDatesArray, values],
        });
    };

    deleteBooking = indexKey => {
        let _bookingsDatesArray = this.state.bookingsDatesArray;
        _bookingsDatesArray = _bookingsDatesArray.filter(
            (data, i) => i != indexKey
        );
        this.setState({ bookingsDatesArray: _bookingsDatesArray });
    };

    // sending review with ratings
    onSubmitReview(values) {
        const {
            onSendReview,
            transaction,
            transactions,
            transactionRole,
        } = this.props;
        const currentTransaction = ensureTransaction(transaction);

        const { reviewRating, reviewContent } = values;
        const rating = Number.parseInt(reviewRating, 10);

        const currentTransactions = transactions.map(_t =>
            ensureTransaction(_t)
        );

        const currentListing = ensureListing(currentTransaction.listing);

        // include listings details to update the rating component data

        onSendReview(
            transactionRole,
            currentTransaction,
            rating,
            reviewContent,
            currentListing
        )
            .then(r =>
                this.setState({
                    isReviewModalOpen: false,
                    reviewSubmitted: true,
                })
            )
            .catch(e => {
                // Do nothing.
            });
    }

    onSendMessageFormFocus() {
        this.setState({ sendMessageFormFocused: true });
        if (this.isMobSaf) {
            // Scroll to bottom
            window.scroll({
                top: document.body.scrollHeight,
                left: 0,
                behavior: 'smooth',
            });
        }
    }

    onSendMessageFormBlur() {
        this.setState({ sendMessageFormFocused: false });
    }

    onMessageSubmit(values, form) {
        const message = values.message ? values.message.trim() : null;

        var { onSendMessage, transactions, transaction } = this.props;
        const currentId =
            this.state.activeTab || transactions.length > 0
                ? transactions[0].id.uuid
                : transaction.id.uuid;
        transaction =
            transactions.length > 0
                ? transactions.filter(t => t.id.uuid === currentId)[0]
                : transaction;
        transaction.id = new UUID(transaction.id.uuid);

        const ensuredTransaction = ensureTransaction(transaction);
        if (!message) {
            return;
        }
        onSendMessage(ensuredTransaction.id, message)
            .then(messageId => {
                form.reset();
                this.scrollToMessage(messageId);
            })
            .catch(e => {
                // Ignore, Redux handles the error
            });
    }

    scrollToMessage(messageId) {
        const selector = `#msg-${messageId.uuid}`;
        const el = document.querySelector(selector);
        if (el) {
            el.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });
        }
    }

    handleCancelBooking() {
        // const currentTransaction = ensureTransaction(this.props.transaction);
        const currentTransaction = this.props.currentTxTab
            ? ensureTransaction(this.props.currentTxTab)
            : {};
        this.props.cancelBooking(currentTransaction);
    }

    render() {
        const {
            rootClassName,
            className,
            currentUser,
            transaction,
            transactions,
            totalMessagePages,
            oldestMessagePageFetched,
            messages,
            initialMessageFailed,
            savePaymentMethodFailed,
            fetchMessagesInProgress,
            fetchMessagesError,
            sendMessageInProgress,
            sendMessageError,
            sendReviewInProgress,
            sendReviewError,
            onFetchTimeSlots,
            onManageDisableScrolling,
            onShowMoreMessages,
            transactionRole,
            intl,
            onAcceptSale,
            onDeclineSale,
            acceptInProgress,
            declineInProgress,
            acceptSaleError,
            declineSaleError,
            onSubmitBookingRequest,
            monthlyTimeSlots,
            nextTransitions,
            onFetchTransactionLineItems,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            currentTxTab,
            onStoreBookingDates,
        } = this.props;

        const currentTransaction = ensureTransaction(transaction);
        const currentTransactions = transactions.map(_t =>
            ensureTransaction(_t)
        );

        const currentListing = ensureListing(currentTransaction.listing);

        const currentProvider = ensureUser(currentTransaction.provider);
        const currentCustomer = ensureUser(currentTransaction.customer);
        const isCustomer = transactionRole === 'customer';
        const isProvider = transactionRole === 'provider';

        const listingLoaded = !!currentListing.id;
        const listingDeleted =
            listingLoaded && currentListing.attributes.deleted;
        const iscustomerLoaded = !!currentCustomer.id;
        const isCustomerBanned =
            iscustomerLoaded && currentCustomer.attributes.banned;
        const isCustomerDeleted =
            iscustomerLoaded && currentCustomer.attributes.deleted;
        const isProviderLoaded = !!currentProvider.id;
        const isProviderBanned =
            isProviderLoaded && currentProvider.attributes.banned;
        const isProviderDeleted =
            isProviderLoaded && currentProvider.attributes.deleted;

        const rowNotificationDot = (
            <div className={css.rowNotificationDot}>
                <div className={css.notificationDot} />
            </div>
        );

        const stateDataFn = tx => {
            if (txIsEnquired(tx)) {
                const transitions = Array.isArray(nextTransitions)
                    ? nextTransitions.map(transition => {
                        return transition.attributes.name;
                    })
                    : [];
                const hasCorrectNextTransition =
                    transitions.length > 0 &&
                    transitions.includes(
                        TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
                    );
                return {
                    headingState: HEADING_ENQUIRED,
                    showBookingPanel:
                        isCustomer &&
                        !isProviderBanned &&
                        hasCorrectNextTransition,
                };
            } else if (txIsPaymentPending(tx)) {
                return {
                    headingState: HEADING_PAYMENT_PENDING,
                    showDetailCardHeadings: isCustomer,
                };
            } else if (txIsPaymentExpired(tx)) {
                return {
                    headingState: HEADING_PAYMENT_EXPIRED,
                    showDetailCardHeadings: isCustomer,
                };
            } else if (txIsRequested(tx)) {
                return {
                    headingState: HEADING_REQUESTED,
                    showDetailCardHeadings: isCustomer,
                    showSaleButtons: isProvider && !isCustomerBanned,
                    showCancel: isCustomer && !isCustomerBanned,
                };
            } else if (txIsAccepted(tx)) {
                return {
                    headingState: HEADING_ACCEPTED,
                    showDetailCardHeadings: isCustomer,
                    showAddress: isCustomer,
                    showCancel: (isProvider || isCustomer) && !isCustomerBanned,
                };
            } else if (txIsDeclined(tx)) {
                return {
                    headingState: HEADING_DECLINED,
                    showDetailCardHeadings: isCustomer,
                };
            } else if (txIsCanceled(tx)) {
                return {
                    headingState: HEADING_CANCELED,
                    showDetailCardHeadings: isCustomer,
                };
            } else if (txIsDelivered(tx)) {
                return {
                    shouldShowReviewButton: true,
                    headingState: HEADING_DELIVERED,
                    showDetailCardHeadings: isCustomer,
                    showAddress: isCustomer,
                }
            } else if (txHasBeenDelivered(tx)) {
                return {
                    headingState: HEADING_DELIVERED,
                    showDetailCardHeadings: isCustomer,
                    showAddress: isCustomer,
                };
            } else {
                return { headingState: 'unknown' };
            }
        };
        // const stateData = stateDataFn(currentTransaction);
        const stateData = currentTxTab ? stateDataFn(currentTxTab) : {};

        const deletedListingTitle = intl.formatMessage({
            id: 'TransactionPanel.deletedListingTitle',
        });

        const {
            authorDisplayName,
            customerDisplayName,
            otherUserDisplayName,
            otherUserDisplayNameString,
        } = displayNames(currentUser, currentProvider, currentCustomer, intl);

        const { publicData, geolocation } = currentListing.attributes;
        const location =
            publicData && publicData.location ? publicData.location : {};
        const listingTitle = currentListing.attributes.deleted
            ? deletedListingTitle
            : currentListing.attributes.title;

        const unitType = config.bookingUnitType;
        const isNightly = unitType === LINE_ITEM_NIGHT;
        const isDaily = unitType === LINE_ITEM_DAY;

        const unitTranslationKey = isNightly
            ? 'TransactionPanel.perNight'
            : isDaily
                ? 'TransactionPanel.perDay'
                : 'TransactionPanel.perUnit';

        const price = currentListing.attributes.price;
        const bookingSubTitle = price
            ? `${formatMoney(intl, price)} ${intl.formatMessage({
                id: unitTranslationKey,
            })}`
            : '';

        const firstImage =
            currentListing.images && currentListing.images.length > 0
                ? currentListing.images[0]
                : null;

        var quantity = null; // in hours

        // Find correct line-item for given unitType prop.
        // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
        // These are defined in '../../util/types';
        const unitPurchase = transaction.attributes.lineItems.find(
            item => item.code === unitType && !item.reversal
        );
        if (transactions && transactions.length) {
            const unitPurchases = transactions.map(_t =>
                _t.attributes.lineItems.find(
                    item => item.code === unitType && !item.reversal
                )
            );

            quantity = unitPurchases
                .map(p => Number(p.quantity))
                .reduce((a, b) => a + b)
                .toString();
        } else {
            quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
        }

        function acceptSaleHandler(i, transactions, currentCustomer) {
            const currentTx = transactions[i];
            const isLastTx = i == transactions.length - 1 ? true : false;
            if (
                currentTx.attributes.lastTransition ===
                'transition/confirm-payment'
            ) {
                onAcceptSale(
                    currentTx.id,
                    currentTx.listing,
                    quantity,
                    transactions,
                    isLastTx,
                    currentCustomer
                ).then(res => {
                    if (i == 0) {
                        // add hours
                        listingUpdateHours({
                            listingId: currentTx && currentTx.listing.id,
                            publicData: { hoursPlayed: quantity },
                        });
                    }
                    if (i < transactions.length) {
                        acceptSaleHandler(i + 1, transactions, currentCustomer);
                    }
                });
            } else {
                if (i < transactions.length) {
                    acceptSaleHandler(i + 1, transactions, currentCustomer);
                }
            }
        }

        const saleButtons = (
            <SaleActionButtonsMaybe
                showButtons={stateData.showSaleButtons}
                showReview={stateData.shouldShowReviewButton}
                showCancel={stateData.showCancel}
                stateData={stateData.headingState}
                onOpenReviewModal={this.onOpenReviewModal}
                acceptInProgress={acceptInProgress}
                declineInProgress={declineInProgress}
                acceptSaleError={acceptSaleError}
                declineSaleError={declineSaleError}
                // onAcceptSale={() => onAcceptSale(currentTransaction.id, currentTransaction.listing, quantity, transactions)}
                onAcceptSale={() =>
                    acceptSaleHandler(0, transactions, currentCustomer)
                }
                onDeclineSale={() => onDeclineSale(currentTransaction.id)}
                onCancel={this.handleCancelBooking}
                transaction={currentTxTab}
                currentUser={currentUser}
            />
        );

        const showSendMessageForm =
            !isCustomerBanned &&
            !isCustomerDeleted &&
            !isProviderBanned &&
            !isProviderDeleted;

        const sendMessagePlaceholder = intl.formatMessage(
            { id: 'TransactionPanel.sendMessagePlaceholder' },
            { name: otherUserDisplayNameString }
        );

        const sendingMessageNotAllowed = intl.formatMessage({
            id: 'TransactionPanel.sendingMessageNotAllowed',
        });

        const paymentMethodsPageLink = (
            <NamedLink name="PaymentMethodsPage">
                <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
            </NamedLink>
        );

        const bookings = transactions
            ? transactions
                .map(_t => {
                    const isSaleNotification =
                        isProvider && txIsRequested(_t);
                    return {
                        ..._t.booking,
                        transactionId: _t.id,
                        isSaleNotification,
                    };
                })
                .sort((a, b) => a?.attributes?.start - b?.attributes?.start) //sorting by upcoming date first
            : [];

        // get time data
        const loaded =
            transaction &&
            transaction.id &&
            transaction.booking &&
            transaction.booking.id;
        const listingAttributes = ensureListing(transaction.listing).attributes;
        const timeZone =
            loaded && listingAttributes.availabilityPlan
                ? listingAttributes.availabilityPlan.timezone
                : 'Etc/UTC';
        let toast = <div></div>;
        if (transaction?.booking) {
            const {
                start,
                end,
                displayStart,
                displayEnd,
            } = transaction?.booking?.attributes;

            const localStartDate = displayStart || start;
            const localEndDateRaw = displayEnd || end;

            const date = new Date(localStartDate).toLocaleString('en-US', {
                timeZone,
                weekday: 'short',
                month: 'short',
                day: 'numeric',
                year: '2-digit',
            });
            const startTime = new Date(localStartDate).toLocaleString('en-US', {
                timeZone,
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            });

            const endTime = new Date(localEndDateRaw).toLocaleString('en-US', {
                timeZone,
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            });

            toast = (
                <Toast
                    onClick={() =>
                        navigator.clipboard.writeText(
                            `Court Name: ${listingTitle}
                            Date: ${date}
                            Time: ${startTime} - ${endTime}
                            Address: ${location?.address}
                            ${hrefToGoogleMaps ? `Location: ${hrefToGoogleMaps}` : ''}`
                        )
                    }
                    content="Booking Details copied to clipboard! Paste & share with your other players"
                    className={css.copyIcon}>
                    <img src={copySolid} fill="#059669" /> Copy booking details
                </Toast>
            );
        }

        const { lat, lng } = geolocation || {};
        const hrefToGoogleMaps = geolocation
            ? `https://maps.google.com/?q=${lat},${lng}`
            : location?.address
                ? `https://maps.google.com/?q=${encodeURIComponent(
                    location?.address
                )}`
                : null;

        const classes = classNames(rootClassName || css.root, className);
        return (
            <div className={classes}>
                <div className={css.container}>
                    <div className={css.txInfo}>
                        <DetailCardImage
                            rootClassName={css.imageWrapperMobile}
                            avatarWrapperClassName={css.avatarWrapperMobile}
                            listingTitle={listingTitle}
                            image={firstImage}
                            provider={currentProvider}
                            isCustomer={isCustomer}
                            listingId={
                                currentListing.id && currentListing.id.uuid
                            }
                            listingDeleted={listingDeleted}
                        />
                        <PanelHeading
                            panelHeadingState={stateData.headingState}
                            transactionRole={transactionRole}
                            providerName={authorDisplayName}
                            customerName={customerDisplayName}
                            isCustomerBanned={isCustomerBanned}
                            listingId={
                                currentListing.id && currentListing.id.uuid
                            }
                            listingTitle={listingTitle}
                            listingDeleted={listingDeleted}
                        />
                        <AddressLinkMaybe
                            className={css.addressExtra}
                            showHeader={true}
                            location={location}
                            geolocation={geolocation}
                            showAddress={stateData.showAddress}
                            isCustomer={isCustomer}
                            toast={toast}
                        />
                        
                        <div className={css.bookingDetailsMobile}>
                            <BreakdownMaybe
                                transaction={currentTransaction}
                                transactions={currentTransactions}
                                transactionRole={transactionRole}
                            />
                        </div>

                        {savePaymentMethodFailed ? (
                            <p className={css.genericError}>
                                <FormattedMessage
                                    id="TransactionPanel.savePaymentMethodFailed"
                                    values={{ paymentMethodsPageLink }}
                                />
                            </p>
                        ) : null}

                        <div className={css.bookingTitle}>
                            {bookings && bookings.length ? 'Booking Dates' : ''}
                        </div>
                        <div style={{ display: 'flex' }}>
                            {React.Children.toArray(
                                bookings.map((_b, bIndex) => {
                                    const style = {
                                        marginRight: '0.5rem',
                                        cursor: 'pointer',
                                        border: 'none',
                                    };
                                    const id = _b.transactionId.uuid;
                                    const { activeTab } = this.state;
                                    if (
                                        activeTab === id ||
                                        (!activeTab &&
                                            this.props.currentTxTab?.id.uuid ===
                                            id)
                                    ) {
                                        style.borderBottom = '3px solid';
                                    } else {
                                        style.borderBottom = 'none';
                                    }
                                    const dateSplit = new Date(
                                        _b.attributes.displayStart
                                    )
                                        .toString()
                                        .split(' ');
                                    const date = `${dateSplit[0]}, ${dateSplit[1]} ${dateSplit[2]}`;

                                    return (
                                        <button
                                            onClick={() =>
                                                this.handleOnChangeChatTab(id)
                                            }
                                            style={style}>
                                            <span
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                {_b.isSaleNotification &&
                                                    rowNotificationDot}{' '}
                                                {date}
                                            </span>
                                        </button>
                                    );
                                })
                            )}
                        </div>

                        {currentTxTab ? (
                            <FeedSection
                                rootClassName={css.feedContainer}
                                // currentTransaction={currentTransaction}
                                currentTransaction={currentTxTab}
                                currentUser={currentUser}
                                fetchMessagesError={fetchMessagesError}
                                fetchMessagesInProgress={
                                    fetchMessagesInProgress
                                }
                                initialMessageFailed={initialMessageFailed}
                                messages={messages}
                                oldestMessagePageFetched={
                                    oldestMessagePageFetched
                                }
                                onOpenReviewModal={this.onOpenReviewModal}
                                // onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
                                onShowMoreMessages={() =>
                                    onShowMoreMessages(currentTxTab.id)
                                }
                                totalMessagePages={totalMessagePages}
                                showSendMessageForm={showSendMessageForm}
                            />
                        ) : null}

                        {showSendMessageForm && currentTxTab ? (
                            <SendMessageForm
                                formId={this.sendMessageFormName}
                                rootClassName={css.sendMessageForm}
                                messagePlaceholder={sendMessagePlaceholder}
                                inProgress={sendMessageInProgress}
                                sendMessageError={sendMessageError}
                                onFocus={this.onSendMessageFormFocus}
                                onBlur={this.onSendMessageFormBlur}
                                onSubmit={this.onMessageSubmit}
                                stateData={stateData.headingState}
                                cancelBooking={this.handleCancelBooking}
                                // transaction={currentTransaction}
                                transaction={currentTxTab}
                                currentUser={currentUser}
                            />
                        ) : (
                            <div className={css.sendingMessageNotAllowed}>
                                {sendingMessageNotAllowed}
                            </div>
                        )}

                        {stateData.showSaleButtons || stateData.showCancel ? (
                            <div className={css.mobileActionButtons}>
                                {saleButtons}
                            </div>
                        ) : null}
                    </div>

                    <div className={css.asideDesktop}>
                        <div className={css.detailCard}>
                            <DetailCardImage
                                avatarWrapperClassName={
                                    css.avatarWrapperDesktop
                                }
                                listingTitle={listingTitle}
                                image={firstImage}
                                provider={currentProvider}
                                isCustomer={isCustomer}
                                listingId={
                                    currentListing.id && currentListing.id.uuid
                                }
                                listingDeleted={listingDeleted}
                            />

                            <DetailCardHeadingsMaybe
                                showDetailCardHeadings={
                                    stateData.showDetailCardHeadings
                                }
                                listingTitle={listingTitle}
                                subTitle={bookingSubTitle}
                                location={location}
                                geolocation={geolocation}
                                showAddress={stateData.showAddress}
                            />
                            {stateData.showBookingPanel ? (
                                <EnquiryMessage
                                    listingId={
                                        currentListing.id &&
                                        currentListing.id.uuid
                                    }
                                    listingTitle={listingTitle}
                                    listingDeleted={listingDeleted}
                                />
                            ) : // <BookingPanel
                                //   className={css.bookingPanel}
                                //   titleClassName={css.bookingTitle}
                                //   unitType={unitType}
                                //   isOwnListing={false}
                                //   listing={currentListing}
                                //   title={listingTitle}
                                //   subTitle={bookingSubTitle}
                                //   authorDisplayName={authorDisplayName}
                                //   onSubmit={onSubmitBookingRequest}
                                //   onManageDisableScrolling={onManageDisableScrolling}
                                //   monthlyTimeSlots={monthlyTimeSlots}
                                //   onFetchTimeSlots={onFetchTimeSlots}
                                //   onFetchTransactionLineItems={onFetchTransactionLineItems}
                                //   lineItems={lineItems}
                                //   fetchLineItemsInProgress={fetchLineItemsInProgress}
                                //   fetchLineItemsError={fetchLineItemsError}
                                //   addNewBooking={this.addNewBooking}
                                //   deleteBooking={this.deleteBooking}
                                //   // bookingDatesArray={bookingDatesArray}
                                //   bookingDatesArray={this.state.bookingsDatesArray}
                                //   storeBookingDatesArray={onStoreBookingDates}
                                //   // removeBookingDates={onRemoveBookingDate}
                                //   removeBookingDates={this.deleteBooking}
                                // />
                                null}
                            <BreakdownMaybe
                                className={css.breakdownContainer}
                                transaction={currentTransaction}
                                transactions={currentTransactions}
                                transactionRole={transactionRole}
                            />

                            {stateData.showSaleButtons ||
                                stateData.shouldShowReviewButton || stateData.showCancel ? (
                                <div className={css.desktopActionButtons}>
                                    {saleButtons}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <ReviewModal
                    id="ReviewOrderModal"
                    isOpen={this.state.isReviewModalOpen}
                    onCloseModal={() =>
                        this.setState({ isReviewModalOpen: false })
                    }
                    onManageDisableScrolling={onManageDisableScrolling}
                    onSubmitReview={this.onSubmitReview}
                    revieweeName={otherUserDisplayName}
                    reviewSent={this.state.reviewSubmitted}
                    sendReviewInProgress={sendReviewInProgress}
                    sendReviewError={sendReviewError}
                />
            </div>
        );
    }
}

TransactionPanelComponent.defaultProps = {
    rootClassName: null,
    className: null,
    currentUser: null,
    acceptSaleError: null,
    declineSaleError: null,
    fetchMessagesError: null,
    initialMessageFailed: false,
    savePaymentMethodFailed: false,
    sendMessageError: null,
    sendReviewError: null,
    monthlyTimeSlots: null,
    nextTransitions: null,
    lineItems: null,
    fetchLineItemsError: null,
    currentTxTab: null,
};

TransactionPanelComponent.propTypes = {
    rootClassName: string,
    className: string,

    currentUser: propTypes.currentUser,
    transaction: propTypes.transaction.isRequired,
    transactions: array,
    totalMessagePages: number.isRequired,
    oldestMessagePageFetched: number.isRequired,
    messages: arrayOf(propTypes.message).isRequired,
    initialMessageFailed: bool,
    savePaymentMethodFailed: bool,
    fetchMessagesInProgress: bool.isRequired,
    fetchMessagesError: propTypes.error,
    sendMessageInProgress: bool.isRequired,
    sendMessageError: propTypes.error,
    sendReviewInProgress: bool.isRequired,
    sendReviewError: propTypes.error,
    onFetchTimeSlots: func.isRequired,
    onManageDisableScrolling: func.isRequired,
    onShowMoreMessages: func.isRequired,
    onSendMessage: func.isRequired,
    onSendReview: func.isRequired,
    onSubmitBookingRequest: func.isRequired,
    monthlyTimeSlots: object,
    nextTransitions: array,
    onFetchMessages: func,
    // Sale related props
    onAcceptSale: func.isRequired,
    onDeclineSale: func.isRequired,
    acceptInProgress: bool.isRequired,
    declineInProgress: bool.isRequired,
    acceptSaleError: propTypes.error,
    declineSaleError: propTypes.error,

    // line items
    onFetchTransactionLineItems: func.isRequired,
    lineItems: array,
    fetchLineItemsInProgress: bool.isRequired,
    fetchLineItemsError: propTypes.error,

    // from injectIntl
    intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
