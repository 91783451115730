import { storableError } from '../../util/errors';
import pick from 'lodash/pick';

export const SET_INITIAL_VALUES = 'app/BOOKINGSPage/SET_INITIAL_VALUES';
export const FETCH_BOOKINGS_REQUEST = 'app/BOOKINGSPage/FETCH_BOOKINGS_REQUEST';
export const FETCH_BOOKINGS_SUCCESS = 'app/BOOKINGSPage/FETCH_BOOKINGS_SUCCESS';
export const FETCH_BOOKINGS_ERROR = 'app/BOOKINGSPage/FETCH_BOOKINGS_ERROR';

// ================ Reducer ================ //
const initialState = {
    fetchBookingsInProgress: false,
    fetchBookingsError: null,
    bookingsApiRes: null,
};

export default function userCalendarPageReducer(
    state = initialState,
    action = {}
) {
    const { type, payload } = action;
    switch (type) {
        case SET_INITIAL_VALUES:
            return { ...initialState, ...payload };

        case FETCH_BOOKINGS_REQUEST:
            return {
                ...state,
                fetchBookingsInProgress: true,
                fetchBookingsError: null,
            };
        case FETCH_BOOKINGS_SUCCESS: {
            return {
                ...state,
                fetchBookingsInProgress: false,
                bookingsApiRes: payload,
            };
        }
        case FETCH_BOOKINGS_ERROR:
            console.error(payload); // eslint-disable-line
            return {
                ...state,
                fetchBookingsInProgress: false,
                fetchBookingsError: payload,
            };

        default:
            return state;
    }
}

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
    type: SET_INITIAL_VALUES,
    payload: pick(initialValues, Object.keys(initialState)),
});

const fetchBookingsRequest = () => ({ type: FETCH_BOOKINGS_REQUEST });
const fetchBookingsSuccess = response => ({
    type: FETCH_BOOKINGS_SUCCESS,
    payload: response,
});

const fetchBookingsError = e => ({
    type: FETCH_BOOKINGS_ERROR,
    error: true,
    payload: e,
});

// ================ Thunks ================ //

export const fetchBookings = date => (dispatch, getState, sdk) => {
    dispatch(fetchBookingsRequest());

    const curDate = date ? new Date(date) : new Date();
    const firstDateOfCurMonth = new Date(
        curDate.getFullYear(),
        curDate.getMonth(),
        1
    );
    const lastDateOfCurMonth = new Date(
        curDate.getFullYear(),
        curDate.getMonth() + 1,
        0
    );

    const start = firstDateOfCurMonth.toISOString();
    const end = lastDateOfCurMonth.toISOString();

    const apiBookingQuery = {
        only: 'sale',
        start,
        end,
        include: [
            'provider',
            'customer',
            'booking',
            'listing',
            'booking.attributes.state',
            'booking.attributes.start',
            'booking.attributes.end',
        ],
        'fields.transaction': ['metadata'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    };

    return sdk.transactions
        .query(apiBookingQuery)
        .then(response => {
            dispatch(fetchBookingsSuccess(response));
        })
        .catch(e => {
            console.error(e);
            dispatch(fetchBookingsError(storableError(e)));
            throw e;
        });
};

export const loadData = params => (dispatch, getState, sdk) => {
    return Promise.all([dispatch(fetchBookings())]);
};
