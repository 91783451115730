import { bool } from 'prop-types';
import React from 'react';

import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import {
    txIsCanceled,
    txIsDelivered,
    txIsDeclined,
} from '../../util/transaction';
import css from './BookingBreakdown.module.css';

const { types } = require('sharetribe-flex-sdk');

const { Money } = types;

export const LINE_ITEM_PROVIDER_COMMISSION = 'line-item/provider-commission';

const LineItemUnitPrice = props => {
    const { transaction, transactions, isProvider, intl } = props;

    let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
    if (txIsDelivered(transaction)) {
        providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
    } else if (txIsDeclined(transaction)) {
        providerTotalMessageId = 'BookingBreakdown.providerTotalDeclined';
    } else if (txIsCanceled(transaction)) {
        providerTotalMessageId = 'BookingBreakdown.providerTotalCanceled';
    }

    const totalLabel = isProvider ? (
        <FormattedMessage id={providerTotalMessageId} />
    ) : (
        <FormattedMessage id="BookingBreakdown.total" />
    );

    let totalPrice = null;
    if (transactions && transactions.length) {
        const pays = transactions.map(
            t => t.attributes[isProvider ? 'payoutTotal' : 'payinTotal']
        );

        const total = transactions
            .map(_t =>
                // sum lineitems for each tx
                _t.attributes.lineItems
                    .filter(li =>
                        isProvider
                            ? li.code !== 'line-item/booking-fee'
                            : li.code !== 'line-item/provider-commission'
                    )
                    .map(_li => _li.lineTotal.amount)
                    .reduce((a, b) => a + b, 0)
            )
            // sum for all tx
            .reduce((a, b) => a + b, 0);

        totalPrice = new Money(total, pays[0].currency);
    } else {
        totalPrice = isProvider
            ? transaction.attributes.payoutTotal
            : transaction.attributes.payinTotal;
    }
    const formattedTotalPrice = formatMoney(intl, totalPrice);

    return (
        <>
            <hr className={css.totalDivider} />
            <div className={css.lineItemTotal}>
                <div className={css.totalLabel}>{totalLabel}</div>
                <div className={css.totalPrice}>{formattedTotalPrice}</div>
            </div>
        </>
    );
};

LineItemUnitPrice.propTypes = {
    transaction: propTypes.transaction.isRequired,
    isProvider: bool.isRequired,
    intl: intlShape.isRequired,
};

export default LineItemUnitPrice;
