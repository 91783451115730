import pick from 'lodash/pick';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { initiatePrivileged, transactionLineItems } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import {
    findNextBoundary,
    nextMonthFn,
    monthIdStringInTimeZone,
} from '../../util/dates';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import {
    LISTING_PAGE_DRAFT_VARIANT,
    LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import {
    fetchCurrentUser,
    fetchCurrentUserHasOrdersSuccess,
} from '../../ducks/user.duck';
import { v4 as uuid } from 'uuid';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_NEARBY_LISTINGS_SUCCESS =
    'app/ListingPage/FETCH_NEARBY_LISTINGS_SUCCESS';

export const FETCH_TIME_SLOTS_REQUEST =
    'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS =
    'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST =
    'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS =
    'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const STORE_BOOKING_DATES_REQUEST =
    'app/ListingPage/STORE_BOOKING_DATES_REQUEST';
export const STORE_BOOKING_DATES_SUCCESS =
    'app/ListingPage/STORE_BOOKING_DATES_SUCCESS';
export const STORE_BOOKING_DATES_ERROR =
    'app/ListingPage/STORE_BOOKING_DATES_ERROR';

export const REMOVE_BOOKING_DATE_REQUEST =
    'app/ListingPage/REMOVE_BOOKING_DATE_REQUEST';
export const REMOVE_BOOKING_DATE_SUCCESS =
    'app/ListingPage/REMOVE_BOOKING_DATE_SUCCESS';
export const REMOVE_BOOKING_DATE_ERROR =
    'app/ListingPage/REMOVE_BOOKING_DATE_ERROR';

// ================ Reducer ================ //

const initialState = {
    id: null,
    showListingError: null,
    reviews: [],
    nearbyListings: [],
    fetchReviewsError: null,
    monthlyTimeSlots: {
        // '2019-12': {
        //   timeSlots: [],
        //   fetchTimeSlotsError: null,
        //   fetchTimeSlotsInProgress: null,
        // },
    },
    bookingDatesArray: [],
    newlist: [],
    lineItems: [],
    fetchLineItemsInProgress: false,
    fetchLineItemsError: null,
    sendEnquiryInProgress: false,
    sendEnquiryError: null,
    enquiryModalOpenForListingId: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case SET_INITIAL_VALUES:
            return { ...initialState, ...payload };

        case SHOW_LISTING_REQUEST:
            return { ...state, id: payload.id, showListingError: null };
        case SHOW_LISTING_ERROR:
            return { ...state, showListingError: payload };

        case FETCH_REVIEWS_REQUEST:
            return { ...state, fetchReviewsError: null };
        case FETCH_REVIEWS_SUCCESS:
            return { ...state, reviews: payload };
        case FETCH_REVIEWS_ERROR:
            return { ...state, fetchReviewsError: payload };

        case FETCH_NEARBY_LISTINGS_SUCCESS:
            return { ...state, nearbyListings: payload };

        case FETCH_TIME_SLOTS_REQUEST: {
            const monthlyTimeSlots = {
                ...state.monthlyTimeSlots,
                [payload]: {
                    ...state.monthlyTimeSlots[payload],
                    fetchTimeSlotsError: null,
                    fetchTimeSlotsInProgress: true,
                },
            };
            return { ...state, monthlyTimeSlots };
        }
        case FETCH_TIME_SLOTS_SUCCESS: {
            const monthId = payload.monthId;
            const monthlyTimeSlots = {
                ...state.monthlyTimeSlots,
                [monthId]: {
                    ...state.monthlyTimeSlots[monthId],
                    fetchTimeSlotsInProgress: false,
                    timeSlots: payload.timeSlots,
                },
            };
            return { ...state, monthlyTimeSlots };
        }
        case FETCH_TIME_SLOTS_ERROR: {
            const monthId = payload.monthId;
            const monthlyTimeSlots = {
                ...state.monthlyTimeSlots,
                [monthId]: {
                    ...state.monthlyTimeSlots[monthId],
                    fetchTimeSlotsInProgress: false,
                    fetchTimeSlotsError: payload.error,
                },
            };
            return { ...state, monthlyTimeSlots };
        }

        case FETCH_LINE_ITEMS_REQUEST:
            return {
                ...state,
                fetchLineItemsInProgress: true,
                fetchLineItemsError: null,
            };
        case FETCH_LINE_ITEMS_SUCCESS:
            return {
                ...state,
                fetchLineItemsInProgress: false,
                lineItems: payload,
            };
        case FETCH_LINE_ITEMS_ERROR:
            return {
                ...state,
                fetchLineItemsInProgress: false,
                fetchLineItemsError: payload,
            };

        case SEND_ENQUIRY_REQUEST:
            return {
                ...state,
                sendEnquiryInProgress: true,
                sendEnquiryError: null,
            };
        case SEND_ENQUIRY_SUCCESS:
            return { ...state, sendEnquiryInProgress: false };
        case SEND_ENQUIRY_ERROR:
            return {
                ...state,
                sendEnquiryInProgress: false,
                sendEnquiryError: payload,
            };

        case STORE_BOOKING_DATES_REQUEST:
            return { ...state, storeDatesArrayInProgress: true };
        case STORE_BOOKING_DATES_SUCCESS:
            const newlist = [...state.bookingDatesArray, payload];
            return {
                ...state,
                storeDatesArrayInProgress: false,
                bookingDatesArray: newlist,
            };
        case STORE_BOOKING_DATES_ERROR:
            return { ...state, storeDatesArrayInProgress: false };

        case REMOVE_BOOKING_DATE_REQUEST:
            return { ...state };
        case REMOVE_BOOKING_DATE_SUCCESS:
            console.log('success', payload);
            var updlist = null;

            if (state.bookingDatesArray.length > 1) {
                updlist = state.bookingDatesArray.splice(payload, 1);
            } else {
                updlist = [];
            }
            return { ...state, bookingDatesArray: updlist };
        case REMOVE_BOOKING_DATE_ERROR:
            return { ...state, storeDatesArrayInProgress: false };

        default:
            return state;
    }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
    type: SET_INITIAL_VALUES,
    payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
    type: SHOW_LISTING_REQUEST,
    payload: { id },
});

export const showListingError = e => ({
    type: SHOW_LISTING_ERROR,
    error: true,
    payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({
    type: FETCH_REVIEWS_SUCCESS,
    payload: reviews,
});
export const fetchReviewsError = error => ({
    type: FETCH_REVIEWS_ERROR,
    error: true,
    payload: error,
});

export const fetchNearbyListingsSuccess = nearbyListings => ({
    type: FETCH_NEARBY_LISTINGS_SUCCESS,
    payload: nearbyListings,
});

export const fetchTimeSlotsRequest = monthId => ({
    type: FETCH_TIME_SLOTS_REQUEST,
    payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
    type: FETCH_TIME_SLOTS_SUCCESS,
    payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
    type: FETCH_TIME_SLOTS_ERROR,
    error: true,
    payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
    type: FETCH_LINE_ITEMS_SUCCESS,
    payload: lineItems,
});
export const fetchLineItemsError = error => ({
    type: FETCH_LINE_ITEMS_ERROR,
    error: true,
    payload: error,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({
    type: SEND_ENQUIRY_ERROR,
    error: true,
    payload: e,
});

export const storeBookingDatesArrayRequest = () => ({
    type: STORE_BOOKING_DATES_REQUEST,
});
export const storeBookingDatesArraySuccess = dateValues => ({
    type: STORE_BOOKING_DATES_SUCCESS,
    payload: dateValues,
});
export const storeBookingDatesArrayError = error => ({
    type: STORE_BOOKING_DATES_ERROR,
    payload: error,
});

export const removeBookingDateRequest = () => ({
    type: REMOVE_BOOKING_DATE_REQUEST,
});
export const removeBookingDateSuccess = dateIndex => ({
    type: REMOVE_BOOKING_DATE_SUCCESS,
    payload: dateIndex,
});
export const removeBookingDateError = error => ({
    type: REMOVE_BOOKING_DATE_ERROR,
    payload: error,
});

// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false) => (
    dispatch,
    getState,
    sdk
) => {
    dispatch(showListingRequest(listingId));
    dispatch(fetchCurrentUser());
    const params = {
        id: listingId,
        include: ['author', 'author.profileImage', 'images'],
        'fields.image': [
            // Listing page
            'variants.landscape-crop',
            'variants.landscape-crop2x',
            'variants.landscape-crop4x',
            'variants.landscape-crop6x',

            // Social media
            'variants.facebook',
            'variants.twitter',

            // Image carousel
            'variants.scaled-small',
            'variants.scaled-medium',
            'variants.scaled-large',
            'variants.scaled-xlarge',

            // Avatars
            'variants.square-small',
            'variants.square-small2x',
        ],
    };

    const show = isOwn
        ? sdk.ownListings.show(params)
        : sdk.listings.show(params);

    return show
        .then(data => {
            dispatch(addMarketplaceEntities(data));
            return data;
        })
        .catch(e => {
            dispatch(showListingError(storableError(e)));
        });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
    dispatch(fetchReviewsRequest());
    return sdk.reviews
        .query({
            listing_id: listingId,
            state: 'public',
            include: ['author', 'author.profileImage'],
            'fields.image': [
                'variants.square-small',
                'variants.square-small2x',
            ],
        })
        .then(response => {
            const reviews = denormalisedResponseEntities(response);
            dispatch(fetchReviewsSuccess(reviews));
        })
        .catch(e => {
            dispatch(fetchReviewsError(storableError(e)));
        });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
    return sdk.timeslots.query(params).then(response => {
        return denormalisedResponseEntities(response);
    });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (
    dispatch,
    getState,
    sdk
) => {
    const monthId = monthIdStringInTimeZone(start, timeZone);

    dispatch(fetchTimeSlotsRequest(monthId));

    // The maximum pagination page size for timeSlots is 500
    const extraParams = {
        per_page: 500,
        page: 1,
    };

    return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
        .then(timeSlots => {
            dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
        })
        .catch(e => {
            dispatch(fetchTimeSlotsError(monthId, storableError(e)));
        });
};

export const sendEnquiry = (listingId, message) => (
    dispatch,
    getState,
    sdk
) => {
    dispatch(sendEnquiryRequest());
    const groupId = uuid();
    const bodyParams = {
        transition: TRANSITION_ENQUIRE,
        processAlias: config.bookingProcessAlias,
        params: {
            metadata: {
                groupId: groupId,
                seenByProvider: false,
                seenByCustomer: true,
            },
            listingId,
        },
    };
    const queryParams = {
        include: ['booking', 'provider'],
        expand: true,
    };
    return (
        initiatePrivileged({
            isSpeculative: false,
            bookingData: {},
            bodyParams,
            queryParams,
            isEnquiry: true,
        })
            // return sdk.transactions
            // .initiate(bodyParams, queryParams)
            .then(response => {
                const transactionId = response.data.data.id;

                // Send the message to the created transaction
                return sdk.messages
                    .send({ transactionId, content: message })
                    .then(() => {
                        dispatch(sendEnquirySuccess());
                        dispatch(fetchCurrentUserHasOrdersSuccess(true));
                        return transactionId;
                    });
            })
            .catch(e => {
                dispatch(sendEnquiryError(storableError(e)));
                throw e;
            })
    );
};

// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
    const hasWindow = typeof window !== 'undefined';
    const attributes = listing.attributes;
    // Listing could be ownListing entity too, so we just check if attributes key exists
    const hasTimeZone =
        attributes &&
        attributes.availabilityPlan &&
        attributes.availabilityPlan.timezone;

    // Fetch time-zones on client side only.
    if (hasWindow && listing.id && hasTimeZone) {
        const tz = listing.attributes.availabilityPlan.timezone;
        const nextBoundary = findNextBoundary(tz, new Date());

        const nextMonth = nextMonthFn(nextBoundary, tz);
        const nextAfterNextMonth = nextMonthFn(nextMonth, tz);

        return Promise.all([
            dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
            dispatch(
                fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)
            ),
        ]);
    }

    // By default return an empty array
    return Promise.all([]);
};

export const fetchTransactionLineItems = ({
    bookingData,
    listingId,
    isOwnListing,
}) => dispatch => {
    dispatch(fetchLineItemsRequest());
    // requesting from API, only accept single object data //
    transactionLineItems({ bookingData, listingId, isOwnListing })
        .then(response => {
            const lineItems = response.data;
            dispatch(fetchLineItemsSuccess(lineItems));
        })
        .catch(e => {
            dispatch(fetchLineItemsError(storableError(e)));
            log.error(e, 'fetching-line-items-failed', {
                listingId: listingId.uuid,
                bookingData: bookingData,
            });
        });
};

export const storeBookingDatesArray = dateValue => dispatch => {
    dispatch(storeBookingDatesArrayRequest());

    if (Object.keys(dateValue).length !== 0) {
        return dispatch(storeBookingDatesArraySuccess(dateValue));
    } else {
        return dispatch(storeBookingDatesArrayError());
    }
};

export const removeBookingDate = dateIndex => dispatch => {
    dispatch(removeBookingDateRequest());

    dispatch(removeBookingDateSuccess(dateIndex));
    console.log(dateIndex);

    dispatch(storeBookingDatesArrayError());
};

export const searchNearByMapListings = searchParams => (
    dispatch,
    getState,
    sdk
) => {
    // dispatch(searchMapListingsRequest(searchParams));

    const { lOrigin, lBounds } = searchParams;
    const params = {
        origin: lOrigin,
        bounds: lBounds,
        page: 1,
        include: ['author', 'images'],
        'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': [
            'variants.landscape-crop',
            'variants.landscape-crop2x',
        ],
        'limit.images': 1,
        per_page: 24,
    };

    return sdk?.listings
        .query(params)
        .then(response => {
            // dispatch(addMarketplaceEntities(response));
            dispatch(fetchNearbyListingsSuccess(response.data));
            return response;
        })
        .catch(e => {
            // dispatch(searchMapListingsError(storableError(e)));
            throw e;
        });
};

export const loadData = (params, search) => dispatch => {
    const listingId = new UUID(params.id);

    const ownListingVariants = [
        LISTING_PAGE_DRAFT_VARIANT,
        LISTING_PAGE_PENDING_APPROVAL_VARIANT,
    ];
    if (ownListingVariants.includes(params.variant)) {
        return dispatch(showListing(listingId, true));
    }

    return Promise.all([
        dispatch(showListing(listingId)),
        dispatch(fetchReviews(listingId)),
    ]).then(responses => {
        if (responses[0] && responses[0].data && responses[0].data.data) {
            const listing = responses[0].data.data;

            // Fetch timeSlots.
            // This can happen parallel to loadData.
            // We are not interested to return them from loadData call.
            fetchMonthlyTimeSlots(dispatch, listing);
        }
        return responses;
    });
};
