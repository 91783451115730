import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingCourtTypeForm } from '../../forms';
import { ListingLink } from '../../components';

import css from './EditListingCourtTypePanel.module.css';

const FEATURES_NAME = 'amenities';

const EditListingCourtTypePanel = props => {
    const {
        rootClassName,
        className,
        listing,
        disabled,
        ready,
        onSubmit,
        onChange,
        submitButtonText,
        panelUpdated,
        updateInProgress,
        errors,
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureListing(listing);
    const { publicData } = currentListing.attributes;

    const isPublished =
        currentListing.id &&
        currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
        <FormattedMessage
            id="EditListingCourtTypePanel.title"
            values={{
                listingTitle: (
                    <ListingLink listing={listing}>
                        <FormattedMessage id="EditListingCourtTypePanel.listingTitle" />
                    </ListingLink>
                ),
            }}
        />
    ) : (
        <FormattedMessage id="EditListingCourtTypePanel.createListingTitle" />
    );

    const courtType = publicData && publicData.courtType;
    const initialValues = {
        typeIndoor: publicData.typeIndoor,
        typeOutdoor: publicData.typeOutdoor,
        courtType,
    };

    return (
        <div className={classes}>
            <h1 className={css.title}>{panelTitle}</h1>
            <EditListingCourtTypeForm
                className={css.form}
                name={FEATURES_NAME}
                initialValues={initialValues}
                onSubmit={values => {
                    const { typeIndoor = [], typeOutdoor = [] } = values;
                    // Add courtType for filtering
                    const courtType = [
                        ...new Set([...typeIndoor, ...typeOutdoor]),
                    ];
                    const updatedValues = {
                        publicData: { typeIndoor, typeOutdoor, courtType },
                    };
                    onSubmit(updatedValues);
                }}
                onChange={onChange}
                saveActionMsg={submitButtonText}
                disabled={disabled}
                ready={ready}
                updated={panelUpdated}
                updateInProgress={updateInProgress}
                fetchErrors={errors}
            />
        </div>
    );
};

EditListingCourtTypePanel.defaultProps = {
    rootClassName: null,
    className: null,
    listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingCourtTypePanel.propTypes = {
    rootClassName: string,
    className: string,

    // We cannot use propTypes.listing since the listing might be a draft.
    listing: object,

    disabled: bool.isRequired,
    ready: bool.isRequired,
    onSubmit: func.isRequired,
    onChange: func.isRequired,
    submitButtonText: string.isRequired,
    panelUpdated: bool.isRequired,
    updateInProgress: bool.isRequired,
    errors: object.isRequired,
};

export default EditListingCourtTypePanel;
