import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';

// import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
// import { propTypes } from '../../../util/types';
// import * as validators from '../../../util/validators';
// import * as validators from '../../../util/validators';

/* import {
  Form,
  Avatar,
  Button,
  ImageFromFile,
  IconSpinner,
  FieldTextInput,
  H4,
  CustomExtendedDataField,
  FieldSelect,
} from '../../../components'; */
import { composeValidators, required } from '../../util/validators';
import FieldTextInput from '../FieldTextInput/FieldTextInput';
import Form from '../Form/Form';
import Button from '../Button/Button';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import css from './DeleteUserForm.module.css';

const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset

class DeleteUserFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false };
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }

    if (this.props.openModal === false) {
      this.props.form.reset();
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={fieldRenderProps => {
          const {
            className,
            handleSubmit,
            intl,
            invalid,
            pristine,
            rootClassName,
            updateInProgress,
            // updateProfileError,
            uploadInProgress,
            form,
            values,
          } = fieldRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress;

          const leaveReasonLabel = intl.formatMessage({
            id: 'DeleteUserForm.reasonLabel',
          });

          const leaveReasonPlaceholder = intl.formatMessage({
            id: 'DeleteUserForm.reasonPlaceholder',
          });

          const leaveReasonRequiredMessage = intl.formatMessage({
            id: 'DeleteUserForm.reasonRequired',
          });

          return (
            <Form
              className={classes}
              onSubmit={async (e) => {
                this.submittedValues = values;
                await handleSubmit(e);

                form.restart();
              }}
            >
              <div className={css.sectionContainer}>
                <div className={css.sectionTitle}>
                  <FormattedMessage id="DeleteUserForm.alertTitle" />
                </div>
                <FieldTextInput
                  type="textarea"
                  id="leaveReason"
                  name="leaveReason"
                  label={leaveReasonLabel}
                  placeholder={leaveReasonPlaceholder}
                  validate={composeValidators(required(leaveReasonRequiredMessage))}
                />
              </div>

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                // ready={pristineSinceLastSubmit}
              >
                <FormattedMessage id="DeleteUserForm.confirmDelete" />
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

DeleteUserFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

DeleteUserFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const DeleteUserForm = compose(injectIntl)(DeleteUserFormComponent);

DeleteUserForm.displayName = 'DeleteUserForm';

export default DeleteUserForm;
