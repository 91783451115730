import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
    autocompleteSearchRequired,
    autocompletePlaceSelected,
    required,
    composeValidators,
} from '../../util/validators';
import {
    Form,
    LocationAutocompleteInputField,
    Button,
    FieldTextInput,
} from '../../components';

import css from './EditListingLocationForm.module.css';

const identity = v => v;

export const EditListingLocationFormComponent = props => (
    <FinalForm
        {...props}
        render={formRenderProps => {
            const {
                className,
                disabled,
                ready,
                handleSubmit,
                intl,
                invalid,
                pristine,
                saveActionMsg,
                updated,
                updateInProgress,
                fetchErrors,
                values,
            } = formRenderProps;

            const titleRequiredMessage = intl.formatMessage({
                id: 'EditListingLocationForm.address',
            });
            const addressPlaceholderMessage = intl.formatMessage({
                id: 'EditListingLocationForm.addressPlaceholder',
            });
            const addressRequiredMessage = intl.formatMessage({
                id: 'EditListingLocationForm.addressRequired',
            });
            const addressNotRecognizedMessage = intl.formatMessage({
                id: 'EditListingLocationForm.addressNotRecognized',
            });

            const optionalText = intl.formatMessage({
                id: 'EditListingLocationForm.optionalText',
            });

            const buildingMessage = intl.formatMessage({
                id: 'EditListingLocationForm.building',
            });
            const buildingPlaceholderMessage = intl.formatMessage({
                id: 'EditListingLocationForm.buildingPlaceholder',
            });

            const { updateListingError, showListingsError } = fetchErrors || {};
            const errorMessage = updateListingError ? (
                <p className={css.error}>
                    <FormattedMessage id="EditListingLocationForm.updateFailed" />
                </p>
            ) : null;

            const errorMessageShowListing = showListingsError ? (
                <p className={css.error}>
                    <FormattedMessage id="EditListingLocationForm.showListingFailed" />
                </p>
            ) : null;

            const classes = classNames(css.root, className);
            const submitReady = (updated && pristine) || ready;
            const submitInProgress = updateInProgress;
            const submitDisabled = invalid || disabled || submitInProgress;

            return (
                <Form className={classes} onSubmit={handleSubmit}>
                    {errorMessage}
                    {errorMessageShowListing}
                    <LocationAutocompleteInputField
                        className={css.locationAddress}
                        inputClassName={css.locationAutocompleteInput}
                        labelClassName={css.label22}
                        iconClassName={css.locationAutocompleteInputIcon}
                        predictionsClassName={css.predictionsRoot}
                        validClassName={css.validLocation}
                        autoFocus
                        name="location"
                        label={titleRequiredMessage}
                        placeholder={addressPlaceholderMessage}
                        useDefaultPredictions={false}
                        format={identity}
                        valueFromForm={values.location}
                        validate={composeValidators(
                            autocompleteSearchRequired(addressRequiredMessage),
                            autocompletePlaceSelected(
                                addressNotRecognizedMessage
                            )
                        )}
                        setLocation={props.setLocation}
                    />

                    <FieldTextInput
                        className={css.building}
                        type="text"
                        name="building"
                        id="building"
                        label={buildingMessage}
                        placeholder={buildingPlaceholderMessage}
                        validate={composeValidators(required('Required'))}
                    />

                    {values.location === null ? (
                        <>
                            <FormattedMessage id="EditListingLocationForm.addressRequiredFootNote" />
                        </>
                    ) : null}

                    <Button
                        className={css.submitButton}
                        type="submit"
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                        ready={submitReady}>
                        {saveActionMsg}
                    </Button>
                </Form>
            );
        }}
    />
);

EditListingLocationFormComponent.defaultProps = {
    selectedPlace: null,
    fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    selectedPlace: propTypes.place,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
    fetchErrors: shape({
        showListingsError: propTypes.error,
        updateListingError: propTypes.error,
    }),
};

export default compose(injectIntl)(EditListingLocationFormComponent);
