import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './ImageFromFile.module.css';

// Create elements out of given thumbnail file
class ImageFromFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataURLS: null,
        };
        this.readImage = this.readImage.bind(this);
    }

    readImage = (file) => {
        const reader = new FileReader();
        reader.onload = e => this.setState({ dataURLS: e.target.result });
        reader.onerror = e => {
            // eslint-disable-next-line
            console.error(
                'Error (',
                e,
                `) happened while reading ${file.name}: ${e.target.result}`
            );
        };
        reader.readAsDataURL(file)
    };

    componentDidMount() {
        this.readImage(this.props.file);
    }

    // Rerender if file changes
    componentDidUpdate(prevProps) {
        if (prevProps.file !== this.props.file) {
            this.readImage(this.props.file);
        }
    }

    render() {
        const {
            className,
            rootClassName,
            aspectRatioClassName,
            file,
            id,
            children,
        } = this.props;
        const classes = classNames(rootClassName || css.root, className);
        const aspectRatioClasses = aspectRatioClassName || css.aspectWrapper;

        return (
            <>
        {this.state.dataURLS && (
            <div className={classes}>
            <div className={css.threeToTwoWrapper}>
                <div className={aspectRatioClasses}>
                    <img
                        src={this.state.dataURLS}
                        alt={file.name}
                        className={css.rootForImage}
                    />
                </div>
            </div>
            {children}
        </div>
        )}</>
        );
    }
}

ImageFromFile.defaultProps = {
    className: null,
    children: null,
    rootClassName: null,
    aspectRatioClassName: null,
};

const { any, node, string } = PropTypes;

ImageFromFile.propTypes = {
    className: string,
    rootClassName: string,
    aspectRatioClassName: string,
    file: any.isRequired,
    id: string.isRequired,
    children: node,
};

export default ImageFromFile;
