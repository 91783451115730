/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { LINE_ITEMS, propTypes } from '../../util/types';
import css from './BookingBreakdown.module.css';

const { types } = require('sharetribe-flex-sdk');
const { Money } = types;


// merging multiple lineitems into one with respected code
const mergeSameLineItemCode = list => {
    const mergedLineItemList = {};
    list.forEach(e => {
        mergedLineItemList[e.code]
            ? (mergedLineItemList[e.code] = {
                  ...mergedLineItemList[e.code],
                  lineTotal: new Money(
                      mergedLineItemList[e.code].lineTotal.amount +
                          e.lineTotal.amount,
                      mergedLineItemList[e.code].lineTotal.currency
                  ),
              })
            : (mergedLineItemList[e.code] = e);
    });
    return Object.values(mergedLineItemList);
};

const LineItemUnknownItemsMaybe = props => {
    const { transaction, transactions, isProvider, intl } = props;

    const txs =
        !transactions || !transactions.length ? [transaction] : transactions;
    // get the unknown lineItemCode from multiple transactions
    const unKnownAllLineItems = txs.map(t =>
        t.attributes.lineItems.filter(
            item => LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal
        )
    );
    // flatten nested arrray
    const mergedUnKnownAllLineItems = [].concat.apply([], unKnownAllLineItems);

    const mergedSameLineItemList = mergeSameLineItemCode(
        mergedUnKnownAllLineItems
    );
    const items = isProvider
        ? mergedSameLineItemList.filter(item =>
              item.includeFor.includes('provider')
          )
        : mergedSameLineItemList.filter(item =>
              item.includeFor.includes('customer')
          );

    return items.length > 0 ? (
        <React.Fragment>
            {items.map((item, i) => {
                const quantity = item.quantity;

                const label =
                    quantity && quantity > 1
                        ? `${humanizeLineItemCode(item.code)} x ${quantity}`
                        : humanizeLineItemCode(item.code);

                const formattedTotal = formatMoney(intl, item.lineTotal);
                return (
                    <div key={`${i}-item.code`} className={css.lineItem}>
                        <span className={css.itemLabel}>{label}</span>
                        <span className={css.itemValue}>{formattedTotal}</span>
                    </div>
                );
            })}
        </React.Fragment>
    ) : null;
};

LineItemUnknownItemsMaybe.propTypes = {
    transaction: propTypes.transaction.isRequired,
    intl: intlShape.isRequired,
};

export default LineItemUnknownItemsMaybe;
