import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
    Avatar,
    InlineTextButton,
    Logo,
    Menu,
    MenuLabel,
    MenuContent,
    MenuItem,
    NamedLink,
    ListingLink,
    OwnListingLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
    const {
        className,
        currentUser,
        currentPage,
        rootClassName,
        currentUserHasListings,
        currentUserListing,
        currentUserListingFetched,
        notificationCount,
        intl,
        isAuthenticated,
        onLogout,
        onSearchSubmit,
        initialSearchFormValues,
    } = props;
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    const authenticatedOnClientSide = mounted && isAuthenticated;
    const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

    const isDraft = listing => {
        return listing.attributes.state === 'draft';
    };

    const isPublished = listing => {
        return listing.attributes.state === 'published' || listing.attributes.state === 'pendingApproval';
    };

    const classes = classNames(rootClassName || css.root, className);

    const search = (
        <TopbarSearchForm
            className={css.searchLink}
            desktopInputRoot={css.topbarSearchWithLeftPadding}
            onSubmit={onSearchSubmit}
            initialValues={initialSearchFormValues}
        />
    );

    const notificationDot =
        notificationCount > 0 ? (
            <div className={css.notificationDot}>{notificationCount}</div>
        ) : null;
    const inboxLink = authenticatedOnClientSide ? (
        <NamedLink
            className={css.inboxLink}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}>
            <span className={css.inbox}>
                <FormattedMessage id="TopbarDesktop.inbox" />
                {notificationDot}
            </span>
        </NamedLink>
    ) : null;

    const currentPageClass = page => {
        const isAccountSettingsPage =
            page === 'AccountSettingsPage' &&
            ACCOUNT_SETTINGS_PAGES.includes(currentPage);
        return currentPage === page || isAccountSettingsPage
            ? css.currentPage
            : null;
    };

    const profileMenu = authenticatedOnClientSide ? (
        <Menu>
            <MenuLabel
                className={css.profileMenuLabel}
                isOpenClassName={css.profileMenuIsOpen}
                shouldHover={false}>
                <Avatar
                    className={css.avatar}
                    user={currentUser}
                    disableProfileLink
                />
            </MenuLabel>
            <MenuContent className={css.profileMenuContent}>
                <MenuItem key="EditListingPage">
                    <OwnListingLink
                        listing={currentUserListing}
                        listingFetched={currentUserListingFetched}
                        className={css.yourListingsLink}>
                        <div>
                            <span className={css.menuItemBorder} />
                            {currentUserListing ? (
                                <FormattedMessage id="TopbarDesktop.editYourListingLink" />
                            ) : (
                                <FormattedMessage id="TopbarDesktop.addYourListingLink" />
                            )}
                        </div>
                    </OwnListingLink>
                </MenuItem>
                <MenuItem key="ProfileSettingsPage">
                    <NamedLink
                        className={classNames(
                            css.profileSettingsLink,
                            currentPageClass('ProfileSettingsPage')
                        )}
                        name="ProfileSettingsPage">
                        <span className={css.menuItemBorder} />
                        <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
                    </NamedLink>
                </MenuItem>
                <MenuItem key="UserCalendarPage">
                    <NamedLink
                        className={classNames(
                            css.yourListingsLink,
                            currentPageClass('UserCalendarPage')
                        )}
                        name="UserCalendarPage">
                        <span className={css.menuItemBorder} />
                        <FormattedMessage id="TopbarDesktop.userCalendarLink" />
                    </NamedLink>
                </MenuItem>
                <MenuItem key="AccountSettingsPage">
                    <NamedLink
                        className={classNames(
                            css.yourListingsLink,
                            currentPageClass('AccountSettingsPage')
                        )}
                        name="AccountSettingsPage">
                        <span className={css.menuItemBorder} />
                        <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
                    </NamedLink>
                </MenuItem>
                <MenuItem key="logout">
                    <InlineTextButton
                        rootClassName={css.logoutButton}
                        onClick={onLogout}>
                        <span className={css.menuItemBorder} />
                        <FormattedMessage id="TopbarDesktop.logout" />
                    </InlineTextButton>
                </MenuItem>
            </MenuContent>
        </Menu>
    ) : null;

    const signupLink = isAuthenticatedOrJustHydrated ? null : (
        <NamedLink name="SignupPage" className={css.signupLink}>
            <span className={css.signup}>
                <FormattedMessage id="TopbarDesktop.signup" />
            </span>
        </NamedLink>
    );

    const loginLink = isAuthenticatedOrJustHydrated ? null : (
        <NamedLink name="LoginPage" className={css.loginLink}>
            <span className={css.login}>
                <FormattedMessage id="TopbarDesktop.login" />
            </span>
        </NamedLink>
    );

    function isListingReadyToPublish(listing) {
        const {
          availabilityPlan,
          description,
          geolocation,
          price,
          title,
          publicData,
        } = listing.attributes;
        const images = listing.images;
      
        const conditions = [
          () => !!(description && title),
          () => !!(publicData && (publicData.typeIndoor || publicData.typeOutdoor)),
          () => !!(publicData && publicData.courtSurface),
          () => !!(publicData && publicData.amenities),
          () => !!(publicData && publicData.howManyPlayersAllowed && publicData.parkingSpots && publicData.rules),
          () => !!(publicData && typeof publicData.rules !== 'undefined'),
          () => !!(geolocation && publicData && publicData.location && publicData.location.address),
          () => !!price,
          () => !!availabilityPlan,
          () => images && images.length > 0,
        ];
      
        return conditions.every(condition => condition());
      }

    const listingLink =
        authenticatedOnClientSide &&
            currentUserListingFetched &&
            currentUserListing
            && isPublished(currentUserListing) ? (
            <ListingLink
                className={css.createListingLink}
                listing={currentUserListing}
                children={
                    <span className={css.createListing}>
                        <FormattedMessage id="TopbarDesktop.viewListing" />
                    </span>
                }
            />
        ) : null;

    const createListingLink =
        isAuthenticatedOrJustHydrated &&
            !(currentUserListingFetched && !currentUserListing) ? null : (
            <NamedLink className={css.createListingLink2} name="NewListingPage">
                <span className={css.createListing2}>
                    <FormattedMessage id="TopbarDesktop.createListing" />
                </span>
            </NamedLink>
        );

    const finishListingLink =
        authenticatedOnClientSide &&
            currentUserListingFetched &&
            currentUserListing &&
            isDraft(currentUserListing) ? (
            <OwnListingLink
                listing={currentUserListing}
                listingFetched={currentUserListingFetched}
                className={css.createListingLink2}>
                    <span className={css.createListing2}>
                        {isListingReadyToPublish(currentUserListing) ? (
                            <FormattedMessage id="TopbarDesktop.publishListing" />
                        ) : (
                            <FormattedMessage id="TopbarDesktop.finishListing" />
                        )}
                    </span>
            </OwnListingLink>
        ) : null;

    return (
        <nav className={classes}>
            <a className={css.logoLink} href="/">
                <Logo
                    format="desktop"
                    className={css.logo}
                    alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
                />
            </a>
            {search}
            {listingLink}
            {createListingLink}
            {finishListingLink}
            {inboxLink}
            {profileMenu}
            {signupLink}
            {loginLink}
        </nav>
    );
};

TopbarDesktop.defaultProps = {
    rootClassName: null,
    className: null,
    currentUser: null,
    currentPage: null,
    notificationCount: 0,
    initialSearchFormValues: {},
    currentUserListing: null,
    currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
    rootClassName: string,
    className: string,
    currentUserHasListings: bool.isRequired,
    currentUserListing: propTypes.ownListing,
    currentUserListingFetched: bool,
    currentUser: propTypes.currentUser,
    currentPage: string,
    isAuthenticated: bool.isRequired,
    onLogout: func.isRequired,
    notificationCount: number,
    onSearchSubmit: func.isRequired,
    initialSearchFormValues: object,
    intl: intlShape.isRequired,
};

export default TopbarDesktop;
