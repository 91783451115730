/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import courtOwner from '../src/assets/icons/court-owner.png';
import instructor from '../src/assets/icons/instructor.png';
import player from '../src/assets/icons/player.png';
import basketball from '../src/assets/icons/basketball.png';
import pickle from '../src/assets/icons/pickle.png';
import pingPong from '../src/assets/icons/Ping-Pong.png';
import racquetball from '../src/assets/icons/racquetball.png';
import squash from '../src/assets/icons/squash.png';
import tennis from '../src/assets/icons/tennis.png';

export const filters = [
    {
        id: 'dates-length',
        label: 'Dates',
        type: 'BookingDateRangeLengthFilter',
        group: 'primary',
        // Note: BookingDateRangeFilter is fixed filter,
        // you can't change "queryParamNames: ['dates'],"
        queryParamNames: ['dates', 'minDuration'],
        config: {
            // A global time zone to use in availability searches. As listings
            // can be in various time zones, we must decide what time zone we
            // use in search when looking for available listings within a
            // certain time interval.
            //
            // If you have all/most listings in a certain time zone, change this
            // config value to that.
            //
            // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
            searchTimeZone: 'Etc/UTC',

            // Options for the minimum duration of the booking
            options: [
                { key: '0', label: 'Any length' },
                { key: '60', label: '1 hour', shortLabel: '1h' },
                { key: '120', label: '2 hours', shortLabel: '2h' },
            ],
        },
    },
    {
        id: 'price',
        label: 'Price',
        type: 'PriceFilter',
        group: 'primary',
        // Note: PriceFilter is fixed filter,
        // you can't change "queryParamNames: ['price'],"
        queryParamNames: ['price'],
        // Price filter configuration
        // Note: unlike most prices this is not handled in subunits
        config: {
            min: 0,
            max: 1000,
            step: 5,
        },
    },
    // {
    //   id: 'keyword',
    //   label: 'Keyword',
    //   type: 'KeywordFilter',
    //   group: 'primary',
    //   // Note: KeywordFilter is fixed filter,
    //   // you can't change "queryParamNames: ['keywords'],"
    //   queryParamNames: ['keywords'],
    //   // NOTE: If you are ordering search results by distance
    //   // the keyword search can't be used at the same time.
    //   // You can turn on/off ordering by distance from config.js file.
    //   config: {},
    // },
    // {
    //   id: 'yogaStyles',
    //   label: 'Yoga styles',
    //   type: 'SelectMultipleFilter',
    //   group: 'secondary',
    //   queryParamNames: ['pub_yogaStyles'],
    //   config: {
    //     // Optional modes: 'has_all', 'has_any'
    //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
    //     searchMode: 'has_all',

    //     // "key" is the option you see in Flex Console.
    //     // "label" is set here for this web app's UI only.
    //     // Note: label is not added through the translation files
    //     // to make filter customizations a bit easier.
    //     options: [
    //       { key: 'ashtanga', label: 'Ashtanga' },
    //       { key: 'hatha', label: 'Hatha' },
    //       { key: 'kundalini', label: 'Kundalini' },
    //       { key: 'restorative', label: 'Restorative' },
    //       { key: 'vinyasa', label: 'Vinyasa' },
    //       { key: 'yin', label: 'Yin' },
    //       { key: 'restroom', label: 'Restroom' },
    //     ],
    //   },
    // },
    {
        id: 'courtType',
        label: 'Court Type',
        type: 'SelectMultipleFilter',
        group: 'secondary',
        queryParamNames: ['pub_courtType'],
        config: {
            searchMode: 'has_all',
            options: [
                { key: 'tennis', label: 'Tennis', icon: tennis },
                { key: 'pickleball', label: 'Pickleball', icon: pickle },
                {
                    key: 'paddle_tennis',
                    label: 'Paddle Tennis',
                    icon: pingPong,
                },
                { key: 'racquetball', label: 'Racquetball', icon: racquetball },
                { key: 'squash', label: 'Squash', icon: squash },
                { key: 'basketball', label: 'Basketball', icon: basketball },
            ],
        },
    },
    {
        id: 'courtSurface',
        label: 'Court Surface',
        type: 'SelectMultipleFilter',
        group: 'secondary',
        queryParamNames: ['pub_courtSurface'],
        config: {
            searchMode: 'has_all',
            options: [
                { key: 'asphalt', label: 'Asphalt' },
                { key: 'astroTurf', label: 'AstroTurf' },
                { key: 'clay', label: 'Clay' },
                { key: 'concrete', label: 'Concrete' },
                { key: 'grass', label: 'Grass' },
                { key: 'hard', label: 'Hard' },
                { key: 'cushioned ', label: 'Cushioned' },
                { key: 'multiSurface ', label: 'Multi-Surface' },
            ],
        },
    },
    {
        id: 'amenities',
        label: 'Amenities',
        type: 'SelectMultipleFilter',
        group: 'secondary',
        queryParamNames: ['pub_amenities'],
        config: {
            searchMode: 'has_all',
            options: [
                { key: 'restroom', label: 'Restroom' },
                { key: 'changing_room', label: 'Changing Room' },
                { key: 'parking', label: 'Parking' },
                { key: 'shaded_area', label: 'Shaded Area' },
                {
                    key: 'sitting_area_with_table',
                    label: 'Sitting Area with Table',
                },
                { key: 'shower ', label: 'Shower' },
                { key: 'tennis_ball_machine ', label: 'Tennis Ball Machine' },
                { key: 'balls_provided', label: 'Balls Provided' },
                { key: 'lights', label: 'Lights' },
                { key: 'bluetooth_speakers', label: 'Bluetooth Speakers' },
                { key: 'electrical_outlet', label: 'Electrical Outlet' },
                { key: 'water_fountain', label: 'Water Fountain' },
                { key: 'wifi', label: 'Wifi' },
                { key: 'security_camera', label: 'Security Camera' },
            ],
        },
    },
    // {
    //   id: 'certificate',
    //   label: 'Certificate',
    //   type: 'SelectSingleFilter',
    //   group: 'secondary',
    //   queryParamNames: ['pub_certificate'],
    //   config: {
    //     // "key" is the option you see in Flex Console.
    //     // "label" is set here for the UI only.
    //     // Note: label is not added through the translation files
    //     // to make filter customizations a bit easier.
    //     options: [
    //       { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
    //       { key: '200h', label: 'Registered yoga teacher 200h' },
    //       { key: '500h', label: 'Registered yoga teacher 500h' },
    //     ],
    //   },
    // },
    {
        id: 'playerType',
        label: 'Player Type',
        // type: 'SelectMultipleFilter',
        // group: 'secondary',
        queryParamNames: ['pub_playerType'],
        config: {
            searchMode: 'has_all',
            options: [
                { key: 'player', label: 'Player', icon: player },
                { key: 'coach', label: 'Coach', icon: instructor },
                { key: 'court_owner', label: 'Court Owner', icon: courtOwner },
            ],
        },
    },
    {
        id: 'usta',
        label: 'USTA (United States Tennis Association) player ranking',
        // type: 'SelectSingleFilter',
        // group: 'secondary',
        queryParamNames: ['pub_usta'],
        config: {
            searchMode: 'has_all',
            options: [
                { key: 'beginner', label: 'Beginner' },
                { key: 'usta3.0', label: 'USTA 3.0' },
                { key: 'usta3.5', label: 'USTA 3.5' },
                { key: 'usta4.0', label: 'USTA 4.0' },
                { key: 'usta4.5', label: 'USTA 4.5+' },
            ],
        },
    },
    {
        id: 'ptr',
        label: 'PTR (Professional Tennis Registry) qualifications',
        // type: 'SelectSingleFilter',
        // group: 'secondary',
        queryParamNames: ['pub_usta'],
        config: {
            searchMode: 'has_all',
            options: [
                { key: 'level_1', label: 'Level 1 (highest)' },
                { key: 'level_2', label: 'Level 2' },
                { key: 'level_3', label: 'Level 3' },
                { key: 'level_4', label: 'Level 4' },
            ],
        },
    },
    {
        id: 'uspta',
        label:
            'USPTA (Unites States Professional Tennis Association) qualifications',
        // type: 'SelectSingleFilter',
        // group: 'secondary',
        queryParamNames: ['pub_usta'],
        config: {
            searchMode: 'has_all',
            options: [
                { key: 'elite', label: 'Elite' },
                { key: 'professional', label: 'Professional' },
                { key: 'tennis_instructor', label: 'Tennis Instructor' },
                { key: 'school_coach', label: 'School Coach' },
            ],
        },
    },
];

export const sortConfig = {
    // Enable/disable the sorting control in the SearchPage
    active: true,

    // Note: queryParamName 'sort' is fixed,
    // you can't change it since Flex API expects it to be named as 'sort'
    queryParamName: 'sort',

    // Internal key for the relevance option, see notes below.
    relevanceKey: 'relevance',

    // Keyword filter is sorting the results already by relevance.
    // If keyword filter is active, we need to disable sorting.
    conflictingFilters: ['keyword'],

    options: [
        { key: 'createdAt', label: 'Newest' },
        { key: '-createdAt', label: 'Oldest' },
        { key: '-price', label: 'Lowest price' },
        { key: 'price', label: 'Highest price' },

        // The relevance is only used for keyword search, but the
        // parameter isn't sent to the Marketplace API. The key is purely
        // for handling the internal state of the sorting dropdown.
        // { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
    ],
};


// México, Canada, United States, All of Europe and Australia
export const countryList = [
    'Mexico',
    'Canada',
    'United States',
    "Albania",
    "Andorra",
    "Armenia",
    "Austria",
    "Azerbaijan",
    "Belarus",
    "Belgium",
    "Bosnia and Herzegovina",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Georgia",
    "Germany",
    "Greece",
    "Hungary",
    "Iceland",
    "Ireland",
    "Italy",
    "Kazakhstan",
    "Kosovo",
    "Latvia",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Moldova",
    "Monaco",
    "Montenegro",
    "Netherlands",
    "North Macedonia",
    "Norway",
    "Poland",
    "Portugal",
    "Romania",
    "Russia",
    "San Marino",
    "Serbia",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
    "Turkey",
    "Ukraine",
    "United Kingdom",
    "Vatican City",
    "Australia",
    "Fiji",
    "Kiribati",
    "Marshall Islands",
    "Micronesia",
    "Nauru",
    "New Zealand",
    "Palau",
    "Papua New Guinea",
    "Samoa",
    "Solomon Islands",
    "Tonga",
    "Tuvalu",
    "Vanuatu"
].sort(function(a, b) {
    return a.localeCompare(b);
  });