import React from 'react';

import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { propTypes, LINE_ITEM_CUSTOMER_COMMISSION } from '../../util/types';

import css from './BookingBreakdown.module.css';
const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

const LineItemCustomerCommissionRefundMaybe = props => {
    const { transaction, transactions, isCustomer, intl } = props;

    const refundTotal = transactions
        .map(_t =>
            _t.attributes.lineItems
                .filter(
                    item =>
                        item.code === LINE_ITEM_CUSTOMER_COMMISSION &&
                        item.reversal
                )
                .map(_li => _li.lineTotal.amount)
                .reduce((a, b) => a + b, 0)
        )
        .reduce((a, b) => a + b, 0);
    const refundMoney = formatMoney(
        intl,
        new Money(refundTotal, transaction.attributes.payoutTotal.currency)
    );

    return isCustomer && refundTotal ? (
        <div className={css.lineItem}>
            <span className={css.itemLabel}>
                <FormattedMessage id="BookingBreakdown.refundCustomerFee" />
            </span>
            <span className={css.itemValue}>{refundMoney}</span>
        </div>
    ) : null;
};

LineItemCustomerCommissionRefundMaybe.propTypes = {
    transaction: propTypes.transaction.isRequired,
    intl: intlShape.isRequired,
};

export default LineItemCustomerCommissionRefundMaybe;
